import React, { useEffect, useState } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { Sidebar } from '../../../commonPages/sidebar'
import { VerticalLayout } from '../../../components/VerticalLayout'
import { WhitePanelBox } from '../../../components/WhitePanelBox'
import { HorizontalLayout } from '../../../components/HorizontalLayout'
import { Heading } from '../../../components/Heading'
import { SvgIcon } from '../../../components/SvgIcon'
import { SearchIcon } from '../../../components/Icons/SearchIcon'
import { Notification } from '../../../components/Icons/Notification'
import Typography from '../../../components/Typography'
import { SharedButton } from '../../../components/SharedButton'
import { LeftArrow } from '../../../components/Icons/LeftArrow'
import { CarDetailCard } from '../../../components/CarDetailCard'
import { UserParkedVehicleTable } from './UserParkedVehicleTable'
import { useLocation } from 'react-router-dom'
import { getBookingsOfUser, getVehiclesOfUser } from "../../../services/NetworkCall"
import { errorAlert } from '../../../components/Alert'
import { Loader } from '../../../components/Loader'
import Header from '../../../components/Header'

export const SubUserDetail = () => {
    const [exUserData, setExUserData] = useState({});
    const location = useLocation();
    const [vehiclesData, setVehiclesData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [bookings, setBookings] = useState([]);
    const [pagination, setPagination] = useState({ totalPages: 1, page: 1 })

    const getVehiclesData = async (userId) => {
        setLoading(true);
        const res = await getVehiclesOfUser(userId);
        if (res.success) {
            setVehiclesData(res.data);
            // successAlert(res.message);
        } else {
            errorAlert({ message: res.message });
        }
        setLoading(false);
    }

    const getBookingsOfUserData = async (page = 1, userId) => {
        setLoading(true);
        const res = await getBookingsOfUser(page, userId);
        if (res.success) {
            setBookings(res.data);
            setPagination(prevPagination => ({
                ...prevPagination,
                totalPages: res.totalNumberOfPages
            }));
        } else {
            errorAlert({ message: res.message });
        }
        setLoading(false);
    }

    useEffect(() => {
        if (location && location.state) {
            setExUserData(location.state.data);
        }
    }, [location]);

    useEffect(() => {
        if (exUserData?.userId) {
            getVehiclesData(exUserData.userId);
            getBookingsOfUserData(pagination.page, exUserData.userId);
        }
    }, [exUserData]);

    const pageHandler = (page) => {
        setPagination(prevPagination => ({
            ...prevPagination,
            page: page
        }));
        getBookingsOfUserData(page, exUserData?._id);
    }

    return (
        <>
            <Loader show={loading} />
            <Container fluid>
                <Row className="m-0">
                    <Col md={3}>
                        <Sidebar />
                    </Col>
                    <Col md={9}>
                        <VerticalLayout gap={3}>
                            <Header
                                heading={"Sub Users Details"}
                                subHeading={"Welcome ! Admin Truck"}
                                showNotificationIcon={true}
                            />
                            <SharedButton label={'Back'} icon={<LeftArrow />} onClick={() => window.history.back()} iconPosition='left' size={'md'} variant={'light'} className={'rounded-5 w-10'} />
                            <WhitePanelBox>
                                <HorizontalLayout gap={3}>
                                    <Image
                                        src={exUserData?.profileImage || '../../assets/images/Image.png'}
                                        alt=''
                                        className='bg-white card-image'
                                    />
                                    <VerticalLayout justifyContent={'center'} alignItems={'start'}>
                                        <Typography variant={'h4'} className={'mb-0'}>{exUserData && exUserData.userName}</Typography>
                                        <Typography variant={'p'} color={'#7d7d7d'} className={'mb-0'}>{exUserData && exUserData.email}</Typography>
                                        <Typography variant={'p'} color={'#0a0a0a'} className={'mb-0'}>{exUserData && exUserData.phoneNumber}</Typography>
                                    </VerticalLayout>
                                </HorizontalLayout>
                            </WhitePanelBox>
                            <Typography className={'mb-0'}>Cars Detail</Typography>
                            <HorizontalLayout gap={2} overflow={'scroll'} justifyContent={'start'} alignItems={'center'} >
                                {vehiclesData && vehiclesData.map((v) =>
                                    <CarDetailCard
                                        vehicleName={v?.vehicleName}
                                        Model={v?.model}
                                        NoPlate={v?.licensePlate}
                                        State={v?.state}
                                        vehicleImage={v?.vehicleImage}
                                    />

                                )}
                            </HorizontalLayout>
                            <Typography className={'mb-0'}>Last Parked</Typography>
                            <UserParkedVehicleTable
                                bookingsData={bookings}
                                pageHandler={pageHandler}
                                pagination={pagination}
                            />
                        </VerticalLayout>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
