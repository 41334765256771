import React from 'react'

export const LaneIcon = () => {
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M6 4.25C4.48122 4.25 3.25 5.48122 3.25 7C3.25 8.51878 4.48122 9.75 6 9.75C7.51878 9.75 8.75 8.51878 8.75 7C8.75 5.48122 7.51878 4.25 6 4.25ZM4.75 7C4.75 6.30964 5.30964 5.75 6 5.75C6.69036 5.75 7.25 6.30964 7.25 7C7.25 7.69036 6.69036 8.25 6 8.25C5.30964 8.25 4.75 7.69036 4.75 7Z" fill="#2D264B" />
                <path d="M12 6.25C11.5858 6.25 11.25 6.58579 11.25 7C11.25 7.41421 11.5858 7.75 12 7.75L20 7.75C20.4142 7.75 20.75 7.41421 20.75 7C20.75 6.58579 20.4142 6.25 20 6.25L12 6.25Z" fill="#2D264B" />
                <path fillRule="evenodd" clipRule="evenodd" d="M18 14.25C16.4812 14.25 15.25 15.4812 15.25 17C15.25 18.5188 16.4812 19.75 18 19.75C19.5188 19.75 20.75 18.5188 20.75 17C20.75 15.4812 19.5188 14.25 18 14.25ZM16.75 17C16.75 16.3096 17.3096 15.75 18 15.75C18.6904 15.75 19.25 16.3096 19.25 17C19.25 17.6904 18.6904 18.25 18 18.25C17.3096 18.25 16.75 17.6904 16.75 17Z" fill="#2D264B" />
                <path d="M4 16.25C3.58579 16.25 3.25 16.5858 3.25 17C3.25 17.4142 3.58579 17.75 4 17.75L12 17.75C12.4142 17.75 12.75 17.4142 12.75 17C12.75 16.5858 12.4142 16.25 12 16.25L4 16.25Z" fill="#2D264B" />
            </svg>
        </>
    )
}
