import React, { useState } from 'react'
import { Loader } from '../../../components/Loader'
import { Col, Container, Row } from 'react-bootstrap'
import { Sidebar } from '../../../commonPages/sidebar'
import { VerticalLayout } from '../../../components/VerticalLayout'
import { WhitePanelBox } from '../../../components/WhitePanelBox'
import { HorizontalLayout } from '../../../components/HorizontalLayout'
import { Heading } from '../../../components/Heading'
import { SvgIcon } from '../../../components/SvgIcon'
import { SearchIcon } from '../../../components/Icons/SearchIcon'
import { Notification } from '../../../components/Icons/Notification'
import { SharedButton } from '../../../components/SharedButton'
import { LeftArrow } from '../../../components/Icons/LeftArrow'
import { AddOnServiceForm } from '../addOnServiceForm/AddServiceForm'
import Header from '../../../components/Header'


export const AddService = () => {
    const [loading, setLoading] = useState(false);
    return (
        <>
            <Loader show={loading} />
            <Container fluid>
                <Row className="g-3">
                    <Col md={3}>
                        <Sidebar />
                    </Col>
                    <Col md={9}>
                        <VerticalLayout gap={3}>
                            <Header
                                heading={'Add On Service'}
                                subHeading={'Welcome ! Admin Truck'}
                                showNotificationIcon={true}
                            />
                            <HorizontalLayout justifyContent={'start'} alignItems={'center'}>
                                <SharedButton onClick={() => window.history.back()} className={'rounded-5'} variant={'light'} size={'md'} type={'button'} label={'Back'} icon={<LeftArrow color={'#000'} />} />
                            </HorizontalLayout>
                            <AddOnServiceForm setLoading={setLoading} />
                        </VerticalLayout>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
