import React from 'react'
import { Stack } from 'react-bootstrap'

export const HorizontalLayout = ({ gap, justifyContent, alignItems, overflow, children, myClass }) => {
    return (
        <>
            <Stack direction="horizontal" gap={gap} className={`justify-content-${justifyContent} align-items-${alignItems} overflow-${overflow}   ${myClass}`}>
                {children}
            </Stack>
        </>
    )
}
