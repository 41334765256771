import React from 'react'

export const Notification = () => {
    return (
        <>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M7.40031 0.833374C5.13661 0.833374 3.38515 2.81739 3.66593 5.06362L3.71883 5.48685C3.79625 6.10615 3.57003 6.72475 3.11135 7.148C2.1609 8.02507 1.89684 9.42104 2.46127 10.5847L2.53017 10.7267C3.02758 11.7521 4.03754 12.4237 5.165 12.4939L5.29053 12.7091C5.96572 13.8666 7.01773 14.5 8.12242 14.5C9.2271 14.5 10.2791 13.8666 10.9543 12.7091L11.0818 12.4906C12.029 12.4158 12.8926 11.9009 13.4077 11.0916C14.1958 9.85331 13.9871 8.22885 12.9115 7.23002L12.8823 7.20284C12.7512 7.0811 12.6379 6.94462 12.5437 6.79731C12.395 6.56464 12.0858 6.4966 11.8531 6.64533C11.6204 6.79406 11.5524 7.10324 11.7011 7.33591C11.8405 7.55386 12.0081 7.7557 12.2018 7.93559L12.231 7.96277C12.9529 8.63319 13.093 9.72354 12.5641 10.5547C12.1893 11.1435 11.5398 11.5 10.8418 11.5H10.7986C10.7967 11.5 10.7948 11.5 10.7929 11.5C10.7888 11.4999 10.7847 11.5 10.7805 11.5H5.46429C5.45829 11.4999 5.45228 11.4999 5.44627 11.5H5.36131C4.53892 11.5 3.78882 11.0302 3.42991 10.2903L3.36101 10.1482C2.98896 9.38121 3.16302 8.46104 3.78952 7.88291C4.48536 7.2408 4.82855 6.30234 4.71111 5.36281L4.65821 4.93958C4.45204 3.29021 5.7381 1.83337 7.40031 1.83337H8.53559C9.28127 1.83337 9.94976 2.12513 10.444 2.59651C10.6438 2.78709 10.9603 2.7796 11.1509 2.57977C11.3415 2.37994 11.334 2.06345 11.1342 1.87286C10.4621 1.23187 9.54935 0.833374 8.53559 0.833374H7.40031ZM8.12242 13.5C7.50832 13.5 6.85314 13.1918 6.34691 12.5H9.89792C9.39169 13.1918 8.73651 13.5 8.12242 13.5Z" fill="black" />
                <path d="M12.7892 4.66671C12.7892 5.40309 12.1922 6.00004 11.4558 6.00004C10.7195 6.00004 10.1225 5.40309 10.1225 4.66671C10.1225 3.93033 10.7195 3.33337 11.4558 3.33337C12.1922 3.33337 12.7892 3.93033 12.7892 4.66671Z" fill="black" />
            </svg>
        </>
    )
}
