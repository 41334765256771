import moment from "moment";
import { axiosInstance } from "./AxiosInstance";

const getHeader = () => {
    const authToken = localStorage.getItem("authToken");
    const deviceTimeZone = moment.tz.guess(); // Get device's current time zone
    const headers = {
        deviceTimeZone: deviceTimeZone,
        Authorization: "Bearer " + authToken, //the token is a variable which holds the token
    };

    return headers;
};

const postRequest = async (path, data) => {
    let res = {
        success: false,
        message: "Something went wrong, please try again later",
    };
    try {
        const response = await axiosInstance.post(path, data, { headers: getHeader() });
        res = response.data;
    } catch (err) {
        res.message = err.response?.data.message || err.message;
        return res;
    }
    return res;
};

const deleteRequest = async (path) => {
    let res = {
        success: false,
        message: "Something went wrong, please try again later",
    };

    try {
        const response = await axiosInstance.delete(path, { headers: getHeader() });
        res = response.data;
    } catch (err) {
        res.message = err.response?.data.message || err.message;
        return res;
    }
    return res;
};

const putRequest = async (path, data) => {
    let res = {
        success: false,
        message: "Something went wrong, please try again later",
    };

    try {
        const response = await axiosInstance.put(path, data, { headers: getHeader() });
        res = response.data;
    } catch (err) {
        res.message = err.response?.data.message || err.message;
        return res;
    }
    return res;
};

const getRequest = async (path) => {
    let res = {
        success: false,
        message: "Something went wrong, please try again later",
    };

    try {
        const response = await axiosInstance.get(path, { headers: getHeader() });
        res = response.data;
    } catch (err) {
        res.message = err.response?.data.message || err.message;
        return res;
    }
    return res;
};

//common login api for admin and super visor
export const login = async (data) => {
    const path = "auth/login";
    return await postRequest(path, data);
};

export const getUsers = async (page, filter) => {
    const path = `user/getUsers?page=${page}&filter=${filter}`;
    return await getRequest(path);
}

export const getUserAnalytics = async () => {
    const path = `user/getUsersAnalytics`;
    return await getRequest(path);
}


export const getVehiclesOfUser = async (userId) => {
    const path = `vehicle/get-user-vehicles?userId=${userId}`;
    return await getRequest(path);
}

export const forgotPassword = async (data) => {
    const path = `auth/forgotPassword`;
    return await putRequest(path, data);
}

export const getCameras = async (page) => {
    let path = "";
    if (page) {
        path = `camera/getCameras?page=${page}`;
    } else {
        path = `camera/getCameras`;
    }
    return await getRequest(path);
}

export const addCamera = async (data) => {
    const path = `camera/createCamera`;
    return await postRequest(path, data);
}

export const updateCamera = async ({ id, data }) => {
    const path = `camera/updateCamera/${id}`;
    return await putRequest(path, data);
}

export const deleteCamera = async ({ id }) => {
    const path = `camera/deleteCamera/${id}`;
    return await deleteRequest(path);
}


export const getParkingLocations = async (page) => {
    const path = `parkingLocation/getParkingLocations?page=${page}`;
    return await getRequest(path);
}

export const createAddonServiceAPI = async (data) => {
    const path = "addonService/createAddonService";
    return await postRequest(path, data);
}

export const getAddonServicesAPI = async (page) => {
    let path;
    if (page) {
        path = `addonService/getAddonServices?page=${page}`;
    } else {
        path = `addonService/getAddonServices`
    }
    return await getRequest(path);
}


export const getAddOnServiceByIdAPI = async (id) => {
    const path = `addonService/getAddOnServiceById/${id}`;
    return await getRequest(path);
}

export const updateAddonServiceAPI = async (data, id) => {
    const path = `addonService/updateAddonService/${id}`;
    return await putRequest(path, data);
}


export const updateParkingSlotAPI = async (data, id) => {
    const path = `parkingSlot/updateParkingSlot/${id}`;
    return await putRequest(path, data);
}


export const deleteAddonServiceAPI = async (id) => {
    const path = `addonService/deleteAddonService/${id}`;
    return await deleteRequest(path);
}

export const createParkingLocationAPI = async (data) => {
    const path = `parkingLocation/createParkingLocation`;
    return await postRequest(path, data);
}

export const createParkingZonesAPI = async (data) => {
    const path = `parkingZone/createParkingZones`;
    return await postRequest(path, data);
}

export const createProductsAPI = async (data) => {
    const path = `product/create-products`;
    return await postRequest(path, data);
}

export const createParkingSlotsAPI = async (data) => {
    const path = `parkingSlot/createParkingSlots`;
    return await postRequest(path, data);
}

export const deleteParkingLocationAPI = async (id) => {
    const path = `parkingLocation/deleteParkingLocation/${id}`;
    return await deleteRequest(path);
}

export const deleteParkingSlotAPI = async (id) => {
    const path = `parkingSlot/deleteParkingSlot/${id}`;
    return await deleteRequest(path);
}


export const deleteParkingZoneAPI = async (id) => {
    const path = `parkingZone/deleteParkingZone/${id}`;
    return await deleteRequest(path);
}


export const updateParkingLocationAPI = async (id, data) => {
    const path = `parkingLocation/updateParkingLocation/${id}`;
    return await putRequest(path, data);
}

export const getParkingZonesAPI = async (page, id) => {
    const path = `parkingZone/getParkingZones?page=${page}&parkingLocationId=${id}`;
    return await getRequest(path);
}


export const getProductsAPI = async () => {
    const path = `product/get-products`;
    return await getRequest(path);
}


export const updateParkingZoneAPI = async (id, data) => {
    const path = `parkingZone/updateParkingZone/${id}`;
    return await putRequest(path, data);
}


export const updateProductPriceAPI = async (id, data) => {
    const path = `product/update-product/${id}`;
    return await putRequest(path, data);
}


export const getParkingSlotsAPI = async (page, id) => {
    const path = `parkingSlot/getParkingSlots?page=${page}&parkingZoneId=${id}`;
    return await getRequest(path);
}

export const getParkingLocationOptionAPI = async () => {
    const path = `parkingLocation/getParkingLocationsDropDownList`;
    return await getRequest(path);
}

export const getParkingLocationPreferredSlotPrices = async () => {
    const path = `parkingLocationPreferredSlotPrice`;
    return await getRequest(path);
}

export const createParkingLocationPreferredSlotPrice = async (data) => {
    const path = `parkingLocationPreferredSlotPrice`;
    return await postRequest(path, data);
}

export const updateParkingLocationPreferredSlotPrice = async (id, data) => {
    const path = `parkingLocationPreferredSlotPrice/${id}`;
    return await putRequest(path, data);
}

export const getVehicles = async (page, startDate = "", endDate = "") => {
    const path = `vehicle/get-vehicles?page=${page}&startDate=${startDate}&endDate=${endDate}`;
    return await getRequest(path);
}

export const getVehicleAnalytics = async () => {
    const path = `vehicle/vehicle-analytics`;
    return await getRequest(path);
}

export const getSubUsers = async (userId) => {
    const path = `user/getSubUsers?userId=${userId}`;
    return await getRequest(path);
}


export const getSecurityAccessPinCodes = async (page) => {
    const path = `booking/getAccessPinCodes?page=${page}`;
    return await getRequest(path);
}

export const getBookingsOfUser = async (page, userId) => {
    const path = `booking/getBookingsOfUser?page=${page}&userId=${userId}`;
    return await getRequest(path);
}


export const getUsersProfileForChatListDisplay = async (userIds, userName = "") => {
    const path = `user/getUsersProfileForChatListDisplay?userIds=${userIds}&userName=${userName}`;
    return await getRequest(path);
}


export const getBookingsAPI = async (page, startDate = "", endDate = "") => {
    // console.log("console in api called-----------------", page, startDate, endDate)
    const path = `booking/getBookings?page=${page}&startDate=${startDate}&endDate=${endDate}`;
    return await getRequest(path);
}

export const getBookingDetailAPI = async (bookingId) => {
    const path = `/booking/getBookingDetail/${bookingId}`;
    return await getRequest(path);
}

export const getAnalyticsDataAPI = async () => {
    const path = `analytics/getAnalytics`
    return await getRequest(path);
}

export const updateParkingSlotsAPI = async (data) => {
    const path = `parkingSlot/updateParkingSlots`
    return await putRequest(path, data);
}

export const getAdminUsersAPI = async () => {
    const path = `auth/getAdminUsers`;
    return await getRequest(path);
}

export const addAdminUserAPI = async (data) => {
    const path = `auth/addAdminUser`;
    return await postRequest(path, data);
}

export const updateAdminUserProfile = async (data) => {
    const path = `auth/updateAdminUserProfile`;
    return await putRequest(path, data);
}

export const deleteAdminUser = async (adminID) => {
    const path = `auth/deleteAdminUser/${adminID}`;
    return await deleteRequest(path);
}

export const getLocationSlotsStatus = async (parkingLocationId, parqueryVisualPlanId) => {
    const path = `analytics/getLocationSlotsStatus?parkingLocationId=${parkingLocationId}&parqueryVisualPlanId=${parqueryVisualPlanId}`;
    return await getRequest(path);
}
