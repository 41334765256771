import { useState, useRef } from 'react';
import { SvgIcon } from './SvgIcon';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Overlay, Tooltip } from 'react-bootstrap';
import { MoreIcon } from './Icons/MoreIcon';
import { VerticalLayout } from './VerticalLayout';
import { SharedButton } from './SharedButton';

export const IconTooltip = ({ editPath = '', editData = '', deletePath, viewPath = '', viewData = '' }) => {
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const navigate = useNavigate();

    const editNavigator = () => {
        navigate(editPath, { state: { data: editData } });
    }

    const viewNavigator = () => {
        navigate(viewPath, { state: { data: viewData } });
    }
    return (
        <>
            <SvgIcon>
                <Button variant={'light-primary'} className='rounded-circle' ref={target} onClick={() => setShow(!show)}><MoreIcon /></Button>
            </SvgIcon>
            <Overlay target={target.current} show={show} placement="right" onHide={() => setShow(false)} rootClose>
                {(props) => (
                    <Tooltip id="overlay-example" {...props} >
                        <VerticalLayout gap={'1'} justifyContent={'center'} alignItems={'center'}>
                            <SharedButton onClick={viewNavigator} className='text-decoration-none text-dark p-0' variant={'transparent'} label={'View'} />
                            <SharedButton onClick={editNavigator} className='text-decoration-none text-dark p-0' variant={'transparent'} label={'Edit'} />
                            {/* <SharedButton onClick={deletePath}className='text-decoration-none text-dark p-0' variant={'transparent'} label={'Delete'} /> */}

                        </VerticalLayout>
                    </Tooltip>
                )}
            </Overlay>
        </>
    );
}