import { Image, Stack } from "react-bootstrap"
import { Link } from "react-router-dom"

export const LinkSidebar = ({ linkLabel, linkIcon, linkPath }) => {
    return <>
        <Link to={linkPath} className={'text-decoration-none text-dark '} >
            <Stack direction={'horizontal'} gap={3}>
                <div>{linkIcon}</div>
                <span className="fw-semibold">
                    {linkLabel}
                </span>
            </Stack>
        </Link>

    </>
}