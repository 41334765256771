import React from 'react'
import { Container, Row, Col, Image, } from 'react-bootstrap'
import { Sidebar } from '../../commonPages/sidebar'
import { WhitePanelBox } from '../../components/WhitePanelBox'
import { VerticalLayout } from '../../components/VerticalLayout'
import { HorizontalLayout } from '../../components/HorizontalLayout'
import { Heading } from '../../components/Heading'
import { SvgIcon } from '../../components/SvgIcon'
import { SearchPanel } from '../../components/SearchPanel'
import { Notification } from '../../components/Icons/Notification'
import Typography from '../../components/Typography'
import { SecurityQRTable } from './SecurityQRTable'
import { LastParkedTable } from './LastParkedTable'
import { LocationIcon } from '../../components/Icons/LocationIcon'
import { SharedButton } from '../../components/SharedButton'
import { LeftArrow } from '../../components/Icons/LeftArrow'

export const SecurityQRDetail = () => {
    return (
        <>
            <Container fluid>
                <Row className="g-3">
                    <Col md={3}>
                        <Sidebar />
                    </Col>
                    <Col md={9}>
                        <VerticalLayout gap={3}>
                            <WhitePanelBox className="Box">
                                <HorizontalLayout gap={2} justifyContent={'space-between'} alignItems={'center'}>
                                    <Heading Heading={'QR /  Pin Code Access Control'} SubHeading={'Welcome ! Admin Truck'} />
                                    <HorizontalLayout gap={3}>
                                        <SvgIcon bg={'#E8EDFD'} size={'1rem'}>
                                            <SearchPanel />
                                        </SvgIcon>
                                        <SvgIcon bg={'#E8EDFD'} size={'1rem'}>
                                            <Notification />
                                        </SvgIcon>
                                    </HorizontalLayout>
                                </HorizontalLayout>
                            </WhitePanelBox>
                            <SharedButton label={'Back'} variant={'light'} size={'md'} className={'rounded-5 w-10'} icon={<LeftArrow/>}/>
                            <WhitePanelBox>
                                <Row className='g-3'>
                                    <Col md={6} sm={12}>
                                        <HorizontalLayout gap={3}>
                                            <Image
                                                src={'../../assets/images/Image.png'}
                                                alt=''
                                                fluid
                                                className='bg-white'
                                            />
                                            <VerticalLayout justifyContent={'center'} alignItems={'start'}>
                                                <Typography variant={'h4'} className={'mb-0'}>New York, NY</Typography>
                                                <Typography variant={'p'} color={'#7d7d7d'} className={'mb-0'}>6 El Qubbabi Street, Kafr El-Sheikh</Typography>
                                                <Typography variant={'p'} color={'#0a0a0a'} className={'mb-0'}>Zone : Zone | Slot : GF-10</Typography>
                                            </VerticalLayout>
                                        </HorizontalLayout>
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <HorizontalLayout gap={3}>
                                            <Image
                                                src={'../../assets/images/Image.png'}
                                                alt=''
                                                fluid
                                                className='bg-white'
                                            />
                                            <VerticalLayout justifyContent={'center'} alignItems={'start'}>
                                                <Typography variant={'h4'} className={'mb-0'}>Nick Name - 01</Typography>
                                                <Typography variant={'p'} color={'#7d7d7d'} className={'mb-0'}>Mercedes CDI</Typography>
                                                <Typography variant={'p'} color={'#0a0a0a'} className={'mb-0'}>WB 06 F 6872</Typography>
                                            </VerticalLayout>
                                        </HorizontalLayout>
                                    </Col>
                                </Row>
                            </WhitePanelBox>
                            <Typography className={'mb-0'}>Last Parked</Typography>
                            <LastParkedTable />
                        </VerticalLayout>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
