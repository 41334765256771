import React from "react";
import { WhitePanelBox } from "./WhitePanelBox";
import { VerticalLayout } from "./VerticalLayout";
import { HorizontalLayout } from "./HorizontalLayout";
import { Avatar } from "./Avatar";
import Typography from "./Typography";
import { SvgIcon } from "./SvgIcon";
import { DaysIcon } from "./Icons/DaysIcon";
import { SlotIcon } from "./Icons/SlotIcon";
import { LocationIcon } from "./Icons/LocationIcon";
import { useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap";

export const DashboardLocation = ({
  LocationName,
  LocationAddress,
  LocationLink,
  TotalSlots = "",
  availableSlots = "",
  occupiedSlots = "",
  locationImage = "",
  onClick,
  isActive,
  bookedSlots
}) => {
  return (
    <>

      <WhitePanelBox onClick={onClick} className={`cursor-pointer ms-2 ${isActive ? " border border-primary" : ""}`}>
        <VerticalLayout gap={2}>
          <HorizontalLayout gap={3} justifyContent={"center"} alignItems={"center"}>
            <Image width={54} height={54} src={locationImage ? locationImage : 'assets/images/LocationPlaceholder.png'} />
            <VerticalLayout>
              <Typography className={"mb-0 fw-bold"} color={"secondary"}>
                {LocationName}
              </Typography>
              <Typography className={"mb-0"} color={"secondary"}>
                {LocationAddress}
              </Typography>
            </VerticalLayout>
            <div onClick={() => window.open(LocationLink, "_blank")}>
              <SvgIcon bg={"#e8edfd"}>{<LocationIcon />}</SvgIcon>
            </div>
          </HorizontalLayout>
          <HorizontalLayout gap={2} justifyContent={"between"} alignItems={"center"}>
               <VerticalLayout alignItems={'center'}>
               <Typography className={"mb-0 text-primary fw-bold"} size={'.90rem'}>TOTAL</Typography>
               <Typography className={"mb-0 fw-semibold"}>{TotalSlots}</Typography>
               </VerticalLayout>

               <VerticalLayout alignItems={'center'}>
               <Typography className={"mb-0 text-primary fw-bold"} size={'.90rem'}>BOOKED</Typography>
               <Typography className={"mb-0 fw-semibold"}>{bookedSlots}</Typography>
               </VerticalLayout>

               <VerticalLayout alignItems={'center'}>
               <Typography className={"mb-0 text-primary fw-bold"} size={'.90rem'}>OCCUPIED</Typography>
               <Typography className={"mb-0 fw-semibold"}>{occupiedSlots}</Typography>
               </VerticalLayout> 

          </HorizontalLayout>
        </VerticalLayout>
      </WhitePanelBox>

    </>
  );
};