import React, { useEffect, useState } from 'react'
import { Container, Row, Col, FormControl, } from 'react-bootstrap'
import { VerticalLayout } from '../../components/VerticalLayout'
import { WhitePanelBox } from '../../components/WhitePanelBox'
import { HorizontalLayout } from '../../components/HorizontalLayout'
import { Heading } from '../../components/Heading'
import { SvgIcon } from '../../components/SvgIcon'
import { Notification } from '../../components/Icons/Notification'
import { DashboardCard } from '../../components/DashboardCard'
import { Sidebar } from '../../commonPages/sidebar'
import { SearchPanel } from '../../components/SearchPanel'
import Typography from '../../components/Typography'
import { NewInputField } from '../../components/NewInputField'
import { SharedButton } from '../../components/SharedButton'
import { VehicleRegisterIcon } from '../../components/Icons/VehicleRegisterIcon'
import { NewVehicleRegisterIcon } from '../../components/Icons/NewVehicleRegisterIcon'
import { CalenderIcon } from '../../components/Icons/CalenderIcon'
import { SearchIcon } from '../../components/Icons/SearchIcon'
import { TransactionTable } from './TransactionTable'
import { Loader } from '../../components/Loader'
import { getBookingsAPI } from '../../services/NetworkCall';
import { errorAlert } from "../../components/Alert"
import Header from '../../components/Header'
import CancelIcon from '../../components/Icons/CancelIcon'

export const Transaction = () => {
    const [loading, setLoading] = useState(false);
    const [bookingsData, setBookingsData] = useState([]);
    const [pagination, setPagination] = useState({ totalPages: 1, page: 1 });
    const [filter, setFilter] = useState({ "startDate": null, "endDate": null });

    const getBookingsData = async (page = 1, startDate, endDate) => {
        setLoading(true);
        const res = await getBookingsAPI(page, startDate, endDate);
        if (res.success) {
            setBookingsData(res.data);
            setPagination(prevPagination => ({
                ...prevPagination,
                totalPages: res.totalNumberOfPages
            }));
            setLoading(false);
        } else {
            errorAlert({ message: res.message });
            setLoading(false);
        }
        setLoading(false);
    }

    useEffect(() => {
        getBookingsData(pagination.page);
    },
        [pagination.page]);

    const pageHandler = (page) => {
        setPagination(prevPagination => ({
            ...prevPagination,
            page: page
        }));
        getBookingsData(page);
    }

    const inputHandler = (e) => {
        const { name, value } = e.target;
        setFilter({ ...filter, [name]: value });
    };

    const searchHandler = () => {
        if (!filter.startDate && !filter.endDate) {
            return;
        }
        getBookingsData(1, filter.startDate, filter.endDate);
    }

    const clearDateHandler = () => {
        setFilter({ "startDate": null, "endDate": null });
        getBookingsData(1, "", "");
    }

    return (
        <>
            <Loader show={loading} />
            <Container fluid>
                <Row className="g-3">
                    <Col md={3}>
                        <Sidebar />
                    </Col>
                    <Col md={9}>
                        <VerticalLayout gap={3}>
                            <Header
                                heading={"Transactions"}
                                subHeading={'Welcome ! Admin Truck'}
                                showNotificationIcon={true}
                            />
                            <WhitePanelBox>
                                <HorizontalLayout justifyContent={'between'} alignItems={'center'} gap={3} myClass={'flex-sm-column'}>
                                    <Typography className={'mb-0'}>Transactions List</Typography>
                                    <HorizontalLayout gap={2} justifyContent={'center'} alignItems={'center'}>
                                        <NewInputField
                                            FormType='date'
                                            name='startDate'
                                            endIcon={<CalenderIcon />}
                                            FormPlaceHolder='Start Date'
                                            className='mb-0'
                                            value={filter.startDate}
                                            onChange={inputHandler}
                                        />
                                        <NewInputField
                                            FormType='date'
                                            name='endDate'
                                            endIcon={<CalenderIcon />}
                                            FormPlaceHolder='End Date'
                                            className='mb-0'
                                            onChange={inputHandler}
                                            value={filter.endDate}
                                        />
                                        <SvgIcon bg={'#E8EDFD'} size={'1rem'}>
                                            <SearchIcon onClick={searchHandler} />
                                        </SvgIcon>
                                        {(filter.startDate || filter.endDate)
                                            && <SvgIcon bg={'#E8EDFD'} size={'1rem'}>
                                                <CancelIcon onClick={clearDateHandler} />
                                            </SvgIcon>
                                        }
                                    </HorizontalLayout>
                                </HorizontalLayout>
                            </WhitePanelBox>
                            <TransactionTable
                                bookingsData={bookingsData}
                                pagination={pagination}
                                pageHandler={pageHandler}
                            />
                        </VerticalLayout>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
