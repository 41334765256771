import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CalenderIcon } from './Icons/CalenderIcon';
// import CalendarIcon from 'react-datepicker/dist/calendar_icon';

export const NewInputField = ({
    pattern = "",
    FormLabel = "",
    FormType = "",
    FormPlaceHolder = "",
    error = "",
    name = "",
    onChange = null,
    value = "",
    readOnly = false,
    isTextArea = false,
    max = '',
    min = "",
    required = false,
    disabled = false,
    className = "",
    minLength = "",
    maxLength = "",
    endIcon,
}) => {
    const [startDate, setStartDate] = useState();
    const datepickerRef = useRef();

    useEffect(() => {
        if (FormType === 'date' && value != "") {
            setStartDate(value);

        }
    }, [FormType, value]);


    // const handleDateChange = (date) => {
    //     console.log("this is date---------------", date);
    //     setStartDate(date);
    //     let formattedDate = "";
    //     if (date) {
    //         // Parse the timestamp using moment
    //         const momentObj = moment(date, "ddd MMM DD YYYY HH:mm:ss");

    //         // Get Unix timestamp (seconds since Jan 1, 1970)
    //         const unixTimestamp = momentObj.unix();
    //         // formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
    //         formattedDate = unixTimestamp;
    //     }
    //     if (onChange) {
    //         onChange({ target: { name, value: formattedDate } });
    //     }
    // };

    const handleDateChange = (date) => {
        setStartDate(date);
        let formattedDate = "";
        if (date) {
            formattedDate = moment(date).format('YYYY-MM-DD');
        }
        if (onChange) {
            onChange({ target: { name, value: formattedDate } });
        }
    };

    const handleIconClick = () => {
        if (datepickerRef.current)
            datepickerRef.current.setOpen(true);
    }


    return (
        <Form.Group className='position-relative' controlId="formGroupEmail">
            <Form.Label>{FormLabel} {required ? <small className='error'>*</small> : ""}</Form.Label>
            {FormType === 'date' ? (
                readOnly == false ? (<>

                    <DatePicker
                        // showIcon
                        ref={datepickerRef}
                        selected={startDate}
                        onChange={handleDateChange}
                        dateFormat="yyyy/MM/dd"
                        className="form-control"
                        onKeyDown={(e) => e.preventDefault()}
                        placeholderText={FormPlaceHolder || "select date"}
                        readOnly={disabled}
                    />
                    <div className='position-absolute end-0 p-1 top-0 cursor-pointer' onClick={handleIconClick}>
                        <CalenderIcon onChange={handleDateChange} />
                    </div>
                </>)
                    :
                    <DatePicker
                        onKeyDown={(e) => e.preventDefault()}
                        selected={startDate}
                        dateFormat="yyyy/MM/dd"
                        className="form-control"
                        placeholderText={"select date"}
                    />
            ) : (
                <InputGroup>
                    <Form.Control
                        as={isTextArea ? 'textarea' : 'input'}
                        type={isTextArea ? undefined : FormType}
                        name={name}
                        value={value ? value : ''}
                        placeholder={FormPlaceHolder}
                        onChange={onChange}
                        readOnly={readOnly}
                        maxLength={maxLength}
                        minLength={minLength}
                        min={min}
                        max={max}
                        disabled={disabled}
                        className={className ? className : "custom-input"}
                        rows={isTextArea ? 3 : undefined}
                    />
                    {endIcon && (
                        <InputGroup.Text className="Input-Group-Icon">
                            {endIcon}
                        </InputGroup.Text>
                    )}
                </InputGroup>
            )}

            <small className='error text-danger'>{error}</small>
        </Form.Group>
    );
};
