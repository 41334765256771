import React from 'react';
import { Col, Image, Row, Table } from 'react-bootstrap';
import { WhitePanelBox } from '../../../components/WhitePanelBox';
import { IconTooltip } from '../../../components/IconTooltip';
import { TablePagination } from '../../../components/TablePagination';
import { HorizontalLayout } from '../../../components/HorizontalLayout';
import { SvgIcon } from '../../../components/SvgIcon';
import { Checkbox } from '../../../components/Checkbox';

export const SlotTableList = ({ handleSlotSelect, pageHandler, pagination, preSlot }) => {
    const isAllSelected = preSlot.length > 0 && preSlot.every((s) => s.selected);

    return (
        <>
            <WhitePanelBox>
                <Table hover>
                    <thead>
                        <tr>
                            <th><Checkbox checked={isAllSelected} onClick={() => { handleSlotSelect(1) }} /></th>
                            <th>SLOT NAME</th>
                            <th>SERVICES</th>
                            <th>ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {preSlot && preSlot.map((e, index) => (
                            <tr key={index}>
                                <td><Checkbox checked={e?.selected} onClick={() => { handleSlotSelect(e?._id) }} /></td>
                                <td> {e?.slotNumber}</td>
                                <td>
                                    <div className='d-flex'>
                                        {e?.addonServiceIds.map((e) => (
                                            <SvgIcon bg={'#D1DCFA'} className={'rounded-2 me-2 d-flex'}>
                                                <Image src={e.image} alt='' width={16} height={16} className='me-2' fluid rounded />
                                                {e.name}
                                            </SvgIcon>
                                        ))}
                                    </div> </td>
                                <td><IconTooltip editPath={'/edit-slot'} editData={e} viewPath={'/view-slot'} viewData={e} /></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </WhitePanelBox>
            <HorizontalLayout justifyContent={'end'} alignItems={'center'}>
                <TablePagination pagination={pagination} pageHandler={pageHandler} />
            </HorizontalLayout>

        </>
    );
};
