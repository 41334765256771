import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap'
import { VerticalLayout } from '../../components/VerticalLayout'
import { WhitePanelBox } from '../../components/WhitePanelBox'
import { HorizontalLayout } from '../../components/HorizontalLayout'
import { Heading } from '../../components/Heading'
import { SvgIcon } from '../../components/SvgIcon'
import { Notification } from '../../components/Icons/Notification'
import { DashboardCard } from '../../components/DashboardCard'
import { Sidebar } from '../../commonPages/sidebar'
import { SearchPanel } from '../../components/SearchPanel'
import { errorAlert, successAlert } from '../../components/Alert'
import { Loader } from '../../components/Loader'
import { createProductsAPI, getParkingLocationOptionAPI, getProductsAPI, updateProductPriceAPI } from '../../services/NetworkCall'
import { ParkingPrice } from './parking_price/ParkingPrice'
import { PreferredParkingPrice } from './preferred_parking_price/PreferredParkingPrice'
import Header from '../../components/Header'



export const CreateProduct = () => {
    const [loading, setLoading] = useState(false);
    const [key, setKey] = useState('parkingPrice');

    return (
        <>
            <Loader show={loading} />
            <Container fluid>
                <Row className="g-3">
                    <Col md={3}>
                        <Sidebar />
                    </Col>
                    <Col md={9}>
                        <VerticalLayout gap={3}>
                            <Header
                                heading={'Create Product'}
                                subHeading={'Welcome ! Admin Truck'}
                                showNotificationIcon={true}
                            />
                            <Tabs
                                id="controlled-tab-example"
                                activeKey={key}
                                onSelect={(k) => setKey(k)}
                                className="mb-3 flex-sm-row"
                                variant='pills'
                            >
                                <Tab eventKey="parkingPrice" title="Parking Price">
                                    <ParkingPrice setLoading={setLoading} />
                                </Tab>
                                <Tab eventKey="Preferred Parking" title="Preferred Parking Price">
                                    <PreferredParkingPrice setLoading={setLoading} />
                                </Tab>
                            </Tabs>
                        </VerticalLayout>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
