import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Accordion } from 'react-bootstrap'
import { Loader } from '../../../components/Loader'
import { Sidebar } from '../../../commonPages/sidebar'
import { VerticalLayout } from '../../../components/VerticalLayout'
import { WhitePanelBox } from '../../../components/WhitePanelBox'
import { HorizontalLayout } from '../../../components/HorizontalLayout'
import { Heading } from '../../../components/Heading'
import { SvgIcon } from '../../../components/SvgIcon'
import { SearchIcon } from '../../../components/Icons/SearchIcon'
import { Notification } from '../../../components/Icons/Notification'
import { SharedButton } from '../../../components/SharedButton'
import { LeftArrow } from '../../../components/Icons/LeftArrow'
import { useNavigate } from 'react-router-dom'

export const CreateLocation = () => {
    const [showIframe, setShowIframe] = useState(true);
    const navigate = useNavigate();
    const authToken = localStorage.getItem("authToken");
    const unityUrl = `${process.env.REACT_APP_UNITY_URL}?token=${authToken}`;

    useEffect(() => {
        const handleMessage = (event) => {

            // console.log("this is event-------------------||||||||--------------", event.data);
            // alert("thiis event data--------------", event.data)
            if (event.data === "closeIframe") {
                setShowIframe(false);
                navigate('/location-list');
            }
        };
        window.addEventListener("message", handleMessage);
        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, [navigate]);

    return (
        <>
            <Container fluid>
                <Row className="g-3">
                    <Col md={3}>
                        <Sidebar />
                    </Col>
                    <Col md={9}>
                        <VerticalLayout gap={3}>
                            <WhitePanelBox className="Box">
                                <HorizontalLayout gap={2} justifyContent={'space-between'} alignItems={'center'}>
                                    <Heading Heading={'Create Location'} SubHeading={'Welcome ! Admin Truck'} />
                                    <HorizontalLayout gap={3}>
                                        <SvgIcon bg={'#E8EDFD'} size={'1rem'}>
                                            <SearchIcon />
                                        </SvgIcon>
                                        <SvgIcon bg={'#E8EDFD'} size={'1rem'}>
                                            <Notification />
                                        </SvgIcon>
                                    </HorizontalLayout>
                                </HorizontalLayout>
                            </WhitePanelBox>

                            <HorizontalLayout justifyContent={'between'} alignItems={'center'}>
                                <SharedButton className={'rounded-5'} onClick={() => window.history.back()} variant={'light'} size={'md'} type={'button'} label={'Back'} icon={<LeftArrow color={'#000'} />} />
                            </HorizontalLayout>

                            {/* Unity WebGL Embed */}
                            <WhitePanelBox className="iframe-container">
                                {showIframe &&
                                    <iframe
                                        src={unityUrl}
                                        width="100%"
                                        height="600px"
                                        style={{ border: 'none', borderRadius: '8px' }}
                                        title="Unity Game"
                                        allowFullScreen
                                    ></iframe>
                                }
                            </WhitePanelBox>
                        </VerticalLayout>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
