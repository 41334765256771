
import { HorizontalLayout } from "../../components/HorizontalLayout";
import { VerticalLayout } from "../../components/VerticalLayout";
import Typography from "../../components/Typography";
import { Image, Badge, Nav } from "react-bootstrap";
import { FaUserCircle } from "react-icons/fa";
import moment from "moment";
import { sortByTimestampDesc } from "../../helper/Helper";
import styled from "styled-components"

const ListBox = styled.div`
    max-height:100%; 
    height:60vh;
    width:100%;
    overflow-y:auto;
    &::-webkit-scrollbar {
      display: none;
    }
`;

export const UsersCard = ({ users, handleCardClick, setActive, active, profileData }) => {
    const convertUtcToLocalDate = (date) => {
        return moment(date.toDate()).local().format("YYYY-MM-DD hh:mm A")
    };

    return (
        <>
            <ListBox >
                <Nav variant="rounded" className="flex-column">
                    {users && sortByTimestampDesc(users).map((user, index) => (
                        <Nav.Item key={user.userId} className="border-bottom" onClick={() => { setActive(index); }}>
                            <Nav.Link eventKey={user.userId} onClick={() => handleCardClick(user, profileData)} className="text-dark">
                                <div className='cursor-pointer'>
                                    <HorizontalLayout justifyContent={'between'} alignItems={'center'} gap={2}>
                                        <HorizontalLayout gap={2}>
                                            {user?.profileImage ? (
                                                <Image src={user?.profileImage} fluid roundedCircle className="chatuser-image" />
                                            ) : (
                                                <FaUserCircle style={{ fontSize: '60px' }} />
                                            )}
                                            <VerticalLayout justifyContent={'center'} alignItems={'start'}>
                                                <Typography className={'mb-0'}>{user?.userName}</Typography>
                                                {active != index &&
                                                    <>
                                                        <Typography className={'text-secondary text-truncate fs-6 fw-bold mb-0 text-ellipsis-width'}>
                                                            {user?.lastMessage?.text}
                                                        </Typography>
                                                        <Typography className={'text-secondary mb-0 fs-6'}> {user?.lastMessage?.date
                                                            ? convertUtcToLocalDate(user?.lastMessage?.date)
                                                            : ""}</Typography>
                                                    </>}
                                            </VerticalLayout>
                                        </HorizontalLayout>
                                        {active != index &&
                                            <Badge variant={'rounded'}>{user?.unseenCount && user.unseenCount !== 0 ? user.unseenCount : ""}</Badge>
                                        }
                                    </HorizontalLayout>
                                </div>
                            </Nav.Link>
                        </Nav.Item>

                    ))}
                </Nav>
            </ListBox>
        </>
    );
};