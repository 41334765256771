import React, { useEffect, useState } from 'react'
import { Container, Row, Col, } from 'react-bootstrap'
import { VerticalLayout } from '../../components/VerticalLayout'
import { WhitePanelBox } from '../../components/WhitePanelBox'
import { HorizontalLayout } from '../../components/HorizontalLayout'
import { Heading } from '../../components/Heading'
import { SvgIcon } from '../../components/SvgIcon'
import { Notification } from '../../components/Icons/Notification'
import { DashboardCard } from '../../components/DashboardCard'
import { Sidebar } from '../../commonPages/sidebar'
import { SearchPanel } from '../../components/SearchPanel'
import Typography from '../../components/Typography'
import { NewInputField } from '../../components/NewInputField'
import { SharedButton } from '../../components/SharedButton'
import { VehicleRegisterIcon } from '../../components/Icons/VehicleRegisterIcon'
import { NewVehicleRegisterIcon } from '../../components/Icons/NewVehicleRegisterIcon'
import { CalenderIcon } from '../../components/Icons/CalenderIcon'
import { SearchIcon } from '../../components/Icons/SearchIcon'
import { VehicleRegistrationTable } from './VehicleRegistrationTable';
import { Loader } from "../../components/Loader"
import { getVehicleAnalytics, getVehicles } from '../../services/NetworkCall';
import Header from '../../components/Header'
import { Delete } from '../../components/Icons/Delete'
import CancelIcon from '../../components/Icons/CancelIcon'

export const VehicleRegistration = () => {
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({ totalPages: 1, page: 1 });
    const [filter, setFilter] = useState({ "startDate": null, "endDate": null });
    const [vehicleAnalytics, setVehicleAnalytics] = useState({
        "totalVehicles": 0,
        "totalNewVehicles": 0
    });
    const [vehiclesData, setVehiclesData] = useState([]);

    const getAnalyticsData = async () => {
        setLoading(true);
        const res = await getVehicleAnalytics();
        if (res.success) {
            setVehicleAnalytics({
                totalVehicles: res.data?.totalVehicles,
                totalNewVehicles: res.data?.totalNewVehicles
            })
            setLoading(false);
        }
        setLoading(false);
    }

    const getVehiclesData = async (page = 1, startDate, endDate) => {
        setLoading(true);
        const res = await getVehicles(page, startDate, endDate);
        if (res.success) {
            setVehiclesData(res.data);
            setPagination(prevPagination => ({
                ...prevPagination,
                totalPages: res.totalNumberOfPages
            }));
            setLoading(false);
        }
        setLoading(false);
    }

    useEffect(() => {
        getVehiclesData(pagination.page);
        getAnalyticsData();
    },
        [pagination.page]);

    const pageHandler = (page) => {
        setPagination(prevPagination => ({
            ...prevPagination,
            page: page
        }));
        getVehiclesData(page);
    }

    const inputHandler = (e) => {
        const { name, value } = e.target;
        setFilter({ ...filter, [name]: value });
    };

    const searchHandler = () => {
        if (!filter.startDate && !filter.endDate) {
            return;
        }
        getVehiclesData(1, filter.startDate, filter.endDate);
    }

    const clearDateHandler = () => {
        setFilter({ "startDate": null, "endDate": null });
        getVehiclesData(1, "", "");
    }

    return (
        <>
            <Loader show={loading} />
            <Container fluid>
                <Row className="g-3">
                    <Col md={3}>
                        <Sidebar />
                    </Col>
                    <Col md={9}>
                        <VerticalLayout gap={3}>
                            <Header
                                heading={"Vehicle Registration"}
                                subHeading={'Welcome ! Admin Truck'}
                                showNotificationIcon={true}
                            />

                            {/* ----------Dashboard Card Start---------- */}
                            <Row className='g-3'>
                                <Col sm={12} md={6} lg={6}>
                                    <DashboardCard Icon={<VehicleRegisterIcon />} Heading={'Total Vehicle Register'} SubHeading={vehicleAnalytics.totalVehicles} />
                                </Col>
                                <Col sm={12} md={6} lg={6}>
                                    <DashboardCard Icon={<NewVehicleRegisterIcon />} Heading={'New Vehicle Registration'} SubHeading={vehicleAnalytics.totalNewVehicles} />
                                </Col>
                            </Row>
                            {/* ----------Dashboard Card End---------- */}
                            <WhitePanelBox>
                                <HorizontalLayout justifyContent={'between'} alignItems={'center'} myClass={'flex-sm-column'} gap={3}>
                                    <Typography className={'mb-0 fw-medium'}>Vehicle List</Typography>
                                    <HorizontalLayout gap={2} justifyContent={'center'} alignItems={'center'}>
                                        <NewInputField
                                            FormType='date'
                                            name='startDate'
                                            endIcon={<CalenderIcon />}
                                            FormPlaceHolder='Start Date'
                                            className='mb-0'
                                            value={filter.startDate}
                                            onChange={inputHandler}
                                        />
                                        <NewInputField
                                            FormType='date'
                                            name='endDate'
                                            endIcon={<CalenderIcon />}
                                            FormPlaceHolder='End Date'
                                            className='mb-0'
                                            onChange={inputHandler}
                                            value={filter.endDate}
                                        />
                                        <SvgIcon bg={'#E8EDFD'} size={'1rem'}>
                                            <SearchIcon onClick={searchHandler} />
                                        </SvgIcon>
                                        {(filter.startDate || filter.endDate) &&
                                            <SvgIcon bg={'#E8EDFD'} size={'1rem'}>
                                                <CancelIcon onClick={clearDateHandler} />
                                            </SvgIcon>
                                        }
                                    </HorizontalLayout>
                                </HorizontalLayout>
                            </WhitePanelBox>
                            <VehicleRegistrationTable
                                pagination={pagination}
                                pageHandler={pageHandler}
                                vehiclesData={vehiclesData}
                            />
                        </VerticalLayout>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
