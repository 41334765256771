import React from 'react'

export const SearchIcon = ({ onClick }) => {
    return (
        <>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
                <g clipPath="url(#clip0_577_18220)">
                    <path d="M6.22309 3.20151C6.49583 3.15831 6.68191 2.90219 6.63871 2.62944C6.59552 2.3567 6.3394 2.17062 6.06665 2.21382C4.4264 2.47361 3.13997 3.76004 2.88018 5.40029C2.83698 5.67303 3.02306 5.92915 3.29581 5.97235C3.56855 6.01555 3.82467 5.82947 3.86787 5.55672C4.05989 4.34436 5.01072 3.39353 6.22309 3.20151Z" fill="black" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M7.33301 0.166626C3.74316 0.166626 0.833008 3.07678 0.833008 6.66663C0.833008 10.2565 3.74316 13.1666 7.33301 13.1666C10.9229 13.1666 13.833 10.2565 13.833 6.66663C13.833 3.07678 10.9229 0.166626 7.33301 0.166626ZM1.83301 6.66663C1.83301 3.62906 4.29544 1.16663 7.33301 1.16663C10.3706 1.16663 12.833 3.62906 12.833 6.66663C12.833 9.70419 10.3706 12.1666 7.33301 12.1666C4.29544 12.1666 1.83301 9.70419 1.83301 6.66663Z" fill="black" />
                    <path d="M13.0199 11.6465C12.8247 11.4512 12.5081 11.4512 12.3128 11.6465C12.1176 11.8417 12.1176 12.1583 12.3128 12.3536L14.9794 15.0202C15.1747 15.2155 15.4913 15.2155 15.6865 15.0202C15.8818 14.8249 15.8818 14.5084 15.6865 14.3131L13.0199 11.6465Z" fill="black" />
                </g>
                <defs>
                    <clipPath id="clip0_577_18220">
                        <rect width="16" height="16" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}
