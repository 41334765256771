import React from 'react'

export const Logout = () => {
    return (
        <>

            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 7L15 12L10 17" stroke="#191D23" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15 12L3 12" stroke="#191D23" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V5C21 4.46957 20.7893 3.96086 20.4142 3.58579C20.0391 3.21071 19.5304 3 19 3H15" stroke="#191D23" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

        </>
    )
}
