import { useLocation, useNavigate } from "react-router-dom";
import { LinkSidebar } from "../../components/LinkSidebar";
import { Image, Navbar, Offcanvas, Stack } from "react-bootstrap";
import { WhitePanelBox } from "../../components/WhitePanelBox";
import Typography from "../../components/Typography";
import { DashboardIcon } from "../../components/Icons/DashboardIcon";
import { CreateLocationIcon } from "../../components/Icons/CreateLocationIcon";
import { UserAccountIcon } from "../../components/Icons/UserAccountIcon";
import { CreateProductIcon } from "../../components/Icons/CreateProductIcon";
import { VehicleRegistrationIcon } from "../../components/Icons/VehicleRegistrationIcon";
import { AddOnServicesIcon } from "../../components/Icons/AddOnServicesIcon";
import { CameraFeedIcon } from "../../components/Icons/CameraFeedIcon";
import { TransactionIcon } from "../../components/Icons/TransactionIcon";
import { SecurityIcon } from "../../components/Icons/SecurityIcon";
import { useContext, useState } from "react";
import { ReportsIcon } from "../../components/Icons/ReportsIcon";
import { OccupancyIcon } from "../../components/Icons/OccupancyIcon";
import { SlotIcon } from "../../components/Icons/SlotIcon";
import { PaymentTransactionIcon } from "../../components/Icons/PaymentTransactionIcon";
import { VerticalLayout } from "../../components/VerticalLayout";
import { HorizontalLayout } from "../../components/HorizontalLayout";
import { Setting } from "../../components/Icons/Setting";
import { Logout } from "../../components/Icons/Logout";
import { AuthContext } from "../../states/AuthContext";
import { SharedButton } from "../../components/SharedButton";
import { ChatIcon } from "../../components/Icons/ChatIcon";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";

export const Sidebar = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { setLoggedIn, profileData, setProfileData } = useContext(AuthContext);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Toggle dropdown function
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const logOutHandler = () => {
        localStorage.removeItem("authToken");
        localStorage.removeItem("profileData");
        localStorage.removeItem("loggedIn");
        setLoggedIn(false);
        setProfileData({});
        navigate('/');
    };


    // const navigate = useNavigate();
    const pathname = useLocation().pathname;
    return (
        <>
            <WhitePanelBox className={'AdminSidebar h-100'}>

                <HorizontalLayout gap={2} justifyContent={'between'} alignItems={'center'}>
                    <Image src="/assets/images/logo.png" fluid className="px-md-4 w-sm-75" />
                    <Navbar bg="transparent" expand="lg">
                        <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={handleShow} className='border-0' />
                    </Navbar>
                </HorizontalLayout>

                {/* ------------sidebar-------------- */}
                <VerticalLayout gap={3} myClass='d-none d-lg-block' >
                    <Typography color='#000' className='fw-semibold'>Menu</Typography>
                    <ul className="p-0 list-unstyled" >
                        <Stack direction={'vertical'} gap={3} className="p-1">
                            <li className={`p-2 ${pathname === '/dashboard' ? "active" : " "}`} >
                                <LinkSidebar linkIcon={<DashboardIcon color={'#000'} />} linkLabel={'Dashboard'} linkPath={'/dashboard'} />
                            </li>
                            <li className={`p-2 ${pathname === '/admin-users' || pathname === '/add-admin-user' || pathname === '/edit-admin-user' ? "active" : " "}`}>
                                <LinkSidebar linkIcon={<UserAccountIcon />} linkLabel={'Admin Users'} linkPath={'/admin-users'} />
                            </li>
                            <li className={`p-2 ${pathname === '/location-list' || pathname === '/create-location' || pathname === '/location-detail' || pathname === "/edit-location" || pathname === "/edit-lane" || pathname === "/view-slot" || pathname === "/edit-slot" ? "active" : " "}`}>
                                <LinkSidebar linkIcon={<CreateLocationIcon />} linkLabel={'Create Location'} linkPath={'/location-list'} />
                            </li>
                            <li className={`p-2 ${pathname === '/users-account' || pathname === '/sub-user-detail' || pathname === '/user-detail' ? "active" : " "}`}>
                                <LinkSidebar linkIcon={<UserAccountIcon />} linkLabel={'User Account'} linkPath={'/users-account'} />
                            </li>
                            <li className={`p-2 ${pathname === '/create-product' ? "active" : " "}`}>
                                <LinkSidebar linkIcon={<CreateProductIcon />} linkLabel={'Create Product'} linkPath={'/create-product'} />
                            </li>
                            <li className={`p-2 ${pathname === '/vehicle-registration' ? "active" : " "}`}>
                                <LinkSidebar linkIcon={<VehicleRegistrationIcon />} linkLabel={'Vehicle Registration'} linkPath={'/vehicle-registration'} />
                            </li>
                            <li className={`p-2 ${pathname === '/add-on-service' || pathname === '/add-service' || pathname === '/edit-service' ? "active" : " "}`}>
                                <LinkSidebar linkIcon={<AddOnServicesIcon />} linkLabel={'Add On Services'} linkPath={'/add-on-service'} />
                            </li>
                            <li className={`p-2 ${pathname === '/camera-list' || pathname === '/edit-camera' || pathname === '/add-camera' ? "active" : " "}`}>
                                <LinkSidebar linkIcon={<CameraFeedIcon />} linkLabel={'Camera'} linkPath={'/camera-list'} />
                            </li>
                            <li className={`p-2 ${pathname === '/chats' ? "active" : " "}`}>
                                <LinkSidebar linkIcon={<ChatIcon />} linkLabel={'Chats'} linkPath={'/chats'} />
                            </li>
                            <hr />
                            <li className={`p-2 ${pathname === '/transactions' || pathname === '/transaction-detail' ? "active" : " "}`}>
                                <LinkSidebar linkIcon={<TransactionIcon />} linkLabel={'Transaction'} linkPath={'/transactions'} />
                            </li>
                            <li className={`p-2 ${pathname === '/security' || pathname === '/security-detail' ? "active" : " "}`}>
                                <LinkSidebar linkIcon={<SecurityIcon />} linkLabel={'Security'} linkPath={'/security'} />
                            </li>
                            <li className={`p-2 ${pathname === '/reports' ? 'active' : ' '}`}>
                                {/* Main link that toggles the dropdown */}
                                <div onClick={toggleDropdown} className="cursor-pointer">
                                    <HorizontalLayout  gap={5}>

                                        <LinkSidebar
                                            linkIcon={<ReportsIcon />}
                                            linkLabel={'Reports'}
                                            linkPath={'#'} // No navigation for the main link
                                        />
                                       {isDropdownOpen ? <RiArrowDropUpLine size={30}/>:<RiArrowDropDownLine size={30}/>} 
                                    </HorizontalLayout>
                                </div>

                                {/* Dropdown menu */}
                                {isDropdownOpen && (
                                    <ul className="mx-auto p-2 dropdown list-unstyled text-center"  >
                                        <Stack direction={'vertical'} gap={3}>
                                        <li className={`p-2 mt-2`}>
                                            <LinkSidebar
                                                linkIcon={<UserAccountIcon />}
                                                linkLabel={'User Registration'}
                                                linkPath={'/reports'}
                                            />
                                        </li>
                                        <li className={`p-2`}>
                                            <LinkSidebar
                                                linkIcon={<VehicleRegistrationIcon />}
                                                linkLabel={'Vehicle Registration'}
                                                linkPath={'/access_control'}
                                            />
                                        </li>
                                        <li className={`p-2`}>
                                            <LinkSidebar
                                                linkIcon={<OccupancyIcon />}
                                                linkLabel={'Occupancy'}
                                                linkPath={'/user_permissions'}
                                            />
                                        </li>
                                        <li className={`p-2`}>
                                            <LinkSidebar
                                                linkIcon={<SlotIcon />}
                                                linkLabel={'Slot Assignments'}
                                                linkPath={'/user_permissions'}
                                            />
                                        </li>
                                        <li className={`p-2`}>
                                            <LinkSidebar
                                                linkIcon={<PaymentTransactionIcon />}
                                                linkLabel={'Payment Transactions'}
                                                linkPath={'/user_permissions'}
                                            />
                                        </li>
                                        </Stack>
                                    </ul>
                                )}
                            </li>
                            <hr />
                            <li className={`p-2 ${pathname === '/profile' ? "active" : " "}`}>
                                {profileData && <VerticalLayout justifyContent={'center'} alignItems={'center'}>
                                    <Image src="../../assets/images/imagePlaceholder.png" width={100} height={100} alt="" rounded />
                                    <Typography variant={'h5'}>{profileData?.userName}</Typography>
                                    <Typography>{profileData?.email}</Typography>
                                </VerticalLayout>}

                            </li>
                            <li>
                                <HorizontalLayout justifyContent={'center'} alignItems={'center'}>
                                    <Image src={''} fluid alt="" rounded />
                                    <LinkSidebar linkIcon={<Setting />} />
                                    <SharedButton variant="light" icon={<Logout />} onClick={logOutHandler} />
                                </HorizontalLayout>
                            </li>
                        </Stack>
                    </ul>
                </VerticalLayout>


                {/* --------Offcanvas Sidebar----------- */}

                <Offcanvas show={show} onHide={handleClose} placement="start" className='d-md-block d-block d-lg-none bg-white'>
                    <Offcanvas.Header className='py-0' closeButton>
                        <Image src="/assets/images/logo.png" fluid className="px-4 w-sm-75" />
                    </Offcanvas.Header>
                    <Offcanvas.Body clasName='py-0'>
                        <ul className="p-0 list-unstyled" >
                            <VerticalLayout gap={3} myClass={'mt-2 overflow-y-scroll'}  >
                                <li className={`p-2 ${pathname === '/dashboard' ? "active" : " "}`}>
                                    <LinkSidebar linkIcon={<DashboardIcon color={'#000'} />} linkLabel={'Dashboard'} linkPath={'/dashboard'} />
                                </li>
                                <li className={`p-2 ${pathname === '/location-list' || pathname === '/create-location' || pathname === '/location-detail' || pathname === "/edit-location" || pathname === "/edit-lane" || pathname === "/view-slot" || pathname === "/edit-slot" ? "active" : " "}`}>
                                    <LinkSidebar linkIcon={<CreateLocationIcon />} linkLabel={'Create Location'} linkPath={'/location-list'} />
                                </li>
                                <li className={`p-2 ${pathname === '/users-account' || pathname === '/sub-user-detail' || pathname === '/user-detail' ? "active" : " "}`}>
                                    <LinkSidebar linkIcon={<UserAccountIcon />} linkLabel={'User Account'} linkPath={'/users-account'} />
                                </li>
                                <li className={`p-2 ${pathname === '/create-product' ? "active" : " "}`}>
                                    <LinkSidebar linkIcon={<CreateProductIcon />} linkLabel={'Create Product'} linkPath={'/create-product'} />
                                </li>
                                <li className={`p-2 ${pathname === '/vehicle-registration' ? "active" : " "}`}>
                                    <LinkSidebar linkIcon={<VehicleRegistrationIcon />} linkLabel={'Vehicle Registration'} linkPath={'/vehicle-registration'} />
                                </li>
                                <li className={`p-2 ${pathname === '/add-on-service' || pathname === '/add-service' ? "active" : " "}`}>
                                    <LinkSidebar linkIcon={<AddOnServicesIcon />} linkLabel={'Add On Services'} linkPath={'/add-on-service'} />
                                </li>
                                <li className={`p-2 ${pathname === '/camera-list' || pathname === '/edit-camera' || pathname === '/add-camera' ? "active" : " "}`}>
                                    <LinkSidebar linkIcon={<CameraFeedIcon />} linkLabel={'Camera'} linkPath={'/camera-list'} />
                                </li>
                                <li className={`p-2 ${pathname === '/chats' ? "active" : " "}`}>
                                    <LinkSidebar linkIcon={<ChatIcon />} linkLabel={'Chats'} linkPath={'/chats'} />
                                </li>
                                <hr />
                                <li className={`p-2 ${pathname === '/transactions' || pathname === '/transaction-detail' ? "active" : " "}`}>
                                    <LinkSidebar linkIcon={<TransactionIcon />} linkLabel={'Transaction'} linkPath={'/transactions'} />
                                </li>
                                <li className={`p-2 ${pathname === '/security' || pathname === '/security-detail' ? "active" : " "}`}>
                                    <LinkSidebar linkIcon={<SecurityIcon />} linkLabel={'Security'} linkPath={'/security'} />
                                </li>
                                <li className={`p-2 ${pathname === '/reports' ? 'active' : ' '}`} >
                                    {/* Main link that toggles the dropdown */}
                                    <div onClick={toggleDropdown} className="cursor-pointer">
                                        <HorizontalLayout justifyContent={'between'} gap={3}>

                                            <LinkSidebar
                                                linkIcon={<ReportsIcon />}
                                                linkLabel={'Reports'}
                                                linkPath={'#'} // No navigation for the main link
                                            />
                                            <RiArrowDropDownLine />
                                        </HorizontalLayout>

                                    </div>

                                    {/* Dropdown menu */}
                                    {isDropdownOpen && (
                                        <ul className="dropdown list-unstyled"  >
                                            <li className="mb-3 mt-2">
                                                <LinkSidebar
                                                    linkIcon={<UserAccountIcon />}
                                                    linkLabel={'User Registration'}
                                                    linkPath={'/security_settings'}
                                                />
                                            </li>
                                            <li className="mb-3 mt-2">
                                                <LinkSidebar
                                                    linkIcon={<VehicleRegistrationIcon />}
                                                    linkLabel={'Vehicle Registration'}
                                                    linkPath={'/access_control'}
                                                />
                                            </li>
                                            <li className="mb-3 mt-2">
                                                <LinkSidebar
                                                    linkIcon={<OccupancyIcon />}
                                                    linkLabel={'Occupancy'}
                                                    linkPath={'/user_permissions'}
                                                />
                                            </li>
                                            <li className="mb-3 mt-2">
                                                <LinkSidebar
                                                    linkIcon={<SlotIcon />}
                                                    linkLabel={'Slot Assignments'}
                                                    linkPath={'/user_permissions'}
                                                />
                                            </li>
                                            <li className="mb-3 mt-2">
                                                <LinkSidebar
                                                    linkIcon={<PaymentTransactionIcon />}
                                                    linkLabel={'Payment Transactions'}
                                                    linkPath={'/user_permissions'}
                                                />
                                            </li>
                                        </ul>
                                    )}
                                </li>
                                <hr />
                                <li className={`p-2 ${pathname === '/profile' ? "active" : " "}`} >
                                    {profileData && <VerticalLayout justifyContent={'center'} alignItems={'center'}>
                                        <Image src="../../assets/images/imagePlaceholder.png" width={100} height={100} alt="" rounded />
                                        <Typography variant={'h5'}>{profileData?.userName}</Typography>
                                        <Typography>{profileData?.email}</Typography>
                                    </VerticalLayout>}

                                </li>
                                <li>
                                    <HorizontalLayout justifyContent={'center'} alignItems={'center'}>
                                        <Image src={''} fluid alt="" rounded />
                                        <LinkSidebar linkIcon={<Setting />} />
                                        <SharedButton variant="light" icon={<Logout />} onClick={logOutHandler} />
                                    </HorizontalLayout>
                                </li>
                            </VerticalLayout>
                        </ul>
                    </Offcanvas.Body >
                </Offcanvas >

            </WhitePanelBox >
        </>

    );
}


