import React, { useEffect, useState } from 'react'
import { Col, Row, Stack, Table } from 'react-bootstrap'
import { WhitePanelBox } from '../../../components/WhitePanelBox'
import { SharedButton } from '../../../components/SharedButton';
import { AddIcon } from '../../../components/Icons/AddIcon';
import { LeftArrow } from '../../../components/Icons/LeftArrow';
import Select from '../../../components/Select';
import { NewInputField } from '../../../components/NewInputField';
import { errorAlert, successAlert } from '../../../components/Alert';
import { createProductsAPI, getParkingLocationOptionAPI, getProductsAPI, updateProductPriceAPI } from '../../../services/NetworkCall';
import { IconTooltip } from '../../../components/IconTooltip';
import { EditIcon } from '../../../components/Icons/EditIcon';

export const ParkingPrice = ({ setLoading }) => {
    const [inData, setInData] = useState({
        "id": "",
        "locationId": "",
        "dailyPrice": "",
        "weeklyPrice": "",
        "monthlyPrice": "",
        "subscriptionPrice": "",
        "stripeProductId": ""
    });

    const [error, setError] = useState({
        "dailyPrice": "",
        "weeklyPrice": "",
        "monthlyPrice": "",
        "subscriptionPrice": "",
        "locationId": ""
    });

    const [exProductsData, setExProductsData] = useState([]);
    const [createPr, setCreatePr] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [locationData, setLocationData] = useState([]);

    const inputHandler = (e) => {
        const { name, value, type } = e.target;

        if (type === 'number') {
            const regex = /^\d*(\.\d{0,2})?$/;
            if (value.includes('.') && value.split('.')[1].length > 2) {

            } else {
                setInData((pre) => ({ ...pre, [name]: value }));
                setError((pre) => ({ ...pre, [name]: "" }));
            }
        } else {
            setInData((pre) => ({ ...pre, [name]: value }));
            setError((pre) => ({ ...pre, [name]: "" }));
        }
    }

    const getProductData = async () => {
        setLoading(true);
        const res = await getProductsAPI();
        if (res.success) {
            const data = res.data;
            setExProductsData(data);
            setLoading(false);
        }
        setLoading(false);
    }

    const getLocationOptions = async () => {
        setLoading(true);
        const res = await getParkingLocationOptionAPI();
        if (res.success) {
            const data = res.data;
            const pre = data.map((e) => ({ "name": e?.locationName, "value": e?._id }));
            setLocationData(pre)
        }
        setLoading(false);
    }


    useEffect(() => {
        getProductData();
        getLocationOptions();
    }, []);


    const submitHandler = async (e) => {
        e.preventDefault();
        let isValid = true;
        const { dailyPrice, weeklyPrice, monthlyPrice, subscriptionPrice, locationId } = inData;

        if (!locationId) { setError((pre) => ({ ...pre, "locationId": 'Required' })); isValid = false; }
        if (!dailyPrice) { setError((pre) => ({ ...pre, "dailyPrice": 'Required' })); isValid = false; }
        if (!weeklyPrice) { setError((pre) => ({ ...pre, "weeklyPrice": 'Required' })); isValid = false; }
        if (!monthlyPrice) { setError((pre) => ({ ...pre, "monthlyPrice": 'Required' })); isValid = false; }
        if (!subscriptionPrice) { setError((pre) => ({ ...pre, "subscriptionPrice": 'Required' })); isValid = false; }

        if (isValid) {
            setLoading(true);
            const data = {
                "parkingLocationId": locationId,
                "dayPrice": dailyPrice,
                "weeklyPrice": weeklyPrice,
                "monthlyPrice": monthlyPrice,
                "monthlySubscriptionPrice": subscriptionPrice
            }

            const res = await createProductsAPI(data);

            if (res.success) {
                setInData({
                    "id": "",
                    "locationId": "",
                    "dailyPrice": "",
                    "weeklyPrice": "",
                    "monthlyPrice": "",
                    "subscriptionPrice": "",
                    "stripeProductId": ""
                })

                getProductData();
                setCreatePr(false);
                setLoading(false);
                successAlert({ message: res.message })
            } else {
                setLoading(false);

                errorAlert({ message: res.message });
            }
            setLoading(false);
        }
    }

    const updateHandler = async (e) => {
        e.preventDefault();
        let isValid = true;
        const { id, dailyPrice, weeklyPrice, monthlyPrice, subscriptionPrice, stripeProductId, locationId } = inData;

        if (!locationId) { setError((pre) => ({ ...pre, "locationId": 'Required' })); isValid = false; }

        if (!dailyPrice) { setError((pre) => ({ ...pre, "dailyPrice": 'Required' })); isValid = false; }
        if (!weeklyPrice) { setError((pre) => ({ ...pre, "weeklyPrice": 'Required' })); isValid = false; }
        if (!monthlyPrice) { setError((pre) => ({ ...pre, "monthlyPrice": 'Required' })); isValid = false; }
        if (!subscriptionPrice) { setError((pre) => ({ ...pre, "subscriptionPrice": 'Required' })); isValid = false; }

        if (isValid) {
            setLoading(true);
            const data = {
                "parkingLocationId": locationId,
                "dayPrice": dailyPrice,
                "weeklyPrice": weeklyPrice,
                "monthlyPrice": monthlyPrice,
                "monthlySubscriptionPrice": subscriptionPrice,
                "stripeProductId": stripeProductId
            }
            const res = await updateProductPriceAPI(id, data);
            if (res.success) {
                setInData({
                    "id": "",
                    "locationId": "",
                    "dailyPrice": "",
                    "weeklyPrice": "",
                    "monthlyPrice": "",
                    "subscriptionPrice": "",
                    "stripeProductId": ""
                })
                getProductData();
                setIsEdit(false);
                setLoading(false);
                successAlert({ message: res.message })
            } else {
                setLoading(false);
                errorAlert({ message: res.message });
            }
            setLoading(false);
        }
    }

    const handleBack = () => {
        setCreatePr(false);
        setIsEdit(false);
        inData.locationId = '';
        inData.subscriptionPrice = '';
        inData.dailyPrice = '';
        inData.weeklyPrice = '';
        inData.monthlyPrice = '';
    }

    const editHandler = (d) => {
        setIsEdit(true);
        setInData({
            "id": d?._id,
            "locationId": d?.parkingLocationId,
            "dailyPrice": d?.dayPrice?.price,
            "weeklyPrice": d?.weeklyPrice?.price,
            "monthlyPrice": d?.monthlyPrice?.price,
            "subscriptionPrice": d?.monthlySubscriptionPrice?.price,
            "stripeProductId": d?.stripeProductId
        })
    }

    return (
        <>
            {!createPr && !isEdit ? (
                <>
                    <Stack direction="horizontal" className="justify-content-end">
                        <SharedButton className={'w-25 mb-3'} type={'button'} label={'Add Parking Price'} icon={<AddIcon color={'#fff'} />} onClick={() => setCreatePr(true)} />
                    </Stack>
                    <WhitePanelBox>
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>LOCATION NAME</th>
                                    <th>Day Price</th>
                                    <th>Weekly Price</th>
                                    <th>Monthly Price</th>
                                    <th>Monthly Subscription Price</th>
                                    <th>ACTION</th>
                                </tr>
                            </thead>
                            <tbody>
                                {exProductsData && exProductsData.map((location, index) => (
                                    <tr key={index}>
                                        <td>
                                            {location?.parkingLocationId?.locationName}
                                        </td>
                                        <td className='text-center'>{location?.dayPrice?.price}</td>
                                        <td className='text-center'>{location?.weeklyPrice?.price}</td>
                                        <td className='text-center'>{location?.monthlyPrice?.price}</td>
                                        <td className='text-center'>{location?.monthlySubscriptionPrice?.price}</td>
                                        <th>
                                            <SharedButton
                                                type={'button'}
                                                onClick={() => { editHandler(location) }}
                                                label={'Edit'}
                                                icon={<EditIcon color={'#4E78EC'} />}
                                                variant={'light-primary'}
                                                size={'md'}
                                                className={'rounded-5 text-primary me-2'} /></th >
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </WhitePanelBox>
                </>)
                :
                (<>
                    <Stack direction="horizontal" className="justify-content-end ">
                        <SharedButton
                            variant={'light'}
                            className={'rounded-5 mb-3'}
                            type={'button'}
                            label={'Back'}
                            icon={<LeftArrow color={'#000'} />}
                            onClick={handleBack} />
                    </Stack>
                    <WhitePanelBox className={'p-5'}>
                        <Row className='g-3'>
                            <Col md={3} className='mb-4' >
                                <Select
                                    myClassName={'rounded-2 bg-white'}
                                    Array={locationData}
                                    name="locationId"
                                    FormLabel={"Location"}
                                    readOnly={isEdit}
                                    onChange={inputHandler}
                                    value={inData.locationId}
                                    error={error.locationId}
                                />
                            </Col>
                            <Col md={3} className='mb-4'>
                                <NewInputField
                                    FormLabel={'Day Price'}
                                    placeholder={'day price'}
                                    FormType={'number'}
                                    value={inData.dailyPrice}
                                    name={'dailyPrice'}
                                    error={error.dailyPrice}
                                    min={'0'}
                                    className={'rounded-2 bg-white'}
                                    onChange={inputHandler} />
                            </Col>
                            <Col md={3} className='mb-4'>
                                <NewInputField
                                    FormLabel={'Weekly Price'}
                                    placeholder={'weekly price'}
                                    FormType={'number'}
                                    name={'weeklyPrice'}
                                    value={inData.weeklyPrice}
                                    error={error.weeklyPrice}
                                    min={'0'}
                                    className={'rounded-2 bg-white'}
                                    onChange={inputHandler} />
                            </Col>
                            <Col md={3} className='mb-4'>
                                <NewInputField
                                    FormLabel={'Monthly Price'}
                                    placeholder={'monthly price'}
                                    FormType={'number'}
                                    name={'monthlyPrice'}
                                    value={inData.monthlyPrice}
                                    error={error.monthlyPrice}
                                    min={'0'}
                                    className={'rounded-2 bg-white'}
                                    onChange={inputHandler} />

                            </Col>
                            <Col md={3} className='mb-4'>
                                <NewInputField
                                    FormLabel={'Monthly Subscription Price'}
                                    placeholder={'price'}
                                    FormType={'number'}
                                    name={'subscriptionPrice'}
                                    value={inData.subscriptionPrice}
                                    min={'0'}
                                    error={error.subscriptionPrice}
                                    className={'rounded-2 bg-white'}
                                    onChange={inputHandler} />
                            </Col>
                        </Row>
                        <Row className='g-3 mt-3'>
                            <Col md={4}>
                                {isEdit ?
                                    <SharedButton
                                        label={'Update'}
                                        onClick={updateHandler}
                                        variant={'primary'}
                                        size={'md'}
                                        className={'w-100 my-2'} />
                                    : <SharedButton
                                        onClick={submitHandler}
                                        label={'Save'}
                                        variant={'primary'}
                                        size={'md'}
                                        className={'w-100 my-2'} />
                                }
                            </Col>
                        </Row>
                    </WhitePanelBox>
                </>)
            }
        </>
    )
}
