import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Image, Badge } from 'react-bootstrap'
import { Loader } from '../../../components/Loader'
import { Sidebar } from '../../../commonPages/sidebar'
import { VerticalLayout } from '../../../components/VerticalLayout'
import { WhitePanelBox } from '../../../components/WhitePanelBox'
import { HorizontalLayout } from '../../../components/HorizontalLayout'
import { Heading } from '../../../components/Heading'
import { SvgIcon } from '../../../components/SvgIcon'
import { SearchIcon } from '../../../components/Icons/SearchIcon'
import { Notification } from '../../../components/Icons/Notification'
import { Delete } from '../../../components/Icons/Delete'
import Typography from '../../../components/Typography'
import { SharedButton } from '../../../components/SharedButton'
import { EditIcon } from '../../../components/Icons/EditIcon'
import { LeftArrow } from '../../../components/Icons/LeftArrow'
import { LocationIcon } from '../../../components/Icons/LocationIcon'
import { LaneIcon } from '../../../components/Icons/LaneIcon'
import { SlotIcon } from '../../../components/Icons/SlotIcon'
import { PowerIcon } from '../../../components/Icons/PowerIcon'
import { WifiIcon } from '../../../components/Icons/WifiIcon'
import { ParkingAssignmentIcon } from '../../../components/Icons/ParkingAssignmentIcon'
import { VacuumIcon } from '../../../components/Icons/VacuumIcon'
import { AirTireIcon } from '../../../components/Icons/AirTireIcon'
import { useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { deleteParkingLocationAPI } from '../../../services/NetworkCall'
import { errorAlert, successAlert } from '../../../components/Alert'
import Header from '../../../components/Header'



export const LocationDetail = () => {
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [preData, setPreData] = useState({ 'id': "", 'name': '', 'address': '', "link": "", "image": "", "nZone": "", "tSlot": "", 'avSlot': [] });


    useEffect(() => {
        if (location?.state?.data) {
            let data = location?.state?.data;
            let pre = { ...preData };
            pre.id = data._id;
            pre.name = data.locationName;
            pre.address = data.locationAddress;
            pre.image = data.locationImage;
            pre.link = data.locationUrl;
            pre.nZone = data.numberOfZones;
            pre.tSlot = data.totalSlots;
            pre.avSlot = data.availableAddOnServicesOnSlots;
            setPreData(pre);
        }
    }, [location])


    const navigateHandler = (input = '') => navigate("/edit-location", { state: { data: input } });

    const deleteHandler = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await deleteParkingLocationAPI(id);
                if (res.success) {
                    setLoading(false);
                    successAlert({ message: res.message });
                    navigate("/location-list");
                } else {
                    setLoading(false);
                    errorAlert({ message: res.message });
                }
                setLoading(false);
            }
        });

    }


    return (
        <>
            <Loader show={loading} />
            <Container fluid>
                <Row className="g-3">
                    <Col md={3}>
                        <Sidebar />
                    </Col>
                    <Col md={9}>
                        <VerticalLayout gap={3}>
                            <Header
                                heading={"Location"}
                                subHeading={'Welcome ! Admin Truck'}
                                showNotificationIcon={true}
                            />
                            <HorizontalLayout justifyContent={'between'} alignItems={'center'}>
                                <SharedButton onClick={() => window.history.back()} className={'rounded-5'} variant={'light'} size={'md'} type={'button'} label={'Back'} icon={<LeftArrow color={'#000'} />} />
                                <HorizontalLayout justifyContent={'between'} alignItems={'center'}>

                                    {/* <SharedButton className={'rounded-5 text-primary me-2'} onClick={() => navigateHandler(preData.id)} variant={'light-primary'} size={'md'} type={'button'} label={'Edit'} icon={<EditIcon color={'#4E78EC'} />} /> */}

                                    <SharedButton onClick={() => deleteHandler(preData.id)} className={'rounded-5 text-danger'} variant={'danger-light'} size={'md'} type={'button'} label={'Delete'} icon={<Delete color={'#000'} />} />

                                </HorizontalLayout>
                            </HorizontalLayout>
                            <WhitePanelBox>
                                <HorizontalLayout gap={2} justifyContent={'between'} alignItems={'center'}>
                                    <HorizontalLayout gap={3}>
                                        <Image src={preData?.image} alt='' fluid className='bg-white' style={{ maxWidth: '116px' }} />
                                        <VerticalLayout justifyContent={'center'} alignItems={'start'}>
                                            <Typography variant={'h4'} className={'mb-0'}>{preData?.name}</Typography>
                                            <Typography variant={'p'} color={'#7d7d7d'} className={'mb-0'}>{preData?.address}</Typography>
                                        </VerticalLayout>
                                    </HorizontalLayout>
                                    <SvgIcon bg={'#E8EDFD'} size={'1rem'} >
                                        <div onClick={() => window.open(preData.link, '_blank')}>
                                            <LocationIcon />
                                        </div>
                                    </SvgIcon>
                                </HorizontalLayout>
                            </WhitePanelBox>
                            <Typography className={'fw-600'} color={'#000'}>Services</Typography>
                            <WhitePanelBox>
                                <Row className='g-3'>
                                    <Col >
                                        <Badge className="rounded-5 fs-6 text-dark fw-normal mb-3 me-2" bg="light">
                                            <LaneIcon />
                                            <span className='ms-2'>  {preData.nZone} Zone</span>

                                        </Badge>
                                        <Badge className="rounded-5 fs-6 text-dark fw-normal mb-3 me-2" bg="light">
                                            <SlotIcon />
                                            <span className='ms-2'>
                                                {preData.tSlot} Slot
                                            </span>

                                        </Badge>
                                        {preData?.avSlot?.map((e, index) => (
                                            <Badge className="rounded-5 fs-6 text-dark fw-normal mb-3" bg="light" key={index}>
                                                <Image src={e?.image} alt='' classsName='border rounded-3 me-1' width={30} height={30} />
                                                {e.name}
                                            </Badge>
                                        ))}
                                    </Col>
                                </Row>
                            </WhitePanelBox>
                        </VerticalLayout>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
