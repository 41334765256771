import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Accordion, Image } from 'react-bootstrap'
import { Loader } from '../../../components/Loader'
import { Sidebar } from '../../../commonPages/sidebar'
import { VerticalLayout } from '../../../components/VerticalLayout'
import { WhitePanelBox } from '../../../components/WhitePanelBox'
import { HorizontalLayout } from '../../../components/HorizontalLayout'
import { Heading } from '../../../components/Heading'
import { SvgIcon } from '../../../components/SvgIcon'
import { SearchIcon } from '../../../components/Icons/SearchIcon'
import { Notification } from '../../../components/Icons/Notification'
import { Delete } from '../../../components/Icons/Delete'
import Typography from '../../../components/Typography'
import { SharedButton } from '../../../components/SharedButton'
import { LeftArrow } from '../../../components/Icons/LeftArrow'
import { LocationIcon } from '../../../components/Icons/LocationIcon'
import { AddLaneIcon } from '../../../components/Icons/AddLaneIcon'
import { useLocation, useNavigate } from 'react-router-dom'
import { Checkbox } from '../../../components/Checkbox'
import { NewInputField } from '../../../components/NewInputField'
import { UploadFile } from '../../../components/UploadFile'
import Select from '../../../components/Select'
import { createParkingZonesAPI, getAddonServicesAPI, getCameras } from '../../../services/NetworkCall'
import { updateCheckedServices, validateZoneData } from '../../../helper/Helper'
import { errorAlert, successAlert } from '../../../components/Alert'
import { InputFieldAutoUpdate } from '../../../components/InputFieldAutoUpdate'
import Swal from 'sweetalert2'
import CopyDataIcon from '../../../components/Icons/CopyDataIcon'

export const CreateZone = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const [inLocation, setInLocation] = useState({ "id": "", "name": "", "address": "", "link": "", "image": "", "preImage": "" });
    const [erLocation, setErLocation] = useState({ "name": "", "address": "", "link": "", "image": "" });
    const [serviceData, setServiceData] = useState([]);
    const [optionCamera, setOptionCamera] = useState([]);


    useEffect(() => {
        if (location?.state?.data) {
            const data = location?.state?.data;
            let pre = { ...inLocation };
            pre.id = data?.id;
            pre.name = data?.name;
            pre.address = data?.address;
            pre.link = data?.link;
            pre.preImage = data?.preImage;
            setInLocation(pre);
        }

    }, [location])

    const [zoneData, setZoneData] = useState([{
        "zoneName": "",
        "slotCount": 1,
        "slots": [
            {
                "slotNumber": "",
                "slotLength": "",
                "slotWidth": "",
                "cameraId": "",
                "addonServiceIds": [],
                "isPreferredSlot": false
            }
        ]
    }])

    const getCamerasAPI = async () => {
        const res = await getCameras();
        if (res.success) {
            const data = res.data;
            let pre = [...data.map((e) => ({
                'name': e?.cameraName,
                'value': e?._id,
            }))];
            setOptionCamera(pre);
        }
    }

    const getServiceList = async () => {
        try {
            setLoading(true);
            const res = await getAddonServicesAPI();
            if (res.success) {
                const data = res.data;
                let pre = [{
                    'id': '',
                    'serviceName': "Select All",
                    "isChecked": false
                }, ...data.map((e) => ({
                    'id': e?._id,
                    'serviceName': e?.name,
                    "isChecked": false
                }))];
                setServiceData(pre);
                // Clone zoneData to avoid mutating state directly
                let preZoneData = [...zoneData];

                // Check if preZoneData[0], preZoneData[0].slot, and preZoneData[0].slot[0] exist
                if (preZoneData[0]?.slots?.[0]) {
                    preZoneData[0].slots[0].addonServiceIds = pre;
                    setZoneData(preZoneData);
                } else {
                    console.error("Zone data structure is not as expected.");
                }
            }
        } catch (error) {
            console.error("Error fetching addonServiceIds:", error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => { getServiceList(); getCamerasAPI(); }, [])

    const locationHandler = (e) => {
        const { name, value } = e.target;
        setInLocation((pre) => ({ ...pre, [name]: value }));
        setErLocation((pre) => ({ ...pre, [name]: '' }));
    }

    const locationImageHandler = (data) => {
        const { name, value } = data;
        setInLocation((pre) => ({ ...pre, [name]: value }));
        setErLocation((pre) => ({ ...pre, [name]: "" }));
    }


    const addLaneNavigate = () => {
        const pre = [...zoneData];
        pre.push({
            "zoneName": "",
            "slotCount": 1,
            "slots": [
                {
                    "slotNumber": "",
                    "slotLength": "",
                    "slotWidth": "",
                    "cameraId": "",
                    "addonServiceIds": serviceData,
                    "isPreferredSlot": false
                }
            ]
        });
        setZoneData(pre);
    }

    const addSlotHandler = (zoneIndex) => {
        // Check if zoneIndex is valid
        if (zoneIndex < 0 || zoneIndex >= zoneData.length) {
            console.error("Invalid zone index:", zoneIndex);
            return;
        }
        const pre = [...zoneData];
        pre[zoneIndex].slots.push({
            slotNumber: "",  // Consider providing a default
            slotLength: "",  // Consider providing a default
            slotWidth: "",   // Consider providing a default
            cameraId: "",
            addonServiceIds: serviceData,
            isPreferredSlot: false
        });

        pre[zoneIndex].slotCount = pre[zoneIndex].slots.length;  // Optional chaining not needed here

        setZoneData(pre);
    };

    const deleteSlotHandler = (zoneIndex, slotIndex) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                const pre = [...zoneData];
                pre[zoneIndex].slots.splice(slotIndex, 1);
                pre[zoneIndex].slotCount = pre[zoneIndex].slots.length;
                setZoneData(pre);
                Swal.fire({
                    title: "Deleted!",
                    text: "Your file has been deleted.",
                    icon: "success"
                });
            }
        });
    }

    const deleteZoneHandler = (zoneIndex) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                if (zoneIndex > 0) {
                    const pre = [...zoneData];
                    pre.splice(zoneIndex, 1);
                    setZoneData(pre);
                }
                Swal.fire({
                    title: "Deleted!",
                    text: "Your file has been deleted.",
                    icon: "success"
                });
            }
        });

    }

    const zoneChangeHandler = (e, index) => {
        const { name, value } = e.target;
        if (name === "zoneName" && value !== "") {
            // Regular expression to allow letters and spaces, but not only digits
            const checkABC = /^[A-Za-z\s]+$/.test(value);
            const isOnlyDigits = /^\d+$/.test(value);
            if (!checkABC || isOnlyDigits) {
                errorAlert({ message: 'Zone name should contain only letters (A-Z, a-z) and spaces, without any numbers or special characters.' });
                return;
            }
        }
        const pre = [...zoneData];
        if (name == 'zoneName') {
            pre[index].zoneName = value;
            setZoneData(pre);
        } else if (name === 'slotCount') {
            if (value === 0 || value === '0' || value === '') {
                errorAlert({ message: 'Slot count should be greater than 0.' }); return;
            }
            pre[index].slotCount = value;
            const stLength = pre[index]?.slots?.length;
            if (value > stLength) {
                const diff = parseInt(value) - parseInt(stLength);
                for (let i = diff; i > 0; i--) {
                    pre[index].slots.push(
                        {
                            "slotNumber": "",
                            "slotLength": "",
                            "slotWidth": "",
                            "cameraId": "",
                            "addonServiceIds": serviceData,
                            "isPreferredSlot": false
                        });
                }
                setZoneData(pre);
            } else if (value < stLength) {
                pre[index].slotCount = value;
                const diff = parseInt(stLength) - parseInt(value);
                pre[index].slots.splice(-diff, diff);
                setZoneData(pre);
            }
        }

    }

    const slotChangeHandler = (e, zoneIndex, slotIndex) => {
        let { name, value } = e.target;
        if (name === 'isPreferredSlot') {
            value = e.target.checked;
        }
        const pre = [...zoneData];
        pre[zoneIndex].slots[slotIndex][name] = value;
        setZoneData(pre);
    }

    const checkHandler = (e, zoneIndex, slotIndex, serviceIndex) => {
        const { name, checked } = e.target;
        let pre = [...zoneData];
        let slots = { ...pre[zoneIndex].slots[slotIndex] };
        let addonServiceIds = [...slots.addonServiceIds];
        if (name === "Select All") {
            addonServiceIds = addonServiceIds.map((addonServiceIds) => ({
                ...addonServiceIds,
                isChecked: checked,
            }));
        } else {
            addonServiceIds[serviceIndex] = {
                ...addonServiceIds[serviceIndex],
                isChecked: checked,
            };
            const allChecked = addonServiceIds
                .slice(1)
                .every((addonServiceIds) => addonServiceIds.isChecked);
            addonServiceIds[0].isChecked = allChecked;
        }
        slots.addonServiceIds = addonServiceIds;
        pre[zoneIndex].slots[slotIndex] = slots;
        setZoneData(pre);
    };



    const submitHandler = async () => {
        const { id } = inLocation
        let isValid = true;
        const updatedData = updateCheckedServices(zoneData);
        isValid = validateZoneData(updatedData);

        if (isValid) {
            setLoading(true);
            const data = {
                "parkingLocationId": id,
                "zones": updatedData,
            }
            try {
                const res = await createParkingZonesAPI(data);
                setLoading(false);
                if (res.success) {
                    successAlert({ message: res.message });
                    navigate('/location-list');
                } else {
                    errorAlert({ message: res.message });
                }
            } catch (error) {
                setLoading(false);
                errorAlert({ message: "An error occurred while creating the location." });
                console.error(error);
            }
        }
    }

    const copyToAllHandler = (zoneIndex, slotIndex) => {
        Swal.fire({
            title: "Are you sure?",
            text: "This action cannot be undone!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#28a745", // Green for confirm
            cancelButtonColor: "#dc3545",  // Red for cancel
            confirmButtonText: "Yes, copy it!"
        }).then((result) => {
            if (result.isConfirmed) {
                const pre = [...zoneData];
                let slotData = pre[zoneIndex].slots[slotIndex]; // Access the slot directly

                // Use map to create a new array with updated values for all slots
                pre[zoneIndex].slots = pre[zoneIndex].slots.map((e) => ({
                    ...e,
                    slotLength: slotData.slotLength,
                    slotWidth: slotData.slotWidth,
                    cameraId: slotData.cameraId,
                    addonServiceIds: slotData.addonServiceIds,
                    isPreferredSlot: slotData.isPreferredSlot
                }));

                setZoneData(pre);

                Swal.fire({
                    title: "Copied!",
                    text: "All slots have been updated successfully.",
                    icon: "success",
                    confirmButtonColor: "#28a745" // Green for success
                });
            }
        });
    };

    return (
        <>
            <Loader show={loading} />
            <Container fluid>
                <Row className="g-3">
                    <Col md={3}>
                        <Sidebar />
                    </Col>
                    <Col md={9}>
                        <VerticalLayout gap={3}>
                            <WhitePanelBox className="Box">
                                <HorizontalLayout gap={2} justifyContent={'space-between'} alignItems={'center'}>
                                    <Heading Heading={'Add New Zone'} SubHeading={'Welcome ! Admin Truck'} />
                                    <HorizontalLayout gap={3}>
                                        <SvgIcon bg={'#E8EDFD'} size={'1rem'}>
                                            <SearchIcon />
                                        </SvgIcon>
                                        <SvgIcon bg={'#E8EDFD'} size={'1rem'}>
                                            <Notification />
                                        </SvgIcon>
                                    </HorizontalLayout>
                                </HorizontalLayout>
                            </WhitePanelBox>
                            <HorizontalLayout justifyContent={'between'} alignItems={'center'}>
                                <SharedButton className={'rounded-5'} onClick={() => window.history.back()} variant={'light'} size={'md'} type={'button'} label={'Back'} icon={<LeftArrow color={'#000'} />} />
                                <HorizontalLayout justifyContent={'between'} alignItems={'center'}>
                                    <SharedButton onClick={submitHandler} className={'rounded-5 text-dark'} variant={'light'} size={'md'} type={'button'} label={'Save'} />
                                </HorizontalLayout>
                            </HorizontalLayout>

                            {/* Location Form Field */}

                            <WhitePanelBox>
                                <VerticalLayout gap={3} justifyContent={'center'} alignItems={'start'}>
                                    {inLocation?.preImage &&
                                        <Image src={inLocation?.preImage} alt='image' style={{ maxHeight: "112px", maxWidth: "112px" }} className='w-100 ms-3' fluid rounded />
                                    }

                                    <Row className='g-3 w-100'>
                                        <Col md={4} sm={12}>
                                            <NewInputField readOnly={true} FormLabel={'Location Name'} FormPlaceHolder={'location name'} FormType={'text'} name={'name'} value={inLocation.name} className={'rounded-2 bg-white'} />
                                        </Col>
                                        <Col md={4} sm={12}>
                                            <NewInputField readOnly={true} FormLabel={'Location Address'} FormPlaceHolder={'location address'} FormType={'text'} name={'address'} value={inLocation.address} className={'rounded-2 bg-white'} />
                                        </Col>
                                        <Col md={4} sm={12}>
                                            <NewInputField readOnly={true} FormLabel={'Location Link'} FormType={'text'} FormPlaceHolder={'location link'} name={'link'} value={inLocation.link} className={'rounded-2 bg-white'} endIcon={<LocationIcon />} />
                                        </Col>
                                    </Row>
                                </VerticalLayout>
                            </WhitePanelBox>

                            {/* Zone Form Field */}
                            {zoneData.map((zone, zoneIndex) => (
                                <div key={zoneIndex}>
                                    <HorizontalLayout gap={1} justifyContent={'between'} alignItems={'center'}>
                                        <Typography className={'fw-600'} color={'#000'}>Zone</Typography>
                                        <span>
                                            {zoneIndex > 0 &&
                                                <SharedButton onClick={() => deleteZoneHandler(zoneIndex)} variant={'danger'} className={'rounded-5 me-2'} label={'Delete Zone'} icon={<Delete color={'white'} />} />
                                            }
                                            <SharedButton onClick={addLaneNavigate} variant={'primary'} className={'rounded-5'} label={'Add Zone'} icon={<AddLaneIcon />} />
                                        </span>
                                    </HorizontalLayout>
                                    <WhitePanelBox className={'mt-3'}>
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Zone - {zoneIndex}</Accordion.Header>
                                                <Accordion.Body>
                                                    <VerticalLayout gap={3} justifyContent={'center'} alignItems={'start'}>

                                                        <Row className='w-100 g-3 sm={12}'>
                                                            <Col md={4} sm={12}>
                                                                <NewInputField FormLabel={'Zone Name'} FormType={'text'} FormPlaceHolder={'zone name'} name={'zoneName'} value={zone?.zoneName || ""} className={'rounded-2 bg-white'} onChange={(e) => zoneChangeHandler(e, zoneIndex)} />
                                                            </Col>
                                                            <Col md={4} sm={12}>
                                                                <NewInputField FormLabel={'Parking Slot Count'} FormType={'number'} FormPlaceHolder={'slot count name'} min={1} name={'slotCount'} value={zone?.slotCount} className={'rounded-2 bg-white'} onChange={(e) => zoneChangeHandler(e, zoneIndex)} />
                                                            </Col>
                                                        </Row>

                                                        {zone?.slots.map((slot, slotIndex) => (
                                                            <div key={slotIndex}>
                                                                <span className='w-100 border-dashed mt-2 d-block' ></span>
                                                                <Row className='w-100 mt-4'>
                                                                    <Col md={4} sm={12}>
                                                                        <InputFieldAutoUpdate readOnly={true} changeValue={zone?.zoneName + " " + (slotIndex + 1)} FormLabel={'Parking Slot Name'} FormType={'text'} FormPlaceHolder={'parking slot name'} name={'slotNumber'} value={slot?.slotNumber} className={'rounded-2 bg-white'} zoneIndex={zoneIndex} slotIndex={slotIndex}
                                                                            onChange={slotChangeHandler}
                                                                        />

                                                                        {/* <NewInputField FormLabel={'Parking Slot Name'} FormType={'text'} FormPlaceHolder={'parking slot name'} name={'slotNumber'} value={slot?.slotNumber} className={'rounded-2 bg-white'} onChange={(e) => slotChangeHandler(e, zoneIndex, slotIndex)} /> */}
                                                                    </Col>
                                                                    <Col md={4} sm={12}>
                                                                        <NewInputField FormLabel={'Length (Feet)'} FormType={'number'} FormPlaceHolder={'length (feet)'} min={0} name={'slotLength'} value={slot?.slotLength} className={'rounded-2 bg-white'} onChange={(e) => slotChangeHandler(e, zoneIndex, slotIndex)} />
                                                                    </Col>
                                                                    <Col md={4} sm={12}>
                                                                        <NewInputField FormLabel={'Width (Feet)'} FormType={'number'} FormPlaceHolder={'width (feet)'} min={0} name={'slotWidth'} value={slot?.slotWidth} className={'rounded-2 bg-white'} onChange={(e) => slotChangeHandler(e, zoneIndex, slotIndex)} />
                                                                    </Col>
                                                                    <Col md={8} sm={12}>
                                                                        {/* <DropdownSelect /> */}
                                                                        <Row className='g-3'>
                                                                            <Col md={6}>
                                                                                <Select myClassName={'rounded-2 bg-white'} Array={optionCamera} name="cameraId" FormLabel={"Camera name"} value={slot?.cameraId} onChange={(e) => slotChangeHandler(e, zoneIndex, slotIndex)} />
                                                                            </Col>
                                                                            <Col md={6} className='mt-4'>
                                                                                <SvgIcon className={'rounded-2 mb-2 mt-2'}>
                                                                                    <Checkbox
                                                                                        label={"Preferred Slot"}
                                                                                        onClick={(e) => slotChangeHandler(e, zoneIndex, slotIndex)}
                                                                                        checked={slot?.isPreferredSlot}
                                                                                        name={'isPreferredSlot'}
                                                                                    />
                                                                                </SvgIcon>
                                                                            </Col>
                                                                        </Row>

                                                                    </Col>
                                                                    <Col md={4} sm={12}>
                                                                        {(zone?.slots?.length - 1 === slotIndex && slotIndex > 0) && (
                                                                            <SharedButton
                                                                                onClick={() => deleteSlotHandler(zoneIndex, slotIndex)}
                                                                                variant={'danger'}
                                                                                className={'rounded-5 mt-4 me-3'}
                                                                                label={'Delete Slot'}
                                                                                icon={<Delete color={'white'} />}
                                                                            />
                                                                        )}
                                                                        <SharedButton onClick={() => copyToAllHandler(zoneIndex, slotIndex)} variant={'primary'} className={'rounded-5 mt-4'} label={'Copy To All'} icon={<CopyDataIcon />} />
                                                                        <SharedButton onClick={() => addSlotHandler(zoneIndex)} variant={'primary'} className={'rounded-5 mt-4 ms-2'} label={'Add Slot'} icon={<AddLaneIcon />} />
                                                                    </Col>
                                                                    {/* <Col md={6}>
                                                                        {slotIndex > 0 &&
                                                                            < SharedButton onClick={() => deleteSlotHandler(zoneIndex, slotIndex)} variant={'danger'} className={'rounded-5 mt-4 me-3'} label={'Delete Slot'} icon={<Delete color={'white'} />} />
                                                                        }
                                                                        <SharedButton onClick={() => addSlotHandler(zoneIndex)} variant={'primary'} className={'rounded-5 mt-4'} label={'Add Slot'} icon={<AddLaneIcon />} />
                                                                    </Col> */}
                                                                </Row>
                                                                <Row className='mt-4 g-3'>
                                                                    {slot?.addonServiceIds.map((ser, serIndex) => (
                                                                        <Col md={3} key={serIndex}>
                                                                            <SvgIcon bg={'#D1DCFA'} className={'rounded-2 mb-2'}>
                                                                                <Checkbox
                                                                                    label={ser.serviceName}
                                                                                    onClick={(e) => checkHandler(e, zoneIndex, slotIndex, serIndex)}
                                                                                    checked={ser?.isChecked}
                                                                                    name={ser.serviceName}
                                                                                />
                                                                            </SvgIcon>
                                                                        </Col>
                                                                    ))}
                                                                </Row>
                                                            </div>
                                                        ))}
                                                    </VerticalLayout>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </WhitePanelBox>
                                </div>
                            ))}
                        </VerticalLayout>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
