import { Col, Container, Row, Form } from "react-bootstrap";
import { Loader } from "../../../components/Loader";
import { VerticalLayout } from "../../../components/VerticalLayout";
import { WhitePanelBox } from "../../../components/WhitePanelBox";
import { HorizontalLayout } from "../../../components/HorizontalLayout";
import { SharedButton } from "../../../components/SharedButton";
import { LeftArrow } from "../../../components/Icons/LeftArrow";
import { useEffect, useState } from "react";
import { Sidebar } from "../../../commonPages/sidebar";
import { InputFields } from "../../../components/InputFields";
import { errorAlert, successAlert } from "../../../components/Alert";
import { deleteCamera } from "../../../services/NetworkCall"
import { useLocation, useNavigate } from "react-router-dom";
import { updateCamera } from "../../../services/NetworkCall"
import { Delete } from "../../../components/Icons/Delete";
import Swal from "sweetalert2";
import Header from "../../../components/Header";

export const EditCamera = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [cameraData, setCameraData] = useState({ _id: "", cameraName: "", cameraUrl: "" });
    const [viewMode, setViewMode] = useState(false);

    // set the exCamera data
    useEffect(() => {
        if (location?.state?.data.pre) {
            setCameraData(location.state?.data.pre);
            if (location.state?.data?.mode === 'view') {
                setViewMode(true);
            } else setViewMode(false);
        }
    }, [location]);


    const inputHandler = (e) => {
        const { name, value } = e.target;
        setCameraData({ ...cameraData, [name]: value });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (!cameraData.cameraName && !cameraData.cameraUrl) {
            errorAlert({ message: "Nothing to update" })
        }

        const data = { cameraName: cameraData.cameraName, cameraUrl: cameraData.cameraUrl };

        setLoading(true);


        const res = await updateCamera({ id: cameraData._id, data: data });

        if (res.success) {
            successAlert({ message: res.message })
            setLoading(false);
            navigate("/camera-list");
        } else {
            errorAlert({ message: res.message });
        }
        setLoading(false);

    };

    const handleDeleteClick = (id) => {
        if (!id) {
            errorAlert("Something wrong");
            return;
        }
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: `Yes, delete it!`
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await deleteCamera({ id });
                if (res.success) {
                    setLoading(false);
                    Swal.fire({
                        title: "DELETE!",
                        text: `Request has been deleted.`,
                        icon: "success"
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            navigate('/camera-list')
                        }
                    });
                }
            }
        });
    };

    return (
        <>
            <Loader show={loading} />
            <Container fluid>
                <Row className="g-3">
                    <Col md={3}>
                        <Sidebar />
                    </Col>
                    <Col md={9}>
                        <VerticalLayout gap={3}>
                            <Header
                                heading={viewMode ? "Camera Detail" : 'Update Camera'}
                                subHeading={'Welcome ! Admin Truck'}
                                showNotificationIcon={true}
                            />
                            <HorizontalLayout justifyContent={"between"} alignItems={"center"}>
                                <SharedButton
                                    label={"Back"}
                                    size={"sm"}
                                    variant={"light"}
                                    className={"rounded-pill"}
                                    icon={<LeftArrow />}
                                    onClick={() => window.history.back()}
                                />
                                {!viewMode &&
                                    <SharedButton label={'Delete'} type={'button'} variant={'danger-light'} size={'md'} className={'rounded-5 text-black'} onClick={() => handleDeleteClick(cameraData._id)} icon={<Delete />} />
                                }
                            </HorizontalLayout>

                            <WhitePanelBox >
                                <Form onSubmit={handleFormSubmit} className="p-3">
                                    <Row className="g-3">
                                        <Col md={6} lg={6} sm={12}>
                                            <InputFields
                                                readOnly={viewMode}
                                                type={"text"}
                                                name={"cameraName"}
                                                label={"Camera Name"}
                                                id={"cameraName"}
                                                placeholder={"Enter Camera Name"}
                                                size={"md"}
                                                className="rounded-2 bg-white"
                                                onChange={inputHandler}
                                                value={cameraData.cameraName}
                                            />
                                        </Col>
                                        <Col md={6} lg={6} sm={12}>
                                            <InputFields
                                                readOnly={viewMode}
                                                type={"text"}
                                                name={"cameraUrl"}
                                                label={"Camera URL"}
                                                id={"cameraUrl"}
                                                placeholder={"Enter Camera URL"}
                                                size={"md"}
                                                className="rounded-2 bg-white"
                                                onChange={inputHandler}
                                                value={cameraData.cameraUrl}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-3 g-3">
                                        <Col md={12}>
                                            <HorizontalLayout justifyContent={"end"} className="mt-4">
                                                {!viewMode &&
                                                    <SharedButton
                                                        type="submit"
                                                        variant={"primary"}
                                                        className={"px-5"}
                                                        size={"md"}
                                                        label={"Update"}
                                                    />
                                                }
                                            </HorizontalLayout>
                                        </Col>
                                    </Row>
                                </Form>
                            </WhitePanelBox>
                        </VerticalLayout>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
