import { Col, Container, Row } from "react-bootstrap"
import { Sidebar } from "../../commonPages/sidebar"
import { WhitePanelBox } from "../../components/WhitePanelBox"
import { HorizontalLayout } from "../../components/HorizontalLayout"
import { VerticalLayout } from "../../components/VerticalLayout"
import { SlotIcon } from "../../components/Icons/SlotIcon"
import Typography from "../../components/Typography"
import { PaymentTransactionIcon } from "../../components/Icons/PaymentTransactionIcon"
import { ClientIcon } from "../../components/Icons/ClientIcon"
import { BookingIcon } from "../../components/Icons/BookingIcon"
import { LocationIcon } from "../../components/Icons/LocationIcon"
import { Checkbox } from "../../components/Checkbox"
import { DashboardCard } from "../../components/DashboardCard"
import ParkingSlot from "../../components/ParkingSlot"
import { useNavigate } from "react-router-dom"
import Header from "../../components/Header"
import { DashboardLocation } from "../../components/DashboardLocation"
import { useEffect, useState } from "react";
import { errorAlert } from "../../components/Alert"
import { getAnalyticsDataAPI, getLocationSlotsStatus } from "../../services/NetworkCall"
import { Loader } from "../../components/Loader"
import { DashboardLocationTable } from "./DashboardLocationTable"
import { SharedButton } from "../../components/SharedButton"
import { LeftArrow } from "../../components/Icons/LeftArrow"

export const Dashboard = () => {
  const navigate = useNavigate();
  const [analyticsData, setAnalyticsData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locationSlotDetails, setLocationSlotDetails] = useState([]);

  const createLocationNavigate = () => {
    navigate("/create-location");
  }


  const getAnalyticsData = async () => {
    setLoading(true);
    const res = await getAnalyticsDataAPI();
    if (res.success) {
      setAnalyticsData(res.data);
    } else {
      errorAlert({ message: res.message });
    }
    setLoading(false);
  }

  useEffect(() => {
    getAnalyticsData();
  }, []);


  const getLocationSLotDetail = async () => {
    setLoading(true)
    const res = await getLocationSlotsStatus(selectedLocation?._id, selectedLocation?.parqueryVisualPlanId)
    if (res.success) {
      setLocationSlotDetails(res.data);
      setLoading(false)
    } else {
      errorAlert({ message: res.message })
      setLoading(false)
    }
  }

  const handleLocationCardClick = (location) => {
    setSelectedLocation(location);
    setShowTable(true);
  };

  // Fetch slot details when `selectedLocation` updates
  useEffect(() => {
    if (selectedLocation) {
      getLocationSLotDetail();
    }
  }, [selectedLocation]);  // Run when `selectedLocation` changes


  return <>
    <Loader show={loading} />
    <Container fluid>
      <Row className="g-3">
        <Col md={3}>
          <Sidebar />
        </Col>
        <Col md={9}>
          <VerticalLayout gap={3}>
            <Header
              heading={"Dashboard"}
              subHeading={"Welcome ! Admin Truck"}
              showNotificationIcon={true}
              showButton={true}
              buttonLabel={"Create Location"}
              onButtonClick={createLocationNavigate}
            />

            {/* ----------Dashboard Card Start---------- */}
            <Row className="g-3">
              <Col sm={12} md={3} lg={3}>
                <DashboardCard
                  Icon={<SlotIcon />} Heading={'Available Parking'} SubHeading={analyticsData?.availableSpots} />
              </Col>
              <Col sm={12} md={3} lg={3}>
                <DashboardCard
                  Icon={<PaymentTransactionIcon />} Heading={'Earnings'} SubHeading={`$${analyticsData?.totalEarnings || 0}`} />
              </Col>
              <Col sm={12} md={3} lg={3}>
                <DashboardCard Icon={<ClientIcon />} Heading={'New Clients (1 Month)'} SubHeading={analyticsData?.newUsers} />
              </Col>
              <Col sm={12} md={3} lg={3}>
                <DashboardCard Icon={<BookingIcon />} Heading={'Bookings'} SubHeading={analyticsData?.totalBookings} />
              </Col>
            </Row>
            {/* ----------Dashboard Card End---------- */}

            {/* ----------Parking location card start---------- */}
            <VerticalLayout>
              <Typography className={'fw-medium'} color={'#000'}>Parking Locations</Typography>
              <Row className="g-3">
                <HorizontalLayout gap={3} overflow={'scroll'}>
                  {analyticsData && analyticsData.locations && analyticsData.locations.length != 0 && analyticsData.locations.map((location) =>
                    <Col sm={12} md={4} lg={4}>
                      <DashboardLocation
                        LocationName={location?.locationName}
                        LocationLink={location?.locationUrl}
                        locationImage={location?.locationImage}
                        LocationAddress={location?.locationAddress}
                        bookedSlots={`${location?.booked ?? 0}`}  // Shows "Booked 0" if undefined
                        // availableSlots={`Available ${location?.slots_detail?.available_spots ?? 0} Spots`}
                        occupiedSlots={`${location?.occupied ?? 0}`}
                        TotalSlots={`${location?.totalSlots ?? 0}`}
                        isActive={selectedLocation?._id === location?._id}
                        onClick={() => handleLocationCardClick(location)}
                      />
                    </Col>
                  )}
                </HorizontalLayout>
              </Row>
            </VerticalLayout>
            {/* -------------------------location table -------------------- */}
            {showTable && (
              <>
                <Typography className={'fw-medium mb-0'} color={'#000'}>Parking Slots</Typography>
                <DashboardLocationTable slotsData={locationSlotDetails} />
              </>
            )}
          </VerticalLayout>
        </Col>
      </Row>
    </Container>
  </>
}