import React from 'react'

export const LocationIcon = () => {
    return (
        <>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_732_4370)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M8.50033 0.666504C8.50033 0.390362 8.27647 0.166504 8.00033 0.166504C7.72418 0.166504 7.50033 0.390362 7.50033 0.666504V2.83343C6.58947 2.83568 5.99115 2.85549 5.48243 3.02079C4.31541 3.39997 3.40046 4.31493 3.02128 5.48194C2.85598 5.99066 2.83617 6.58898 2.83391 7.49984H0.666992C0.39085 7.49984 0.166992 7.72369 0.166992 7.99984C0.166992 8.27598 0.39085 8.49984 0.666992 8.49984H2.83391C2.83617 9.41069 2.85598 10.009 3.02128 10.5177C3.40046 11.6847 4.31541 12.5997 5.48243 12.9789C5.99115 13.1442 6.58947 13.164 7.50033 13.1662V15.3332C7.50033 15.6093 7.72418 15.8332 8.00033 15.8332C8.27647 15.8332 8.50033 15.6093 8.50033 15.3332V13.1662C9.41118 13.164 10.0095 13.1442 10.5182 12.9789C11.6852 12.5997 12.6002 11.6847 12.9794 10.5177C13.1447 10.009 13.1645 9.41069 13.1667 8.49984H15.3337C15.6098 8.49984 15.8337 8.27598 15.8337 7.99984C15.8337 7.72369 15.6098 7.49984 15.3337 7.49984H13.1667C13.1645 6.58898 13.1447 5.99066 12.9794 5.48194C12.6002 4.31493 11.6852 3.39997 10.5182 3.02079C10.0095 2.85549 9.41118 2.83568 8.50033 2.83343V0.666504ZM5.79144 3.97184C6.19925 3.83934 6.72055 3.83317 8.00033 3.83317C9.2801 3.83317 9.8014 3.83934 10.2092 3.97184C11.0718 4.25211 11.7481 4.92838 12.0283 5.79096C12.1608 6.19876 12.167 6.72007 12.167 7.99984C12.167 9.27961 12.1608 9.80091 12.0283 10.2087C11.7481 11.0713 11.0718 11.7476 10.2092 12.0278C9.8014 12.1603 9.2801 12.1665 8.00033 12.1665C6.72055 12.1665 6.19925 12.1603 5.79144 12.0278C4.92887 11.7476 4.2526 11.0713 3.97233 10.2087C3.83983 9.80091 3.83366 9.27961 3.83366 7.99984C3.83366 6.72007 3.83983 6.19876 3.97233 5.79096C4.2526 4.92838 4.92887 4.25211 5.79144 3.97184Z" fill="#4E78EC" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.33366 7.99984C6.33366 8.92031 7.07985 9.6665 8.00033 9.6665C8.9208 9.6665 9.66699 8.92031 9.66699 7.99984C9.66699 7.07936 8.9208 6.33317 8.00033 6.33317C7.07985 6.33317 6.33366 7.07936 6.33366 7.99984ZM7.33366 7.99984C7.33366 8.36803 7.63214 8.6665 8.00033 8.6665C8.36851 8.6665 8.66699 8.36803 8.66699 7.99984C8.66699 7.63165 8.36851 7.33317 8.00033 7.33317C7.63214 7.33317 7.33366 7.63165 7.33366 7.99984Z" fill="#4E78EC" />
                </g>
                <defs>
                    <clipPath id="clip0_732_4370">
                        <rect width="16" height="16" fill="white" />
                    </clipPath>
                </defs>
            </svg>

        </>
    )
}
