import React from 'react'

export const SvgIcon = ({ children, className, bg, size }) => {

const styles = {
    padding: '0.3rem 0.5rem',
    borderRadius: '1rem',
    background: bg,
    fontSize: size,
    cursor : 'pointer',
}

    return (
        <>
            <div className={className}  style={styles}>
                {children}
            </div>
        </>
    )
}
