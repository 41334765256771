import React from 'react';
import { Table } from 'react-bootstrap';
import { WhitePanelBox } from '../../components/WhitePanelBox';


export const DashboardLocationTable = ({ slotsData }) => {

    return (
        <>
            <WhitePanelBox>
                <Table hover>
                    <thead>
                        <tr>
                            <th>S No.</th>
                            <th>SLOT NO.</th>
                            <th>SYSTEM STATUS</th>
                            <th>PARQUERY STATUS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {slotsData && slotsData.length != 0 && slotsData.map((slot, index) => (
                            <tr key={index}>
                                <td> {index + 1}</td>
                                <td> {slot?.slotNumber}</td>
                                <td>{slot?.isBooked ? "BOOKED" : "AVAILABLE"}</td>
                                <td>
                                    {slot?.isAvailableInParquery === true
                                        ? "AVAILABLE"
                                        : slot?.isAvailableInParquery === false
                                            ? "OCCUPIED"
                                            : "UNKNOWN"}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </WhitePanelBox>


        </>
    );
};
