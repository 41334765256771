import React from 'react'
import { SharedButton } from './SharedButton'
import { WhitePanelBox } from './WhitePanelBox'
import { HorizontalLayout } from './HorizontalLayout'
import { Badge, Image } from 'react-bootstrap'
import { VerticalLayout } from './VerticalLayout'
import Typography from './Typography'
import { SvgIcon } from './SvgIcon'

export const CarDetailCard = ({ className, vehicleName, Model, NoPlate, State, vehicleImage }) => {
    return (
        <>
            <WhitePanelBox className={className}>
                <HorizontalLayout gap={3} overflow={'auto scrollbar-w-none'}>
                    <Image src={vehicleImage || '../../assets/images/Image.png'} alt='' width={80} height={70} className='border-dashed-2 rounded-3' />
                    <VerticalLayout justifyContent={'center'} alignItems={'start'}>
                        <Typography variant={'h4'} className={'mb-0'} color={'#272833'}>{vehicleName}</Typography>
                        <Typography variant={'p'} color={'#999999'} className={'mb-0'}>{Model}</Typography>
                        <Typography variant={'p'} color={'#191D23'} className={'mb-0'}>{NoPlate}</Typography>
                    </VerticalLayout>
                    {/* <SharedButton className={'rounded-5'} label={State} variant={'light'} disabled /> */}
                    {/* <Badge pill bg={'light'} className='p-2' >                        <Typography variant={'p'} size={'.9rem'} color={'#191D23'} className={'fw-normal mb-0'}>{State}</Typography></Badge> */}
                        <SvgIcon bg={'#E6E6E6'} className={'px-3 py-2 rounded-5'}> <Typography variant={'p'} size={'.9rem'} color={'#191D23'} className={'fw-normal mb-0'}>{State}</Typography></SvgIcon>
                </HorizontalLayout>
            </WhitePanelBox>
        </>
    )
}
