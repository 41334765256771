import React from 'react';
import { Image, Table } from 'react-bootstrap';
import { WhitePanelBox } from '../../../components/WhitePanelBox';
import { IconTooltip } from '../../../components/IconTooltip';
import { TablePagination } from '../../../components/TablePagination';
import { HorizontalLayout } from '../../../components/HorizontalLayout';

export const LocationListTable = ({ pageHandler, pagination, locationData }) => {
    return (
        <>
            <WhitePanelBox>
                <Table hover responsive>
                    <thead>
                        <tr>
                            <th>LOCATION NAME</th>
                            <th>ID</th>
                            <th>NO OF ZONE</th>
                            <th>TOTAL SLOTS</th>
                            <th>ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {locationData && locationData.map((location, index) => (
                            <tr key={index}>
                                <td>
                                   <HorizontalLayout gap={2}><Image src={location?.locationImage || '../../assets/images/BydeFaultImg.png'} alt='image'  rounded className='card-image' />  {location.locationName}</HorizontalLayout> 
                                </td>
                                <td>{location?._id}</td>
                                <td>{location?.numberOfZones}</td>
                                <td>{location?.totalSlots}</td>
                                <td><IconTooltip editPath='/edit-location' editData={location} viewPath='/location-detail' viewData={location} /></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </WhitePanelBox>
            <HorizontalLayout justifyContent={'end'} alignItems={'center'}>
                <TablePagination pagination={pagination} pageHandler={pageHandler} />
            </HorizontalLayout>

        </>
    );
};
