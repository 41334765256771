import React from 'react';
import { Image, Table } from 'react-bootstrap';
import { WhitePanelBox } from '../../../components/WhitePanelBox';
import { IconTooltip } from '../../../components/IconTooltip';
import { TablePagination } from '../../../components/TablePagination';
import { HorizontalLayout } from '../../../components/HorizontalLayout';
import { SvgIcon } from '../../../components/SvgIcon';

export const LocationLaneTableList = ({ pageHandler, pagination, zoneList }) => {
    // Define a sample data array for locations


    return (
        <>
            <WhitePanelBox>
                <Table hover>
                    <thead>
                        <tr>
                            <th>ZONE NAME</th>
                            <th>TOTAL SLOTS</th>
                            <th>SERVICES</th>
                            <th>ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {zoneList && zoneList.map((e, index) => (
                            <tr key={index}>
                                <td> {e.zoneName}</td>
                                <td>{e.totalSlots}</td>
                                <td>{e?.addOnServices?.map((e) => (
                                    <span className='me-1'> {e.name},</span>

                                ))} </td>
                                <td>
                                    <IconTooltip
                                        viewPath='/edit-lane' viewData={{ pre: e, "mode": 'view' }}
                                        editPath='/edit-lane' editData={{ pre: e, "mode": 'edit' }}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </WhitePanelBox>
            <HorizontalLayout justifyContent={'end'} alignItems={'center'}>
                <TablePagination pagination={pagination} pageHandler={pageHandler} />
            </HorizontalLayout>

        </>
    );
};
