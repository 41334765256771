import React from 'react'
import { WhitePanelBox } from '../../components/WhitePanelBox'
import { Image, Table } from 'react-bootstrap'
import { SharedButton } from '../../components/SharedButton'
import { HorizontalLayout } from '../../components/HorizontalLayout'
import { TablePagination } from '../../components/TablePagination'
import { ViewIcon } from '../../components/Icons/ViewIcon'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

export const TransactionTable = ({ bookingsData, pagination, pageHandler }) => {

    const navigate = useNavigate();

    const handleViewClick = (booking) => {
        navigate("/transaction-detail", { state: { data: { bookingId: booking?._id } } })
    }

    return (
        <>
            <WhitePanelBox>
                <Table hover responsive>
                    <thead>
                        <tr color='#64748B'>
                            <th>BOOKING ID</th>
                            <th>LOCATION NAME</th>
                            <th>BOOKING DATE</th>
                            <th>DURATION</th>
                            <th>LICENSE PLATE</th>
                            <th>PRICE</th>
                            <th>ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bookingsData && bookingsData.map((booking, index) => (
                            <tr key={index}>
                                <td>{booking?._id}</td>
                                <td>{booking?.location_info?.locationName}</td>
                                <td>{booking?.createdAt && moment(booking?.createdAt).local().format("DD-MM-YYYY")}</td>
                                <td>{booking?.bookingType}</td>
                                <td>{booking?.vehicle_info?.licensePlate}</td>
                                <td>{booking?.totalAmount}</td>
                                <td>
                                    <SharedButton
                                        icon={<ViewIcon />}
                                        iconPosition='left'
                                        label={'View'}
                                        variant={'light'}
                                        className={'bg-white'}
                                        onClick={() => handleViewClick(booking)}
                                    />
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </Table>
            </WhitePanelBox>
            <HorizontalLayout justifyContent={'end'} alignItems={'center'}>
                <TablePagination
                    pagination={pagination}
                    pageHandler={pageHandler}
                />
            </HorizontalLayout>
        </>
    )
}
