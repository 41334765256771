import React from 'react'

export const PaymentTransactionIcon = () => {
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M12.75 5C12.75 4.58579 12.4142 4.25 12 4.25C11.5858 4.25 11.25 4.58579 11.25 5V5.30807C10.9232 5.39005 10.6026 5.51813 10.2973 5.69439C7.57541 7.26586 7.92137 11.2989 10.8711 12.3839L11.25 12.5233L11.25 17.2195C10.7151 17.0686 10.2372 16.717 9.93799 16.1988L9.56243 15.5483C9.35532 15.1895 8.89663 15.0666 8.53791 15.2737C8.17919 15.4808 8.05628 15.9395 8.26339 16.2983L8.63895 16.9488C9.21742 17.9507 10.1927 18.5796 11.25 18.7535L11.25 19C11.25 19.4142 11.5858 19.75 12 19.75C12.4142 19.75 12.75 19.4142 12.75 19L12.75 18.692C13.0768 18.61 13.3975 18.4819 13.7028 18.3056C16.4247 16.7341 16.0787 12.7011 13.129 11.6161L12.75 11.4767L12.75 6.78053C13.2849 6.93138 13.7629 7.28298 14.0621 7.80125L14.4377 8.45175C14.6448 8.81047 15.1035 8.93337 15.4622 8.72626C15.8209 8.51916 15.9438 8.06047 15.7367 7.70175L15.3611 7.05125C14.7827 6.04928 13.8074 5.42033 12.75 5.2465V5ZM11.0473 6.99343C11.1139 6.95499 11.1815 6.92044 11.25 6.88969L11.25 10.92C9.62296 10.2015 9.47054 7.90376 11.0473 6.99343ZM12.75 17.1103L12.75 13.08C14.3771 13.7984 14.5296 16.0962 12.9528 17.0066C12.8862 17.045 12.8185 17.0796 12.75 17.1103Z" fill="black" />
                <path fillRule="evenodd" clipRule="evenodd" d="M16.4635 2.37373C15.3214 2.24999 13.8818 2.24999 12.0452 2.25H11.9548C10.1182 2.24999 8.67861 2.24999 7.53648 2.37373C6.37094 2.50001 5.42656 2.76232 4.62024 3.34815C4.13209 3.70281 3.70281 4.13209 3.34815 4.62024C2.76232 5.42656 2.50001 6.37094 2.37373 7.53648C2.24999 8.67861 2.24999 10.1182 2.25 11.9548V12.0452C2.24999 13.8818 2.24999 15.3214 2.37373 16.4635C2.50001 17.6291 2.76232 18.5734 3.34815 19.3798C3.70281 19.8679 4.13209 20.2972 4.62024 20.6518C5.42656 21.2377 6.37094 21.5 7.53648 21.6263C8.67859 21.75 10.1182 21.75 11.9547 21.75H12.0453C13.8818 21.75 15.3214 21.75 16.4635 21.6263C17.6291 21.5 18.5734 21.2377 19.3798 20.6518C19.8679 20.2972 20.2972 19.8679 20.6518 19.3798C21.2377 18.5734 21.5 17.6291 21.6263 16.4635C21.75 15.3214 21.75 13.8818 21.75 12.0453V11.9547C21.75 10.1182 21.75 8.67859 21.6263 7.53648C21.5 6.37094 21.2377 5.42656 20.6518 4.62024C20.2972 4.13209 19.8679 3.70281 19.3798 3.34815C18.5734 2.76232 17.6291 2.50001 16.4635 2.37373ZM5.50191 4.56168C6.00992 4.19259 6.66013 3.97745 7.69804 3.865C8.74999 3.75103 10.1084 3.75 12 3.75C13.8916 3.75 15.25 3.75103 16.302 3.865C17.3399 3.97745 17.9901 4.19259 18.4981 4.56168C18.8589 4.82382 19.1762 5.14111 19.4383 5.50191C19.8074 6.00992 20.0225 6.66013 20.135 7.69804C20.249 8.74999 20.25 10.1084 20.25 12C20.25 13.8916 20.249 15.25 20.135 16.302C20.0225 17.3399 19.8074 17.9901 19.4383 18.4981C19.1762 18.8589 18.8589 19.1762 18.4981 19.4383C17.9901 19.8074 17.3399 20.0225 16.302 20.135C15.25 20.249 13.8916 20.25 12 20.25C10.1084 20.25 8.74999 20.249 7.69804 20.135C6.66013 20.0225 6.00992 19.8074 5.50191 19.4383C5.14111 19.1762 4.82382 18.8589 4.56168 18.4981C4.19259 17.9901 3.97745 17.3399 3.865 16.302C3.75103 15.25 3.75 13.8916 3.75 12C3.75 10.1084 3.75103 8.74999 3.865 7.69804C3.97745 6.66013 4.19259 6.00992 4.56168 5.50191C4.82382 5.14111 5.14111 4.82382 5.50191 4.56168Z" fill="black" />
            </svg>
        </>
    )
}
