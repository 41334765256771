import React from 'react'
import { WhitePanelBox } from '../../../components/WhitePanelBox';
import { Table } from 'react-bootstrap';
import { TablePagination } from '../../../components/TablePagination';
import { HorizontalLayout } from '../../../components/HorizontalLayout';
import { useNavigate } from 'react-router-dom';
import { IconTooltip } from '../../../components/IconTooltip';
import { SharedButton } from '../../../components/SharedButton';

export const CameraListTable = ({ cameraData, pagination, pageHandler, handleDeleteClick }) => {
    const navigate = useNavigate();

    const handleEditClick = (cameraData) => {
        navigate("/edit-camera", { state: { data: cameraData } })
    }

    return (
        <>
            <WhitePanelBox>
                <Table hover responsive>
                    <thead>
                        <tr>
                            <th>CAMERA NAME</th>
                            <th>CAMERA URL</th>
                            <th>ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cameraData && cameraData.map((camera, index) => (
                            <tr key={index}>
                                <td> {camera?.cameraName}</td>
                                <td>{camera?.cameraUrl}</td>
                                <td><IconTooltip
                                    viewPath='/edit-camera'
                                    viewData={{ pre: camera, "mode": 'view' }}
                                    editPath={'/edit-camera'}
                                    editData={{ pre: camera, "mode": 'edit' }}
                                />
                                    {/* <SharedButton label={"Delete"} onClick={() => handleDeleteClick(camera?._id)} />
                                    <SharedButton label={"Edit"} onClick={() => handleEditClick(camera)} /> */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </WhitePanelBox>
            <HorizontalLayout justifyContent={'end'} alignItems={'center'}>
                <TablePagination pagination={pagination} pageHandler={pageHandler} />
            </HorizontalLayout>

        </>
    );
};
