import React, { useContext, useState } from "react";
import { Col, Container, Form, Image, Row } from "react-bootstrap";
import { Heading } from "../../components/Heading";
import { InputFields } from "../../components/InputFields";
import { Checkbox } from "../../components/Checkbox";
import { SharedButton } from "../../components/SharedButton";
import { Link, useNavigate } from "react-router-dom";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { VerticalLayout } from "../../components/VerticalLayout";
import styled from "styled-components";
import { Loader } from "../../components/Loader";
import { AuthContext } from "../../states/AuthContext";
import { emailPattern } from '../../helper/Helper'
import { errorAlert, successAlert } from "../../components/Alert";
import { login } from "../../services/NetworkCall"

const WhiteBox = styled.div`
  background: #fff;
  padding: 2.5rem;
`;

export const Login = () => {
  const { setLoggedIn, setProfileData } = useContext(AuthContext);
  const [indata, setIndata] = useState({ "email": localStorage.getItem("myemail"), "password": localStorage.getItem("mypassword"), "reminder": localStorage.getItem("myreminder") === "true" });
  const [error, setError] = useState({ "email": "", "password": "" });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State to handle password visibility
  const navigate = useNavigate();


  const inputHandler = (e) => {
    const { name, value } = e.target;
    setIndata((pre) => ({ ...pre, [name]: value }));
    setError((pre) => ({ ...pre, [name]: "" }));
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }

  const checkHandler = (e) => {
    const { name, checked } = e.target;
    setIndata((pre) => ({ ...pre, [name]: checked }));
  }

  const submitHandler = async (e) => {

    e.preventDefault();

    if (indata.reminder) {
      localStorage.setItem("myreminder", true);
      localStorage.setItem("myemail", indata.email);
      localStorage.setItem("mypassword", indata.password);
    } else {
      localStorage.removeItem("myreminder");
      localStorage.removeItem("myemail");
      localStorage.removeItem("mypassword");
    }


    let isvalid = 1;

    if (!indata.email) {
      setError((prev) => ({ ...prev, email: "Email is required *" }));
      isvalid = 2;
    } else if (!emailPattern.test(indata.email)) {
      setError((prev) => ({ ...prev, email: "Invalid email format *" }));
      isvalid = 3;
    }

    if (!indata.password) {
      setError((pre) => ({ ...pre, 'password': "Password is Required *" }));
      isvalid = 4;
    }

    if (isvalid === 1) {
      setLoading(true);
      const data = {
        "email": indata.email,
        "password": indata.password
      }

      const res = await login(data);

      if (res.success) {
        setLoggedIn(true);
        setProfileData(res.data);
        localStorage.setItem("loggedIn", "true");
        localStorage.setItem("authToken", `${res.data.token}`);
        localStorage.setItem("profileData", JSON.stringify(res.data));
        successAlert({ message: res.message })
        navigate("/dashboard", { replace: true });
      } else {
        errorAlert({ message: res.message });
      }
      setLoading(false);
    }
  }

  return (
    <>
      <Loader show={loading} />
      <Container fluid className="m-0">
        <Row className="justify-content-center align-items-center">
          <Col sm={12} md={5} lg={5}>
            <VerticalLayout gap={3} justifyContent={"start"} alignItems={"center"}>
              <WhiteBox className="w-75 rounded-3">
                <Form onSubmit={submitHandler}>
                  <VerticalLayout gap={2} justifyContent={"start"} alignItems={"center"}>
                    <Image src="/assets/images/logo.png" fluid />
                    <Heading
                      Heading={"Login"}
                      SubHeading={"Welcome back. Enter your credentials to access your account"}
                    />
                  </VerticalLayout>
                  <VerticalLayout gap={3} justifyContent={"center"} alignItems={"center"}>
                    <InputFields
                      type={"email"}
                      name={"email"}
                      label={"Email Address"}
                      id={"EmailAddress"}
                      placeholder={"Enter email address"}
                      size={"md"}
                      className="rounded-2 bg-white"
                      onChange={inputHandler}
                      feedback={error.email}
                      isvalid={!!error.email}
                      value={indata.email}
                    />
                    <InputFields
                      type={showPassword ? "text" : "password"}
                      name={"password"}
                      label={"Password"}
                      id={"PasswordInput"}
                      className="bg-white rounded-2"
                      size={"md"}
                      placeholder={"Password"}
                      onChange={inputHandler}
                      feedback={error.password}
                      isvalid={!!error.password}
                      value={indata.password}
                      endIcon={
                        showPassword ? (
                          <FiEyeOff
                            className="text-secondary cursor-pointer"
                            onClick={togglePasswordVisibility}
                          />
                        ) : (
                          <FiEye
                            className="text-secondary cursor-pointer"
                            onClick={togglePasswordVisibility}
                          />
                        )
                      }
                    />


                    <div className="text-left w-100">
                      <Checkbox
                        type={"CheckBox"}
                        checked={indata.reminder}
                        name={"reminder"}
                        label={"Keep me signed in"}
                        id={'custom-check'}
                        onClick={checkHandler}
                      />
                    </div>

                    <SharedButton
                      type={"submit"}
                      size={"md"}
                      variant={"primary"}
                      className={"btn-block w-100"}
                      label={"Login"}
                    />
                    <p className="text-center">
                      <Link
                        to={"/forgot-password"}
                        className="text-decoration-none fw-semibold text-primary"
                      >
                        Forgot Password ?
                      </Link>
                    </p>
                  </VerticalLayout>
                </Form>
              </WhiteBox>
            </VerticalLayout>
          </Col>

          <Col sm={12} md={7} lg={7}>
            <Image
              src="/assets/images/TruckTruck.jpg"
              fluid
              className="w-100 vh-100 object-fit-cover"
              alt="image"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};
