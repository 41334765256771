import React from 'react'

export const CreateProductIcon = () => {
    return (
        <>
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1418_5480)">
                    <path d="M10.5 8C10.441 8 10.383 7.989 10.327 7.969L0.827 4.46899C0.63 4.39599 0.5 4.20999 0.5 3.99999C0.5 3.78999 0.63 3.60399 0.827 3.53099L10.327 0.0309941C10.438 -0.0100059 10.561 -0.0100059 10.673 0.0309941L20.173 3.53099C20.37 3.60399 20.5 3.78999 20.5 3.99999C20.5 4.20999 20.37 4.39599 20.173 4.46899L10.673 7.969C10.617 7.989 10.559 8 10.5 8ZM2.446 3.99999L10.5 6.967L18.554 3.99999L10.5 1.03299L2.446 3.99999Z" fill="black" />
                    <path d="M9.12 19.49C9.062 19.49 9.004 19.48 8.947 19.459L0.827 16.469C0.631 16.396 0.5 16.21 0.5 16V4C0.5 3.724 0.724 3.5 1 3.5C1.276 3.5 1.5 3.724 1.5 4V15.651L9.293 18.521C9.552 18.616 9.685 18.904 9.589 19.163C9.515 19.365 9.324 19.49 9.12 19.49Z" fill="black" />
                    <path d="M20 9C19.724 9 19.5 8.776 19.5 8.5V4C19.5 3.724 19.724 3.5 20 3.5C20.276 3.5 20.5 3.724 20.5 4V8.5C20.5 8.776 20.276 9 20 9Z" fill="black" />
                    <path d="M10.5 13.03C10.224 13.03 10 12.806 10 12.53V7.5C10 7.224 10.224 7 10.5 7C10.776 7 11 7.224 11 7.5V12.53C11 12.807 10.776 13.03 10.5 13.03Z" fill="black" />
                    <path d="M18 24C14.416 24 11.5 21.084 11.5 17.5C11.5 13.916 14.416 11 18 11C21.584 11 24.5 13.916 24.5 17.5C24.5 21.084 21.584 24 18 24ZM18 12C14.967 12 12.5 14.468 12.5 17.5C12.5 20.532 14.967 23 18 23C21.033 23 23.5 20.532 23.5 17.5C23.5 14.468 21.033 12 18 12Z" fill="black" />
                    <path d="M18 21C17.724 21 17.5 20.776 17.5 20.5V14.5C17.5 14.224 17.724 14 18 14C18.276 14 18.5 14.224 18.5 14.5V20.5C18.5 20.776 18.276 21 18 21Z" fill="black" />
                    <path d="M21 18H15C14.724 18 14.5 17.776 14.5 17.5C14.5 17.224 14.724 17 15 17H21C21.276 17 21.5 17.224 21.5 17.5C21.5 17.776 21.276 18 21 18Z" fill="black" />
                </g>
                <defs>
                    <clipPath id="clip0_1418_5480">
                        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}
