// import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth";
// import { getFirestore } from "firebase/firestore";

// const firebaseConfig = {
//     apiKey: "AIzaSyCUbJtkZUOSIoVrZT5ejo1dku3ekjW7oXo",
//     authDomain: "truck-tuck.firebaseapp.com",
//     projectId: "truck-tuck",
//     storageBucket: "truck-tuck.firebasestorage.app",
//     messagingSenderId: "1090877167795",
//     appId: "1:1090877167795:web:38b12ecf8e00c04dec38c6",
//     measurementId: "G-79RK7GE8G5"
// };

// export const app = initializeApp(firebaseConfig);
// export const auth = getAuth();
// export const db = getFirestore();

//---------------------------------------------------------testing account----------------------------------------------------
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDLM41eb7FFlOkThNWR6rcYXZgD_j-TLjM",
    authDomain: "truckt-app.firebaseapp.com",
    projectId: "truckt-app",
    storageBucket: "truckt-app.firebasestorage.app",
    messagingSenderId: "385250921690",
    appId: "1:385250921690:web:4017cf3fd88bc25e7669e3",
    measurementId: "G-P4SRBLBE32"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore();