import React from 'react'

export const LeftArrow = () => {
    return (
        <>

            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M7.32212 6.95632C9.13043 5.56428 11.7496 6.85338 11.7496 9.13544V11.2501L21.9996 11.2501C22.4138 11.2501 22.7496 11.5859 22.7496 12.0001C22.7496 12.4143 22.4138 12.7501 21.9996 12.7501L11.7496 12.7501V14.8647C11.7496 17.1468 9.13043 18.4359 7.32212 17.0438L3.60084 14.1792C2.17081 13.0784 2.17081 10.9218 3.60084 9.82096L7.32212 6.95632ZM8.23711 8.14493C9.05907 7.51218 10.2496 8.09814 10.2496 9.13544L10.2496 12.0001L10.2496 14.8647C10.2496 15.902 9.05907 16.488 8.23711 15.8552L4.51583 12.9906C3.86582 12.4902 3.86582 11.5099 4.51583 11.0096L8.23711 8.14493Z" fill="#2D264B" />
            </svg>

        </>
    )
}
