import React from 'react'
import { WhitePanelBox } from '../../components/WhitePanelBox'
import { Image, Table } from 'react-bootstrap'
import { SharedButton } from '../../components/SharedButton'
import { HorizontalLayout } from '../../components/HorizontalLayout'
import { TablePagination } from '../../components/TablePagination'
import { useNavigate } from 'react-router-dom'

export const VehicleRegistrationTable = ({ pagination, pageHandler, vehiclesData }) => {
    return (
        <>
            <WhitePanelBox>
                <Table hover responsive>
                    <thead>
                        <tr>
                            <th>Vehicle Name</th>
                            <th>LICENSE PLATE</th>
                            <th>STATE</th>
                            <th>LENGTH</th>
                            <th>WIDTH</th>
                        </tr>
                    </thead>
                    <tbody>
                        {vehiclesData && vehiclesData.map((vehicle, index) => (
                            <tr key={index}>
                                <td><HorizontalLayout gap={2}><Image src={vehicle?.vehicleImage || '../../assets/images/Image.png'} alt=''  className='bg-white card-image rounded' /> {vehicle?.vehicleName}</HorizontalLayout> </td>
                                <td>{vehicle?.licensePlate}</td>
                                <td>{vehicle?.state}</td>
                                <td>{vehicle?.length}</td>
                                <td>{vehicle?.width}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </WhitePanelBox>
            <HorizontalLayout justifyContent={'end'} alignItems={'center'}>
                <TablePagination pagination={pagination} pageHandler={pageHandler} />
            </HorizontalLayout>
        </>
    )
}
