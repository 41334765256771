import React from 'react'
import { WhitePanelBox } from '../../components/WhitePanelBox'
import { Image, Table } from 'react-bootstrap'
import { SharedButton } from '../../components/SharedButton'
import { HorizontalLayout } from '../../components/HorizontalLayout'
import { TablePagination } from '../../components/TablePagination'
import { ViewIcon } from '../../components/Icons/ViewIcon'
import { useNavigate } from 'react-router-dom'

export const SecurityQRTable = ({ pagination, pageHandler, pinData }) => {

    const navigate = useNavigate();

    // const handleViewClick = (user) => {
    //     navigate("/security-detail", { state: { data: user } })
    // }


    return (
        <>
            <WhitePanelBox>
                <Table hover responsive>
                    <thead>
                        <tr>
                            <th>BOOKING ID</th>
                            <th>USER NAME</th>
                            <th>PIN CODE</th>
                            <th>LICENSE PLATE</th>
                            {/* <th>ACTION</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {pinData && pinData.map((data, index) => (
                            <tr key={index}>
                                <td>{data?._id}</td>
                                <td>{data?.user?.userName}</td>
                                <td>{data?.uniFiAccessPin}</td>
                                <td>{data?.vehicle?.licensePlate}</td>
                                {/* <td><SharedButton icon={<ViewIcon />} iconPosition='left' label={'View'} variant={'light'} className={'bg-white'} onClick={() => handleViewClick(user)} /></td> */}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </WhitePanelBox>
            <HorizontalLayout justifyContent={'end'} alignItems={'center'}>
                <TablePagination pagination={pagination} pageHandler={pageHandler} />
            </HorizontalLayout>
        </>
    )
}
