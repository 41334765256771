import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Accordion, Image } from 'react-bootstrap'
import { Loader } from '../../../components/Loader'
import { Sidebar } from '../../../commonPages/sidebar'
import { VerticalLayout } from '../../../components/VerticalLayout'
import { WhitePanelBox } from '../../../components/WhitePanelBox'
import { HorizontalLayout } from '../../../components/HorizontalLayout'
import { Heading } from '../../../components/Heading'
import { SvgIcon } from '../../../components/SvgIcon'
import { SearchIcon } from '../../../components/Icons/SearchIcon'
import { Notification } from '../../../components/Icons/Notification'
import { Delete } from '../../../components/Icons/Delete'
import Typography from '../../../components/Typography'
import { SharedButton } from '../../../components/SharedButton'
import { LeftArrow } from '../../../components/Icons/LeftArrow'
import { LocationIcon } from '../../../components/Icons/LocationIcon'
import { AddLaneIcon } from '../../../components/Icons/AddLaneIcon'
import { useLocation, useNavigate } from 'react-router-dom'
import { Checkbox } from '../../../components/Checkbox'
import { NewInputField } from '../../../components/NewInputField'
import { UploadFile } from '../../../components/UploadFile'
import Select from '../../../components/Select'
import { createParkingSlotsAPI, createParkingZonesAPI, getAddonServicesAPI, getCameras } from '../../../services/NetworkCall'
import { updateCheckedServices, validateSlotData, validateZoneData } from '../../../helper/Helper'
import { errorAlert, successAlert } from '../../../components/Alert'

export const CreateSlot = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const [inLocation, setInLocation] = useState({ "id": "", "name": "" });
    const [serviceData, setServiceData] = useState([]);
    const [optionCamera, setOptionCamera] = useState([]);


    useEffect(() => {
        if (location?.state?.data) {
            const data = location?.state?.data;
            let pre = { ...inLocation };
            pre.id = data?.id;
            pre.name = data?.name;
            setInLocation(pre);
        }
    }, [location])

    const [zoneData, setZoneData] = useState([{
        "zoneName": "",
        "slotCount": 1,
        "slots": [
            {
                "slotNumber": "",
                "slotLength": "",
                "slotWidth": "",
                "cameraId": "",
                "addonServiceIds": [],
            }
        ]
    }])

    const getCamerasAPI = async () => {
        const res = await getCameras();
        if (res.success) {
            const data = res.data;
            let pre = [...data.map((e) => ({
                'name': e?.cameraName,
                'value': e?._id,
            }))];
            setOptionCamera(pre);
        }
    }

    const getServiceList = async () => {
        try {
            setLoading(true);
            const res = await getAddonServicesAPI();
            if (res.success) {
                const data = res.data;
                let pre = [{
                    'id': '',
                    'serviceName': "Select All",
                    "isChecked": false
                }, ...data.map((e) => ({
                    'id': e?._id,
                    'serviceName': e?.name,
                    "isChecked": false
                }))];
                setServiceData(pre);
                // Clone zoneData to avoid mutating state directly
                let preZoneData = [...zoneData];

                // Check if preZoneData[0], preZoneData[0].slot, and preZoneData[0].slot[0] exist
                if (preZoneData[0]?.slots?.[0]) {
                    preZoneData[0].slots[0].addonServiceIds = pre;
                    setZoneData(preZoneData);
                } else {
                    console.error("Zone data structure is not as expected.");
                }
            }
        } catch (error) {
            console.error("Error fetching addonServiceIds:", error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => { getServiceList(); getCamerasAPI(); }, []);

    const addSlotHandler = (zoneIndex) => {
        const pre = [...zoneData];
        pre[zoneIndex].slots.push(
            {
                "slotNumber": "",
                "slotLength": "",
                "slotWidth": "",
                "cameraId": "",
                "addonServiceIds": serviceData,
            });
        setZoneData(pre);
    }

    const deleteSlotHandler = (zoneIndex, slotIndex) => {
        if (slotIndex > 0) {
            const pre = [...zoneData];
            pre[zoneIndex].slots.splice(slotIndex, 1);
            setZoneData(pre);
        }
    }





    const slotChangeHandler = (e, zoneIndex, slotIndex) => {
        const { name, value } = e.target;
        const pre = [...zoneData];
        pre[zoneIndex].slots[slotIndex][name] = value;
        setZoneData(pre);
    }

    const checkHandler = (e, zoneIndex, slotIndex, serviceIndex) => {
        const { name, checked } = e.target;
        let pre = [...zoneData];
        let slots = { ...pre[zoneIndex].slots[slotIndex] };
        let addonServiceIds = [...slots.addonServiceIds];
        if (name === "Select All") {
            addonServiceIds = addonServiceIds.map((addonServiceIds) => ({
                ...addonServiceIds,
                isChecked: checked,
            }));
        } else {
            addonServiceIds[serviceIndex] = {
                ...addonServiceIds[serviceIndex],
                isChecked: checked,
            };
            const allChecked = addonServiceIds
                .slice(1)
                .every((addonServiceIds) => addonServiceIds.isChecked);
            addonServiceIds[0].isChecked = allChecked;
        }
        slots.addonServiceIds = addonServiceIds;
        pre[zoneIndex].slots[slotIndex] = slots;
        setZoneData(pre);
    };



    const submitHandler = async () => {
        const { id } = inLocation
        let isValid = true;
        const updatedData = updateCheckedServices(zoneData);
        isValid = validateSlotData(updatedData);
        const slotData = updatedData[0].slots;

        if (isValid) {
            setLoading(true);
            const data = {
                "parkingZoneId": id,
                "slots": slotData,
            }
            try {
                const res = await createParkingSlotsAPI(data);
                setLoading(false);
                if (res.success) {
                    successAlert({ message: res.message });
                    navigate('/location-list');
                } else {
                    errorAlert({ message: res.message });
                }
            } catch (error) {
                setLoading(false);
                errorAlert({ message: "An error occurred while creating the location." });
                console.error(error);
            }

        }

    }

    return (
        <>
            <Loader show={loading} />
            <Container fluid>
                <Row className="m-0">
                    <Col md={3}>
                        <Sidebar />
                    </Col>
                    <Col md={9}>
                        <VerticalLayout gap={3}>
                            <WhitePanelBox className="Box">
                                <HorizontalLayout gap={2} justifyContent={'space-between'} alignItems={'center'}>
                                    <Heading Heading={'Add New Slot'} SubHeading={'Welcome ! Admin Truck'} />
                                    <HorizontalLayout gap={3}>
                                        <SvgIcon bg={'#E8EDFD'} size={'1rem'}>
                                            <SearchIcon />
                                        </SvgIcon>
                                        <SvgIcon bg={'#E8EDFD'} size={'1rem'}>
                                            <Notification />
                                        </SvgIcon>
                                    </HorizontalLayout>
                                </HorizontalLayout>
                            </WhitePanelBox>
                            <HorizontalLayout justifyContent={'between'} alignItems={'center'}>
                                <SharedButton className={'rounded-5'} onClick={() => window.history.back()} variant={'light'} size={'md'} type={'button'} label={'Back'} icon={<LeftArrow color={'#000'} />} />
                                <HorizontalLayout justifyContent={'between'} alignItems={'center'}>
                                    <SharedButton onClick={submitHandler} className={'rounded-5 text-dark'} variant={'light'} size={'md'} type={'button'} label={'Save'} />
                                </HorizontalLayout>
                            </HorizontalLayout>

                            {/* Location Form Field */}

                            <WhitePanelBox>
                                <VerticalLayout gap={3} justifyContent={'center'} alignItems={'start'}>

                                    <Row className='w-100'>
                                        <Col md={4}>
                                            <NewInputField readOnly={true} FormLabel={'Zone Name'} FormPlaceHolder={'location name'} FormType={'text'} name={'name'} value={inLocation.name} className={'rounded-2 bg-white'} />
                                        </Col>
                                    </Row>
                                </VerticalLayout>
                            </WhitePanelBox>

                            {/* Zone Form Field */}
                            {zoneData.map((zone, zoneIndex) => (
                                <div key={zoneIndex}>

                                    <WhitePanelBox className={'mt-3'}>
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Body>
                                                    <VerticalLayout gap={3} justifyContent={'center'} alignItems={'start'}>

                                                        {zone?.slots.map((slot, slotIndex) => (
                                                            <div key={slotIndex}>
                                                                <span className='w-100 border-dashed mt-2 d-block'></span>
                                                                <Row className='w-100 mt-4'>
                                                                    <Col md={4}>
                                                                        <NewInputField FormLabel={'Parking Slot Name'} FormType={'text'} FormPlaceHolder={'parking slot name'} name={'slotNumber'} value={slot?.slotNumber} className={'rounded-2 bg-white'} onChange={(e) => slotChangeHandler(e, zoneIndex, slotIndex)} />
                                                                    </Col>
                                                                    <Col md={4}>
                                                                        <NewInputField FormLabel={'Length (Feet)'} FormType={'number'} FormPlaceHolder={'length (feet)'} min={0} name={'slotLength'} value={slot?.slotLength} className={'rounded-2 bg-white'} onChange={(e) => slotChangeHandler(e, zoneIndex, slotIndex)} />
                                                                    </Col>
                                                                    <Col md={4}>
                                                                        <NewInputField FormLabel={'Width (Feet)'} FormType={'number'} FormPlaceHolder={'width (feet)'} min={0} name={'slotWidth'} value={slot?.slotWidth} className={'rounded-2 bg-white'} onChange={(e) => slotChangeHandler(e, zoneIndex, slotIndex)} />
                                                                    </Col>
                                                                    <Col md={4}>
                                                                        {/* <DropdownSelect /> */}
                                                                        <Select myClassName={'rounded-2 bg-white'} Array={optionCamera} name="cameraId" FormLabel={"Camera name"} value={slot?.cameraId} onChange={(e) => slotChangeHandler(e, zoneIndex, slotIndex)} />
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        {slotIndex > 0 &&
                                                                            < SharedButton onClick={() => deleteSlotHandler(zoneIndex, slotIndex)} variant={'danger'} className={'rounded-5 mt-4 me-3'} label={'Delete Slot'} icon={<Delete color={'white'} />} />
                                                                        }
                                                                        <SharedButton onClick={() => addSlotHandler(zoneIndex)} variant={'primary'} className={'rounded-5 mt-4'} label={'Add Slot'} icon={<AddLaneIcon />} />
                                                                    </Col>
                                                                </Row>
                                                                <Row className='mt-4'>
                                                                    {slot?.addonServiceIds.map((ser, serIndex) => (
                                                                        <Col md={3} key={serIndex}>
                                                                            <SvgIcon bg={'#D1DCFA'} className={'rounded-2 mb-2'}>
                                                                                <Checkbox
                                                                                    label={ser.serviceName}
                                                                                    onClick={(e) => checkHandler(e, zoneIndex, slotIndex, serIndex)}
                                                                                    checked={ser?.isChecked}
                                                                                    name={ser.serviceName}
                                                                                />
                                                                            </SvgIcon>
                                                                        </Col>
                                                                    ))}
                                                                </Row>
                                                            </div>
                                                        ))}
                                                    </VerticalLayout>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </WhitePanelBox>
                                </div>
                            ))}
                        </VerticalLayout>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
