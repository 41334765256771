import React, { useEffect, useState } from 'react'
import { Container, Row, Col, } from 'react-bootstrap'
import { Sidebar } from '../../commonPages/sidebar'
import { WhitePanelBox } from '../../components/WhitePanelBox'
import { VerticalLayout } from '../../components/VerticalLayout'
import { HorizontalLayout } from '../../components/HorizontalLayout'
import { Heading } from '../../components/Heading'
import { SvgIcon } from '../../components/SvgIcon'
import { SearchPanel } from '../../components/SearchPanel'
import { Notification } from '../../components/Icons/Notification'
import Typography from '../../components/Typography'
import { SecurityQRTable } from './SecurityQRTable';
import { getSecurityAccessPinCodes } from "../../services/NetworkCall";
import { errorAlert } from "../../components/Alert"
import { Loader } from '../../components/Loader'
import Header from '../../components/Header'

export const SecurityQRList = () => {
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({ totalPages: 1, page: 1 });
    const [securityData, setSecurityData] = useState([]);

    const getSecurityAccessPin = async (page = 1) => {
        setLoading(true);
        const res = await getSecurityAccessPinCodes(page);
        if (res.success) {
            setSecurityData(res.data);
            setPagination(prevPagination => ({
                ...prevPagination,
                totalPages: res.totalNumberOfPages
            }));
            // successAlert(res.message);
        } else {
            errorAlert({ message: res.message });
        }
        setLoading(false);
    }

    useEffect(() => {
        getSecurityAccessPin(pagination.page);
    },
        [pagination.page]);

    const pageHandler = (page) => {
        setPagination(prevPagination => ({
            ...prevPagination,
            page: page
        }));
        getSecurityAccessPin(page);
    }

    return (
        <>
            <Loader show={loading} />
            <Container fluid>
                <Row className="g-3">
                    <Col md={3}>
                        <Sidebar />
                    </Col>
                    <Col md={9}>
                        <VerticalLayout gap={3}>
                            <Header
                                heading={'Security / Access Control'}
                                subHeading={'Welcome ! Admin Truck'}
                                showNotificationIcon={true}
                            />

                            <Typography>Pin Code</Typography>
                            <SecurityQRTable
                                pagination={pagination}
                                pageHandler={pageHandler}
                                pinData={securityData}
                            />
                        </VerticalLayout>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
