import React from "react";
import { Stack } from "react-bootstrap";
import Typography from "./Typography";
export const Heading = ({
   Heading,
   SubHeading,
}) => {
return (
       <Stack direction="vertical" gap={0}>
         <Typography variant='h3' className='fw-bold mb-1' size='1.5rem' color='#000'>{Heading}</Typography>
         <Typography className={'mb-1'} color='#64748B' size={'.8rem'}>{SubHeading}</Typography>
       </Stack>
);
}