import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Modal, Form, Image } from 'react-bootstrap'
import { Loader } from '../../../components/Loader'
import { Sidebar } from '../../../commonPages/sidebar'
import { VerticalLayout } from '../../../components/VerticalLayout'
import { WhitePanelBox } from '../../../components/WhitePanelBox'
import { HorizontalLayout } from '../../../components/HorizontalLayout'
import { Heading } from '../../../components/Heading'
import { SvgIcon } from '../../../components/SvgIcon'
import { SearchIcon } from '../../../components/Icons/SearchIcon'
import { Notification } from '../../../components/Icons/Notification'
import Typography from '../../../components/Typography'
import { SharedButton } from '../../../components/SharedButton'
import { LeftArrow } from '../../../components/Icons/LeftArrow'
import { LaneIcon } from '../../../components/Icons/LaneIcon'
import { SlotIcon } from '../../../components/Icons/SlotIcon'
import { PowerIcon } from '../../../components/Icons/PowerIcon'
import { WifiIcon } from '../../../components/Icons/WifiIcon'
import { ParkingAssignmentIcon } from '../../../components/Icons/ParkingAssignmentIcon'
import { VacuumIcon } from '../../../components/Icons/VacuumIcon'
import { AirTireIcon } from '../../../components/Icons/AirTireIcon'
import { InputFields } from '../../../components/InputFields'
import { AddLaneIcon } from '../../../components/Icons/AddLaneIcon'
import { SlotTableList } from './SlotTableList'
import { useLocation, useNavigate } from 'react-router-dom'
import { NewInputField } from '../../../components/NewInputField'
import { errorAlert, successAlert } from '../../../components/Alert'
import { deleteParkingZoneAPI, getAddOnServiceByIdAPI, getAddonServicesAPI, getParkingSlotsAPI, updateParkingSlotsAPI, updateParkingZoneAPI } from '../../../services/NetworkCall'
import Swal from 'sweetalert2'
import { Delete } from '../../../components/Icons/Delete'
import Header from '../../../components/Header'
import { Checkbox } from '../../../components/Checkbox'



export const EditLaneLocation = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [preData, setPreData] = useState({ "id": "", "name": "" });
    const [erPreData, setErPreData] = useState({ "id": "", "name": "" });
    const [viMode, setViMode] = useState(false);
    const location = useLocation();
    const [preSlot, setPreSlot] = useState([]);
    const [show, setshow] = useState(false);
    const [pagination, setPagination] = useState({ totalPages: 1, page: 1 });
    const [addonServices, setAddOnServices] = useState([]);


    const openModal = () => setshow(true);
    const closeModal = () => setshow(false);

    const getSlotList = async (page = 1, id) => {
        setLoading(true);
        const res = await getParkingSlotsAPI(page, id);
        console.log("this is respponse---------------------------->", res)
        if (res.success) {
            const data = res.data;
            setPagination(prevPagination => ({
                ...prevPagination,
                totalPages: res.totalNumberOfPages
            }));
            // console.log(data);
            if (data) {
                const modifyData = data.map((s) => ({ ...s, selected: false }));
                setPreSlot(modifyData);
            }

            // setPreSlot(data);
            setLoading(false);
        }
        setLoading(false);
    }

    const pageHandler = (page) => {
        setPagination(prevPagination => ({
            ...prevPagination,
            page: page
        }));
        getSlotList(page, preData.id);
    }


    useEffect(() => {
        if (location?.state?.data) {
            const data = location?.state?.data?.pre;
            getSlotList(pagination.page, data._id);
            let pre = { ...preData };
            pre.id = data._id;
            pre.name = data.zoneName;
            setPreData(pre);
            const mode = location?.state?.data?.mode;
            if (mode === 'view') setViMode(true);
        }
    }, [location, pagination.page]);

    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        setPreData((pre) => ({ ...pre, [name]: value }));
        setErPreData((pre) => ({ ...pre, [name]: '' }));
    }

    useEffect(() => { getAddOnServices() }, [])

    const getAddOnServices = async () => {
        setLoading(true);
        const res = await getAddonServicesAPI();
        if (res.success) {
            const mData = res.data.map((d) => ({ ...d, selected: false }));
            setLoading(false);
            setAddOnServices(mData);
        } else {
            setLoading(false);
            errorAlert({ message: res.message });
        }
    }

    const updateHandler = async () => {
        const { id, name } = preData;
        if (!id) { errorAlert("Id missing"); return false; };
        if (!name) { setErPreData((pre) => ({ ...pre, 'name': 'Required' })); return }

        setLoading(true);
        const data = { "zoneName": name };
        try {
            const res = await updateParkingZoneAPI(id, data);
            setLoading(false);
            if (res.success) {
                successAlert({ message: res.message });
                // navigate('/location-list');
            } else {
                errorAlert({ message: res.message });
            }
        } catch (error) {
            setLoading(false);
            errorAlert({ message: "An error occurred while creating the location." });
            console.error(error);
        }
    }

    const deleteHandler = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await deleteParkingZoneAPI(id);
                if (res.success) {
                    setLoading(false);
                    successAlert({ message: res.message });
                    navigate("/location-list");
                } else {
                    setLoading(false);
                    errorAlert({ message: res.message });
                }
                setLoading(false);
            }
        });
    }


    const handleSlotSelect = (slotId) => {
        if (slotId === 1) {
            // Check if all slots are already selected
            const allSelected = preSlot.every((s) => s.selected);

            // Toggle all slots: If all are selected, unselect all; otherwise, select all
            const mData = preSlot.map((s) => ({ ...s, selected: !allSelected }));

            setPreSlot(mData);
        } else {
            // Toggle individual slot selection
            const mData = preSlot.map((s) =>
                s._id === slotId ? { ...s, selected: !s.selected } : s
            );

            setPreSlot(mData);
        }
    };


    const handleBulkUpdateClick = () => {
        const isAnySlotSelected = preSlot.some(slot => slot.selected);

        if (!isAnySlotSelected) {
            errorAlert({ message: "Please select at least one slot before proceeding." });
            return;
        }
        openModal();
    };



    const handleServiceSelect = (id) => {
        const mData = addonServices.map((s) => ({
            ...s,
            selected: s._id === id ? !s.selected : s.selected // Toggle selection
        }));

        setAddOnServices(mData);
    };

    const handleUpdateClick = async () => {
        const isAnyServiceSelected = addonServices.some(s => s.selected);

        if (!isAnyServiceSelected) {
            errorAlert({ message: "Please select at least one service" });
            return;
        }

        const selectedSlots = preSlot.filter(slot => slot.selected).map(slot => slot._id);
        const selectedAddOnServices = addonServices.filter(service => service.selected).map(service => service._id);

        if (!isAnyServiceSelected || selectedSlots.length == 0) {
            errorAlert({ message: "Something went wrong please try again later" });
            return;
        }

        setLoading(true);

        const payload = {
            "slotIds": selectedSlots,
            "addonServiceIds": selectedAddOnServices
        }

        const res = await updateParkingSlotsAPI(payload);

        if (res.success) {
            setLoading(false);
            closeModal(true);
            successAlert({ message: res.message });
            // Refresh the slot list after successful update
            getSlotList(pagination.page, preData.id);
        } else {
            errorAlert({ message: res.message });
            setLoading(false)
            closeModal()
        }
    }

    return (
        <>
            <Loader show={loading} />
            <Container fluid>
                <Row className="m-0">
                    <Col md={3}>
                        <Sidebar />
                    </Col>
                    <Col md={9}>
                        <VerticalLayout gap={3}>
                            <Header
                                heading={"Edit Zone"}
                                subHeading={'Welcome ! Admin Truck'}
                                showNotificationIcon={true}
                            />
                            <HorizontalLayout justifyContent={'between'} alignItems={'center'}>
                                <SharedButton onClick={() => window.history.back()} className={'rounded-5'} variant={'light'} size={'md'} type={'button'} label={'Back'} icon={<LeftArrow color={'#000'} />} />
                                <HorizontalLayout justifyContent={'between'} alignItems={'center'} gap={3}>
                                    {!viMode &&
                                        <>
                                            <SharedButton className={'rounded-5 text-dark'} variant={'light'} size={'md'} type={'button'} onClick={updateHandler} label={'Update'} />
                                            <SharedButton label={'Delete'} type={'button'} variant={'danger-light'} size={'md'} className={'rounded-5 text-black'} onClick={() => deleteHandler(preData.id)} icon={<Delete />} />
                                        </>
                                    }
                                </HorizontalLayout>
                            </HorizontalLayout>
                            <WhitePanelBox>
                                <VerticalLayout gap={3} justifyContent={'center'} alignItems={'start'}>
                                    <Row className='w-100'>
                                        <Col md={4}>
                                            <NewInputField readOnly={viMode} FormLabel={'Lane Name'} FormPlaceHolder={'lane name'} FormType={'text'} name={'name'} value={preData.name} className={'rounded-2 bg-white'} error={erPreData.name} onChange={onChangeHandler} />
                                        </Col>
                                    </Row>
                                </VerticalLayout>
                            </WhitePanelBox>
                            <HorizontalLayout gap={1} justifyContent={'between'} alignItems={'center'}>
                                <Typography className={'fw-600'} color={'#000'}>Slots</Typography>
                                {!viMode &&
                                    // <SharedButton variant={'light'} className={'rounded-5'} label={'Add Slots'} icon={<AddLaneIcon />} onClick={addSlotsNavigate} />
                                    <SharedButton variant={'light'} className={'rounded-5'} label={'Bulk Update'} onClick={handleBulkUpdateClick} />
                                }
                            </HorizontalLayout>
                            <WhitePanelBox>
                                <SlotTableList handleSlotSelect={handleSlotSelect} preSlot={preSlot} pagination={pagination} pageHandler={pageHandler} />
                            </WhitePanelBox>
                        </VerticalLayout>
                    </Col>
                </Row>
            </Container>
            {/* --------------------------services modal ---------------------------- */}
            <Modal show={show} onHide={closeModal} centered backdrop="static">
                <Modal.Header>
                    <Modal.Title>ADD-ON services</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className='g-3'>
                            {addonServices && addonServices.length != 0 && addonServices.map((s) =>
                                <Col md={6} sm={12}>
                                    <SvgIcon bg={'#D1DCFA'} className={'rounded-2 p-3'}>
                                        <HorizontalLayout gap={2} justifyContent={'between'} alignItems={'center'}>
                                            <HorizontalLayout gap={3} alignItems={'center'}>
                                                <Image src={s?.image} width={20} height={20} alt='icon' />
                                                <Typography variant={'h6'} className={'mb-0'}>{s?.name}</Typography>
                                            </HorizontalLayout>
                                            <Checkbox checked={s?.selected} onClick={() => { handleServiceSelect(s?._id) }} /></HorizontalLayout>
                                    </SvgIcon>
                                </Col>
                            )}
                        </Row>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <SharedButton variant={'secondary'} onClick={closeModal} label={'Close'} />
                    <SharedButton variant={"primary"} label={'Update'} onClick={handleUpdateClick} />
                </Modal.Footer>
            </Modal>

        </>
    )
}
