import React from 'react'

export const VehicleRegistrationIcon = () => {
    return (
        <>

            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_1418_2934" mask-type="luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                    <path d="M0.5 1.90735e-06H24.5V24H0.5V1.90735e-06Z" fill="white" />
                </mask>
                <g mask="url(#mask0_1418_2934)">
                    <path d="M6.85156 22.2422V22.7109C6.85156 23.2287 6.43184 23.6484 5.91406 23.6484H4.22656C3.70878 23.6484 3.28906 23.2287 3.28906 22.7109V21.7734" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M21.7109 21.7734V22.7109C21.7109 23.2287 21.2912 23.6484 20.7734 23.6484H19.0859C18.5682 23.6484 18.1484 23.2287 18.1484 22.7109V22.2422" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M22.8988 21.437L18.1484 22.2422H6.85156L2.10111 21.437C1.65027 21.3606 1.32031 20.97 1.32031 20.5127V19.6172C1.32031 19.3583 1.53017 19.1484 1.78906 19.1484H23.2109C23.4698 19.1484 23.6797 19.3583 23.6797 19.6172V20.5127C23.6797 20.97 23.3497 21.3606 22.8988 21.437Z" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7.08594 19.1484V13.0547C7.08594 11.7603 8.13528 10.7109 9.42969 10.7109H15.5703C16.8647 10.7109 17.9141 11.7603 17.9141 13.0547V19.1484" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10.0156 12.1172H9.66406C9.01686 12.1172 8.49219 12.6419 8.49219 13.2891V16.5703C8.49219 17.2175 9.01686 17.7422 9.66406 17.7422H15.3359C15.9831 17.7422 16.5078 17.2175 16.5078 16.5703V13.2891C16.5078 12.6419 15.9831 12.1172 15.3359 12.1172H11.6562" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M15.1484 13.5234H9.89844" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M15.1484 14.9297H9.89844" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M15.1484 16.3359H9.89844" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7.08594 13.2422L5.19191 13.2234C3.62783 13.208 2.35156 14.4716 2.35156 16.0358V19.1484" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M17.9141 13.2367H19.8359C21.3892 13.2367 22.6484 14.4959 22.6484 16.0492V19.1484" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7.08594 17.5078H4.22656C3.96767 17.5078 3.75781 17.2979 3.75781 17.0391V15.8672C3.75781 15.6083 3.96767 15.3984 4.22656 15.3984H7.08594" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M17.9141 15.3984H20.7734C21.0323 15.3984 21.2422 15.6083 21.2422 15.8672V17.0391C21.2422 17.2979 21.0323 17.5078 20.7734 17.5078H17.9141" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5.82031 13.207V4.33763H19.1797V13.207" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M17.9141 8.71875V8.83594C17.9141 9.09483 17.7042 9.30469 17.4453 9.30469H7.55469C7.2958 9.30469 7.08594 9.09483 7.08594 8.83594V6.21094C7.08594 5.95205 7.2958 5.74219 7.55469 5.74219H17.4453C17.7042 5.74219 17.9141 5.95205 17.9141 6.21094V7.07813" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M19.1797 4.33594L18.215 1.60252C17.9503 0.852891 17.2418 0.351563 16.4468 0.351563H8.55317C7.75817 0.351563 7.04966 0.852891 6.78505 1.60252L5.82031 4.33594" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10.4844 2.17969H11.9297" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M13.0703 2.17969H14.5156" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                </g>
            </svg>


        </>
    )
}
