
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { Login } from './commonPages/login';
import { ForgotPassword } from './commonPages/forgotPassword';
import { Dashboard } from './pages/dashboard';
import { UserAccount } from './pages/userAccount';
import { UserDetail } from './pages/userAccount/userDetail/UserDetail';
import Auth from './services/Auth';
import { LocationList } from './pages/location/locationList/LocationList';
import { CameraList } from './pages/camera/list/CameraList';
import { AddCamera } from './pages/camera/add/AddCamera';
import { EditCamera } from './pages/camera/edit/EditCamera';
import { AddOnService } from './pages/addOnService';
import { AddService } from './pages/addOnService/addService/AddService';
import { LocationDetail } from './pages/location/locationDetail/LocationDetail';
import { EditLocation } from './pages/location/editLocation/EditLocation';
import { EditLaneLocation } from './pages/location/addLaneLocation/EditLaneLocation';
import { CreateLocation } from './pages/location/createLocation/CreateLocation';
import { EditSlot } from './pages/location/addSlotLocation/EditSlot';
import EditService from './pages/addOnService/addOnServiceForm/EditService';
import { CreateProduct } from './pages/createProduct';
import { VehicleRegistration } from './pages/vehicleRegistration';
import { Transaction } from './pages/transaction';
import { TransactionDetail } from './pages/transaction/TransactionDetail';
import { SecurityQRList } from './pages/security';
import { SecurityQRDetail } from './pages/security/SecurityQRDetail';
import { ViewSlot } from './pages/location/addSlotLocation/ViewSlot';
import { CreateZone } from './pages/location/createLocation/CreateZone';
import { CreateSlot } from './pages/location/createLocation/CreateSlot';
import { SubUserDetail } from './pages/userAccount/userDetail/SubUserDetail';
import { Chat } from './pages/chat';
import { AdminUserList } from './pages/adminUser/AdminUserList';
import { AddAdminUser } from './pages/adminUser/AddAdminUser';
import { EditAdminUser } from './pages/adminUser/EditAdminUser';
import { UserRegistrationReport } from './pages/reports/userRegistration';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Login />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      {/* protected routes */}
      <Route element={<Auth />}>
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/location-list' element={<LocationList />} />
        <Route path='/location-detail' element={<LocationDetail />} />
        <Route path='/create-location' element={<CreateLocation />} />
        <Route path='/add-zone' element={<CreateZone />} />
        <Route path='/add-slot' element={<CreateSlot />} />
        <Route path='/edit-location' element={<EditLocation />} />
        <Route path='/edit-lane' element={<EditLaneLocation />} />
        <Route path='/edit-slot' element={<EditSlot />} />
        <Route path='/view-slot' element={<ViewSlot />} />
        <Route path='/users-account' element={<UserAccount />} />
        <Route path='/user-detail' element={<UserDetail />} />
        <Route path='/sub-user-detail' element={<SubUserDetail />} />
        <Route path='/create-product' element={<CreateProduct />} />
        <Route path='/vehicle-registration' element={<VehicleRegistration />} />
        <Route path='/add-on-service' element={<AddOnService />} />
        <Route path='/add-service' element={<AddService />} />
        <Route path='/edit-service' element={<EditService />} />
        <Route path='/camera-list' element={<CameraList />} />
        <Route path='/add-camera' element={<AddCamera />} />
        <Route path='/edit-camera' element={<EditCamera />} />
        <Route path='/transactions' element={<Transaction />} />
        <Route path='/transaction-detail' element={<TransactionDetail />} />
        <Route path='/security' element={<SecurityQRList />} />
        <Route path='/security-detail' element={<SecurityQRDetail />} />
        <Route path='/chats' element={<Chat />} />
        <Route path='/admin-users' element={<AdminUserList />} />
        <Route path='/add-admin-user' element={<AddAdminUser />} />
        <Route path='/edit-admin-user' element={<EditAdminUser />} />

        <Route path='/reports' element={<UserRegistrationReport />} />

      </Route>

    </Routes>
  );
}

export default App;
