import React, { useState } from 'react'
import { SearchIcon } from './Icons/SearchIcon';
import { SvgIcon } from './SvgIcon';


export const SearchPanel = ({ onSearch }) => {
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    const toggleSearch = () => {
        setIsSearchOpen(!isSearchOpen);
    };

    const onClickHandler = () => {
        onSearch();
        // toggleSearch();
    }

    return (
        <>
            <SvgIcon
                bg={'#E8EDFD'}
                size={'1rem'}
                onClick={onClickHandler} 
                className='cursor-pointer'
            >
                <SearchIcon />
            </SvgIcon>
            {isSearchOpen && (
                <input
                    type="text"
                    placeholder="Search..."
                    onClick={onSearch}
                    // onKeyPress={(e) => {
                    //     if (e.key === 'Enter') {
                    //         onSearch(); // Call the search handler
                    //     }
                    // }}
                    className='border me-3 p-2 rounded'
                />
            )}
        </>
    );
};

