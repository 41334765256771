import React from 'react'

export const ClientIcon = () => {
    return (
        <>

            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M16.0001 2.08826C12.6052 2.08826 9.85306 4.84039 9.85306 8.23532C9.85306 11.6302 12.6052 14.3824 16.0001 14.3824C19.395 14.3824 22.1472 11.6302 22.1472 8.23532C22.1472 4.84039 19.395 2.08826 16.0001 2.08826ZM11.7942 8.23532C11.7942 5.91247 13.6773 4.02943 16.0001 4.02943C18.323 4.02943 20.206 5.91247 20.206 8.23532C20.206 10.5582 18.323 12.4412 16.0001 12.4412C13.6773 12.4412 11.7942 10.5582 11.7942 8.23532Z" fill="black" />
                <path fillRule="evenodd" clipRule="evenodd" d="M12.1178 16.3236C8.72283 16.3236 5.9707 19.0757 5.9707 22.4706C5.9707 25.8655 8.72283 28.6177 12.1178 28.6177H19.8825C23.2774 28.6177 26.0295 25.8655 26.0295 22.4706C26.0295 19.0757 23.2774 16.3236 19.8825 16.3236H12.1178ZM7.91188 22.4706C7.91188 20.1478 9.79492 18.2647 12.1178 18.2647H19.8825C22.2053 18.2647 24.0883 20.1478 24.0883 22.4706C24.0883 24.7935 22.2053 26.6765 19.8825 26.6765H12.1178C9.79492 26.6765 7.91188 24.7935 7.91188 22.4706Z" fill="black" />
            </svg>

        </>
    )
}
