import React, { useEffect, useState } from 'react'
import { WhitePanelBox } from '../../../components/WhitePanelBox'
import { HorizontalLayout } from '../../../components/HorizontalLayout'
import { SharedButton } from '../../../components/SharedButton'
import { SlotIcon } from '../../../components/Icons/SlotIcon'
import { Row, Col, Form, Image } from 'react-bootstrap'
import { VerticalLayout } from '../../../components/VerticalLayout'
import { Delete } from '../../../components/Icons/Delete'
import { UploadImg } from '../../../components/Icons/UploadImg'
import { DropdownSelect } from '../../../components/DropdownSelect'
import { UploadFile } from '../../../components/UploadFile'
import Select from '../../../components/Select'

import { optionAddOnServiceType, optionIsPaid } from '../../../helper/Helper'
import { NewInputField } from '../../../components/NewInputField'
import { deleteAddonServiceAPI, updateAddonServiceAPI } from '../../../services/NetworkCall'
import { errorAlert, successAlert } from '../../../components/Alert'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'

export const EditServiceForm = ({ setLoading, serviceData, getDataHandler, isView }) => {
    const navigate = useNavigate();

    const [inData, setInData] = useState({ "id": "", "serviceType": "", "image": "", "serviceName": "", "isPaid": "", "dailyPrice": "", "weeklyPrice": "", "monthlyPrice": "", "subscriptionPrice": "", "serviceSubtitle": "", "infoDescription": "", "preImage": "" });
    const [error, setError] = useState({ "id": "", "image": "", "serviceName": "", "isPaid": "", "dailyPrice": "", "weeklyPrice": "", "monthlyPrice": "", "subscriptionPrice": "", "serviceSubtitle": "", "infoDescription": "", "preImage": '' });

    useEffect(() => {
        if (serviceData && serviceData.id) {
            let pre = { ...inData };
            pre.id = serviceData.id;
            pre.serviceName = serviceData.serviceName;
            pre.isPaid = serviceData.isFree
            pre.dailyPrice = serviceData.dailyPrice;
            pre.weeklyPrice = serviceData.weeklyPrice;
            pre.monthlyPrice = serviceData.monthlyPrice;
            pre.subscriptionPrice = serviceData.subscriptionPrice;
            pre.serviceSubtitle = serviceData.serviceSubtitle;
            pre.infoDescription = serviceData.info;
            pre.preImage = serviceData.imageUrl;
            pre.stripeProductId = serviceData.stripeProductId;
            pre.serviceType = serviceData.serviceType;
            setInData(pre);
        }

    }, [serviceData])

    const imageHandler = (data) => {
        const { name, value } = data;
        setInData((pre) => ({ ...pre, [name]: value }));
        setError((pre) => ({ ...pre, [name]: "" }));
    }
    const inputHandler = (e) => {
        const { name, value, type } = e.target;

        if (type === 'number') {
            const regex = /^\d*(\.\d{0,2})?$/;
            if (value.includes('.') && value.split('.')[1].length > 2) {

            } else {
                setInData((pre) => ({ ...pre, [name]: value }));
                setError((pre) => ({ ...pre, [name]: "" }));
            }
        } else {
            setInData((pre) => ({ ...pre, [name]: value }));
            setError((pre) => ({ ...pre, [name]: "" }));
        }

    }


    console.log("this is indata---------------------", inData);

    const submitHandler = async (e) => {

        e.preventDefault();
        let isValid = true;
        const { serviceName, isPaid, dailyPrice, weeklyPrice, monthlyPrice, subscriptionPrice, serviceSubtitle, infoDescription, image } = inData;
        if (!serviceName) { setError((pre) => ({ ...pre, "serviceName": 'Required' })); isValid = false; }
        if (!isPaid) { setError((pre) => ({ ...pre, "isPaid": 'Required' })); isValid = false; }

        if (!serviceSubtitle) { setError((pre) => ({ ...pre, "serviceSubtitle": 'Required' })); isValid = false; }
        if (!infoDescription) { setError((pre) => ({ ...pre, "infoDescription": 'Required' })); isValid = false; }
        // if (!image) { setError((pre) => ({ ...pre, "image": 'Required' })); isValid = false; }


        if (isPaid === 2) {
            if (!dailyPrice) { setError((pre) => ({ ...pre, "dailyPrice": 'Required' })); isValid = false; }
            if (!weeklyPrice) { setError((pre) => ({ ...pre, "weeklyPrice": 'Required' })); isValid = false; }
            if (!monthlyPrice) { setError((pre) => ({ ...pre, "monthlyPrice": 'Required' })); isValid = false; }
            if (!subscriptionPrice) { setError((pre) => ({ ...pre, "subscriptionPrice": 'Required' })); isValid = false; }
        }

        if (isValid) {
            setLoading(true);
            const id = inData.id;
            const data = new FormData();
            data.append('image', inData.image);
            data.append('name', inData.serviceName);
            if (inData.isPaid == 1) {
                data.append('isFree', true);
                data.append('dayPrice', '');
                data.append('weeklyPrice', '');
                data.append('monthlyPrice', '');
                data.append('monthlySubscriptionPrice', '');
            } else if (inData.isPaid == 2) {
                data.append('isFree', false);
                data.append('dayPrice', inData.dailyPrice);
                data.append('weeklyPrice', inData.weeklyPrice);
                data.append('monthlyPrice', inData.monthlyPrice);
                data.append('monthlySubscriptionPrice', inData.subscriptionPrice);
            } else {
                data.append('isFree', inData.isPaid);
                data.append('dayPrice', inData.dailyPrice);
                data.append('weeklyPrice', inData.weeklyPrice);
                data.append('monthlyPrice', inData.monthlyPrice);
                data.append('monthlySubscriptionPrice', inData.subscriptionPrice);
            }
            data.append('subtitle', inData.serviceSubtitle);
            data.append('description', inData.infoDescription);
            data.append('stripeProductId', inData.stripeProductId);

            const res = await updateAddonServiceAPI(data, id);
            if (res.success) {
                setLoading(false);
                getDataHandler(id);
                successAlert({ message: res.message })
            } else {
                setLoading(false);
                errorAlert({ message: res.message });
            }
            setLoading(false);
        }
    }


    const deleteHandler = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await deleteAddonServiceAPI(id);
                if (res.success) {
                    setLoading(false);
                    successAlert({ message: res.message });
                    navigate("/add-on-service");
                } else {
                    setLoading(false);
                    errorAlert({ message: res.message });
                }
                setLoading(false);
            }
        });

    }

    return (
        <>
            <WhitePanelBox>
                    <Form className='p-2'>
                        <Row className='g-3'>
                            <Col md={12}>
                                <HorizontalLayout gap={3} justifyContent={'between'}>
                                    {isView ?
                                        <Image src={inData?.preImage} alt='image' width={100} height={100} className='rounded-3 border-dashed-2 p-1' />
                                        :
                                        <>
                                            <HorizontalLayout gap={3}>
                                                <UploadFile
                                                    FormLabel="Upload"
                                                    name="image"
                                                    controlId="formProfilePic"
                                                    onChange={imageHandler}
                                                    error={error.image}
                                                />
                                                {inData?.preImage &&
                                                    <Image src={inData?.preImage} alt='image' width={100} height={100} className='rounded-3 border-dashed-2  p-1' />
                                                }
                                            </HorizontalLayout>
                                        </>
                                    }
                                    {!isView &&
                                        <SharedButton label={'Delete'} type={'button'} variant={'danger-light'} size={'md'} className={'rounded-5 text-black'} onClick={() => deleteHandler(inData.id)} icon={<Delete />} />
                                    }
                                </HorizontalLayout>
                            </Col>

                            <Col md={4} sm={12}>
                                <NewInputField readOnly={isView} FormLabel={'Service Name'} FormType={'text'} name={'serviceName'} value={inData.serviceName} className={'rounded-2 bg-white'} error={error.serviceName} onChange={inputHandler} />
                            </Col>

                            <Col md={4} sm={12}>
                                <Select myClassName={'rounded-2 bg-white'} Array={optionIsPaid} required={true} name="isPaid" FormLabel={"Paid /Free"} error={error.isPaid} value={inData.isPaid} onChange={inputHandler} />
                            </Col>
                            <Col md={4} sm={12}>
                                {inData.isPaid == 2 ?
                                    <>
                                        <NewInputField readOnly={isView} FormLabel={'Daily Price'} placeholder={'price'} FormType={'number'} value={inData.dailyPrice} name={'dailyPrice'} error={error.dailyPrice} min={'0'} className={'rounded-2 bg-white'} onChange={inputHandler} />
                                    </>
                                    :
                                    <>
                                        <NewInputField readOnly={isView} FormLabel={'Daily Price'} disabled={true} placeholder={'price'} FormType={'number'} value={''} name={'dailyPrice'} error={error.dailyPrice} min={'0'} className={'rounded-2 bg-white'} onChange={inputHandler} />
                                    </>
                                }

                            </Col>
                            <Col md={4} sm={12}>
                                {inData.isPaid == 2 ?
                                    <NewInputField readOnly={isView} FormLabel={'Weekly Price'} placeholder={'price'} disabled={!inData.isPaid} FormType={'number'} name={'weeklyPrice'} value={inData.weeklyPrice} error={error.weeklyPrice} min={'0'} className={'rounded-2 bg-white'} onChange={inputHandler} />
                                    :
                                    <NewInputField readOnly={isView} FormLabel={'Weekly Price'} disabled={true} FormType={'number'} value={''} className={'rounded-2 bg-white'} />
                                }
                            </Col>
                            <Col md={4} sm={12}>
                                {inData.isPaid == 2 ?
                                    <NewInputField readOnly={isView} FormLabel={'Monthly Price'} placeholder={'price'} FormType={'number'} name={'monthlyPrice'}
                                        value={inData.monthlyPrice} error={error.monthlyPrice} min={'0'} className={'rounded-2 bg-white'} onChange={inputHandler} />
                                    :
                                    <NewInputField readOnly={isView} FormLabel={'Monthly Price'} disabled={true} FormType={'number'} value={''} className={'rounded-2 bg-white'} />
                                }
                            </Col>
                            <Col md={4} sm={12}>
                                {inData.isPaid == 2 ?
                                    <NewInputField readOnly={isView} FormLabel={'Monthly Subscription Price'} placeholder={'price'} FormType={'number'} name={'subscriptionPrice'} value={inData.subscriptionPrice} min={'0'} error={error.subscriptionPrice} className={'rounded-2 bg-white'} onChange={inputHandler} />
                                    :
                                    <NewInputField readOnly={isView} FormLabel={'Monthly Subscription Price'} disabled={true} FormType={'number'} value={''} className={'rounded-2 bg-white'} />
                                }
                            </Col>
                            <Col md={4} sm={12}>
                                <NewInputField readOnly={true} FormLabel={'Service Type'} placeholder={'Service Type'} FormType={'text'} name={'serviceType'} value={inData.serviceType} className={'rounded-2 bg-white'} />
                            </Col>
                            <Col md={12} sm={12}>
                                <NewInputField readOnly={isView} FormLabel={'Service Subtitle'} placeholder={'service subtitle'} FormType={'text'} name={'serviceSubtitle'} value={inData.serviceSubtitle} error={error.serviceSubtitle} className={'rounded-2 bg-white'} onChange={inputHandler} />
                            </Col>
                            <Col md={12} sm={12}>
                                <NewInputField readOnly={isView} FormLabel={'Info Description'} placeholder={'info description'} FormType={'text'} name={'infoDescription'} value={inData.infoDescription} error={error.infoDescription} className={'rounded-2 bg-white'} onChange={inputHandler} />
                            </Col>

                            {!isView &&
                                <Col md={12} sm={12}>
                                        <SharedButton type={'button'} label={'Update Service'} size={'md'} variant={'primary'} className={'px-5 py-2 rounded-2'} onClick={submitHandler} />
                                </Col>
                            }
                        </Row>
                    </Form>
            </WhitePanelBox>
        </>
    )
}
