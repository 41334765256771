import moment from 'moment-timezone';
import { errorAlert } from '../components/Alert';


// Email validation regex pattern
export let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


// const laneData = [
//   { id: 1, Icon: <LaneIcon />, label: '5 Lane' },
//   { id: 2, Icon: <SlotIcon />, label: '300 Slots' },
//   { id: 3, Icon: <PowerIcon />, label: 'Power of truck' },
//   { id: 3, Icon: <ParkingAssignmentIcon />, label: 'Parking Assignment' },
//   { id: 3, Icon: <WifiIcon />, label: 'Internet' },
//   { id: 3, Icon: <VacuumIcon />, label: 'Vacuum' },
//   { id: 3, Icon: <AirTireIcon />, label: 'Air for Tire' },
// ];


export const TimezoneList = () => {
  const tzNames = moment.tz.names();
  return tzNames ? tzNames : [];
};

export const statusArray = [
  { name: "Active", value: "Active" },
  { name: "InActive", value: "Inactive" }
]

export const timeFormateArray = [
  { name: '12-Hours', "value": 'hh:mm:ss A' },
  { name: '24-Hours', "value": 'HH:mm:ss' }
]

export const utcToLocal = (utcTime) => {
  return moment.utc(utcTime).local().format('YYYY-MM-DD');
}


export const utcToLocalTime = (utcTime) => {
  return moment.utc(utcTime).local().format('YYYY-MM-DD hh:mm:ss A');
}


export const optionIsPaid = [
  { name: "Free", value: 1 },
  { name: "Paid", value: 2 },
]

export const optionIsFree = [
  { name: "Free", value: true },
  { name: "Paid", value: false },
]


export const optionAddOnServiceType = [
  { name: "CAMERA_FEED", value: "CAMERA_FEED" },
  { name: "POWER", value: "POWER" },
  { name: "INTERNET", value: "INTERNET" },
  { name: "VACUUM", value: "VACUUM" },
  { name: "AIR_FOR_TIRE", value: "AIR_FOR_TIRE" }
]


export const updateCheckedServices = (data) => {
  return data.map((zone) => ({
    ...zone,
    slots: zone.slots.map((slot) => ({
      ...slot,
      addonServiceIds: slot.addonServiceIds
        .filter((service) => service.isChecked) // Filter services with isChecked true
        .map((service) => service.id) // Return only the id values
        .filter((id) => id) // Remove empty or blank values
    }))
  }));
};


export const CheckedSlotsIDS = (data) => {
  return data.filter((service) => service.isChecked) // Filter services with isChecked true
    .map((service) => service.id) // Return only the id values
    .filter((id) => id) // Remove empty or blank values
};




export const validateZoneData = (zoneData) => {
  // Check for missing zone names
  const missingZoneName = zoneData.some((zone) => !zone.zoneName.trim());
  if (missingZoneName) {
    errorAlert({ message: "Please enter a zone name for all zones." });
    return false;
  }

  // Check for missing slot numbers
  const missingSlotNumber = zoneData.some((zone) =>
    zone.slots.some((slot) => !slot.slotNumber.trim())
  );
  if (missingSlotNumber) {
    errorAlert({ message: "Please enter a slot name for all slots." });
    return false;
  }

  // Check for missing slot lengths
  const missingSlotLength = zoneData.some((zone) =>
    zone.slots.some((slot) => !slot.slotLength.trim())
  );
  if (missingSlotLength) {
    errorAlert({ message: "Please enter a slot length for all slots." });
    return false;
  }

  // Check for missing slot widths
  const missingSlotWidth = zoneData.some((zone) =>
    zone.slots.some((slot) => !slot.slotWidth.trim())
  );
  if (missingSlotWidth) {
    errorAlert({ message: "Please enter a slot width for all slots." });
    return false;
  }

  // Check for missing camera IDs
  const missingCameraId = zoneData.some((zone) =>
    zone.slots.some((slot) => !slot.cameraId.trim())
  );
  if (missingCameraId) {
    errorAlert({ message: "Please enter a camera ID for all slots." });
    return false;
  }

  // Check for empty addonServiceIds
  const missingAddonServices = zoneData.some((zone) =>
    zone.slots.some((slot) => !slot.addonServiceIds || slot.addonServiceIds.length === 0)
  );

  console.log()
  if (missingAddonServices) {
    errorAlert({ message: "Please select at least one addon service for all slots." });
    return false;
  }

  // If all validations pass
  return true;
};

export const validateSlotData = (zoneData) => {

  // Check for missing slot numbers
  const missingSlotNumber = zoneData.some((zone) =>
    zone.slots.some((slot) => !slot.slotNumber.trim())
  );
  if (missingSlotNumber) {
    errorAlert({ message: "Please enter a slot name for all slots." });
    return false;
  }

  // Check for missing slot lengths
  const missingSlotLength = zoneData.some((zone) =>
    zone.slots.some((slot) => !slot.slotLength.trim())
  );
  if (missingSlotLength) {
    errorAlert({ message: "Please enter a slot length for all slots." });
    return false;
  }

  // Check for missing slot widths
  const missingSlotWidth = zoneData.some((zone) =>
    zone.slots.some((slot) => !slot.slotWidth.trim())
  );
  if (missingSlotWidth) {
    errorAlert({ message: "Please enter a slot width for all slots." });
    return false;
  }

  // Check for missing camera IDs
  const missingCameraId = zoneData.some((zone) =>
    zone.slots.some((slot) => !slot.cameraId.trim())
  );
  if (missingCameraId) {
    errorAlert({ message: "Please enter a camera ID for all slots." });
    return false;
  }

  // Check for empty addonServiceIds
  const missingAddonServices = zoneData.some((zone) =>
    zone.slots.some((slot) => !slot.addonServiceIds || slot.addonServiceIds.length === 0)
  );

  console.log()
  if (missingAddonServices) {
    errorAlert({ message: "Please select at least one addon service for all slots." });
    return false;
  }

  // If all validations pass
  return true;
};


export const sortByTimestampDesc = (users) => {
  const uUsers = [...users].sort((a, b) => {
    // Convert Firebase timestamps to moments or JavaScript Date
    const dateA = a?.lastMessage?.date
      ? moment(a.lastMessage.date.seconds ? a.lastMessage.date.seconds * 1000 : a.lastMessage.date)
      : 0;
    const dateB = b?.lastMessage?.date
      ? moment(b.lastMessage.date.seconds ? b.lastMessage.date.seconds * 1000 : b.lastMessage.date)
      : 0;


    // If both dates are missing, retain their relative positions
    if (dateA === 0 && dateB === 0) return 0;

    // If one date is missing, push it to the end
    if (dateA === 0) return 1;
    if (dateB === 0) return -1;

    // Compare dates for descending order
    return dateB - dateA;
  });

  return uUsers;
};











