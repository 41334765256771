import { Col, Container, Row, Tab, } from "react-bootstrap"
import { Sidebar } from "../../commonPages/sidebar"
import { VerticalLayout } from "../../components/VerticalLayout"
import { WhitePanelBox } from "../../components/WhitePanelBox"
import { HorizontalLayout } from "../../components/HorizontalLayout"
import { Heading } from "../../components/Heading"
import { SvgIcon } from "../../components/SvgIcon"
import { SearchIcon } from "../../components/Icons/SearchIcon"
import { Notification } from "../../components/Icons/Notification"
import { useContext, useEffect, useState } from "react"
import Typography from "../../components/Typography"
import { InputFields } from "../../components/InputFields"
import { CiSearch } from "react-icons/ci"
import { UsersCard } from "./UsersCard"
import { ChatPanel } from "./ChatPanel"
import { arrayUnion, collection, doc, getDoc, getDocs, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../services/firebase";
import { AuthContext } from "../../states/AuthContext"
import { getUsersProfileForChatListDisplay } from "../../services/NetworkCall"
import { errorAlert } from "../../components/Alert";
import { Timestamp } from "firebase/firestore";
import Header from "../../components/Header"


export const Chat = () => {
    const { profileData } = useContext(AuthContext); // logged user detail
    const [users, setUsers] = useState([]);   // user full detail
    const [chatWithUsers, setChatWithUsers] = useState();
    const [combinedId, setCombinedId] = useState();
    const [messages, setMessages] = useState([]);
    const [receiverId, setReceiverId] = useState();
    const [userKey, setUserKey] = useState();
    const [searchTerm, setSearchTerm] = useState("");
    const [active, setActive] = useState();
    const loggedUserId = profileData?._id;

    const handleCardClick = async (user, loggedUser) => {
        try {
            setUserKey(user); // Set the selected user
            setReceiverId(user.userId); // Set the receiver ID
            // const combinedId = loggedUser?._id > user.userId ? `${loggedUser._id},${user.userId}` : `${user.userId},${loggedUser._id}`;
            const combinedId = `${user?.userId},${loggedUser._id}`;     // userID,adminID
            setCombinedId(combinedId); // Set the combined ID

            const chatDoc = await getDoc(doc(db, "chat_messages", combinedId));

            if (!chatDoc.exists()) {
                // If the chat doesn't exist, create it
                await setDoc(doc(db, "chat_messages", combinedId), { messages: [] });
            } else {
                // Update messages to mark them as seen
                const updatedMessages = chatDoc.data().messages.map((msg) =>
                    msg.receiverId === loggedUser._id ? { ...msg, seen: true } : msg
                );

                // Update the chat document with seen messages
                await updateDoc(doc(db, "chat_messages", combinedId), {
                    messages: updatedMessages,
                });

                // Update the local state to reset unseen count
                setUsers((prevUsers) =>
                    prevUsers.map((u) =>
                        u.userId === user.userId
                            ? {
                                ...u,
                                unseenCount: 0,
                                lastMessage: {
                                    ...u.lastMessage,
                                    seen: true,
                                },
                            }
                            : u
                    )
                );
            }
        } catch (error) {
            errorAlert({ message: error.message });
        }
    };


    const sendMessageHandler = async (msg) => {
        try {
            if (!msg) {
                return;
            }
            const time = Timestamp.now();
            const res = await getDoc(doc(db, "chat_messages", combinedId));
            if (!res.exists()) {
                //create a chat in chats collection
                await setDoc(doc(db, "chat_messages", combinedId), { messages: [] });
            }

            await updateDoc(doc(db, "chat_messages", combinedId), {
                messages: arrayUnion({
                    // id: uuid(),
                    text: msg,
                    senderId: profileData?._id,
                    date: time,
                    receiverId: receiverId,
                }),
            });
        } catch (error) {
            errorAlert({ message: error?.message || "Something went wrong" })
        }
    }

    useEffect(() => {
        if (combinedId) {
            const unSub = onSnapshot(doc(db, "chat_messages", combinedId), (doc) => {
                doc.exists() && setMessages(doc.data().messages); console.log(doc.data())
            });
            return () => {
                unSub();
            };
        }
    }, [combinedId])


    useEffect(() => {
        if (profileData && profileData?._id) {
            const myId = profileData?._id;
            const uSub = onSnapshot(doc(db, "chat_rooms", myId), (doc) => {
                if (doc.exists()) {
                    const users = doc.data().users;
                    console.log("this is initial user data-----------------", users);
                    setChatWithUsers(users);
                }
            });
            return () => {
                uSub();
            };
        }
    }, [profileData]);


    const getChatUserInformation = async (chatWithUsers, searchTerm) => {
        // const userIds = chatWithUsers.map((u) => u.userId);
        // const data = JSON.stringify(userIds);

        try {
            const userInfoPromises = chatWithUsers.map(async (user) => {
                // const combinedId = profileData?._id > user?.userId ? `${profileData._id},${user?.userId}` : `${user?.userId},${profileData._id}`;
                const combinedId = `${user?.userId},${profileData._id}`;
                const chatDoc = await getDoc(doc(db, "chat_messages", combinedId));
                let lastMessage = null;
                let unseenCount = 0;
                let lastTime = "";

                if (chatDoc.exists()) {
                    const messages = chatDoc.data().messages;
                    unseenCount = messages.filter(
                        (msg) =>
                            msg.receiverId === profileData._id &&
                            !msg.seen
                    ).length;

                    lastTime = messages[messages.length - 1]?.date?.nanoseconds
                    lastMessage = messages[messages.length - 1];
                }

                return {
                    ...user,
                    lastMessage,
                    unseenCount,
                    lastTime,
                };
            });

            // Wait for all the user information and chat info to be fetched in parallel
            const userInfo = await Promise.all(userInfoPromises);
            // setUsers(userInfo);

            // // Fetch user profiles for display
            // const res = await getUsersProfileForChatListDisplay(data, searchTerm);

            // if (res.success) {
            //     // Merge user profiles with chat info
            //     const usersData = res.data.map((user) => {
            //         const userInfoMatch = userInfo.find((info) => info.userId === user._id);
            //         return userInfoMatch ? { ...user, ...userInfoMatch } : user;
            //     });

            //     console.log("This is final user data-------------------", usersData);

            //     setUsers(usersData);
            // } else {
            //     errorAlert({ message: res.message });
            // }
            const regex = new RegExp(searchTerm, 'i');
            // Apply the regex search filter here
            const filteredUsers = userInfo.filter(user => {
                return regex.test(user.userName)
            });

            setUsers(filteredUsers);
        } catch (error) {
            errorAlert({ message: error.message });
        }
    };


    useEffect(() => {
        if (chatWithUsers && chatWithUsers.length > 0) {
            getChatUserInformation(chatWithUsers, searchTerm);
        }
    }, [chatWithUsers, searchTerm]);


    // Function to handle search
    const searchHandler = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchTerm(query);
    };

    return (
        <>
            <Container fluid>
                <Row className="m-0">
                    <Col md={3}>
                        <Sidebar />
                    </Col>
                    <Col md={9}>
                        <VerticalLayout gap={3} className='h-100'>
                            <Header
                                heading={"Chats"}
                                subHeading={'Welcome ! Admin Truck'}
                                showNotificationIcon={true}
                            />

                            <Row>
                                <col-md-12>
                                    <Tab.Container id="left-tabs-example" activeKey={userKey} onSelect={(key) => setUserKey(key)}>
                                        <Row>
                                            <Col md={5} sm={12}>
                                                <WhitePanelBox>
                                                    <VerticalLayout gap={3}>
                                                        <HorizontalLayout gap={0} justifyContent={'between'} alignItems={'center'}>
                                                            <HorizontalLayout gap={3}>
                                                                <Typography variant={'h5'}>Messages</Typography>
                                                            </HorizontalLayout>
                                                        </HorizontalLayout>
                                                        <div className="border-top w-100 h-25"></div>
                                                        <InputFields
                                                            startIcon={<CiSearch />}
                                                            placeholder={'Search users...'}
                                                            id={'search'}
                                                            className='rounded-end'
                                                            value={searchTerm}
                                                            onChange={searchHandler}
                                                        />
                                                        <UsersCard
                                                            active={active}
                                                            setActive={setActive}
                                                            handleCardClick={handleCardClick}
                                                            profileData={profileData}
                                                            users={users}
                                                        />
                                                    </VerticalLayout>
                                                </WhitePanelBox>
                                            </Col>

                                            <Col md={7} sm={12}>
                                                <ChatPanel
                                                    users={users}
                                                    setActive={setActive}
                                                    loggedUserId={loggedUserId}
                                                    messages={messages}
                                                    sendMessageHandler={sendMessageHandler}
                                                    setCombinedId={setCombinedId}
                                                    setMessages={setMessages}
                                                    setUserKey={setUserKey}
                                                />
                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                </col-md-12>
                            </Row>
                        </VerticalLayout>
                    </Col>
                </Row>
            </Container >
        </>
    )
}