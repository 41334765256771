import { Col, Container, Row } from "react-bootstrap"
import { Heading } from "../../components/Heading"
import { Sidebar } from "../../commonPages/sidebar"
import { HorizontalLayout } from "../../components/HorizontalLayout"
import { VerticalLayout } from "../../components/VerticalLayout"
import Typography from "../../components/Typography"
import { useState, useEffect } from "react"
import { errorAlert } from "../../components/Alert"
import { Loader } from "../../components/Loader"
import { getAddonServicesAPI, getUsers } from "../../services/NetworkCall"
import { UserListTable } from "../userAccount/userList/UserListTable"
import { WhitePanelBox } from "../../components/WhitePanelBox"
import { SvgIcon } from "../../components/SvgIcon"
import { SearchIcon } from "../../components/Icons/SearchIcon"
import { Notification } from "../../components/Icons/Notification"
import { SharedButton } from "../../components/SharedButton"
import { AddIcon } from "../../components/Icons/AddIcon"
import { LeftArrow } from "../../components/Icons/LeftArrow"
import { AddOnServiceList } from "./addOnServiceList/AddOnServiceList"
import { useNavigate } from "react-router-dom"
import Header from "../../components/Header"

export const AddOnService = () => {
    const [loading, setLoading] = useState(false);
    const [serviceData, setServiceData] = useState([]);
    const [pagination, setPagination] = useState({ totalPages: 1, page: 1 })
    const navigate = useNavigate(); // Create a navigate instance

    const handleNavigate = () => {
        navigate('/add-service'); // Replace with your desired path
    };

    const getUsersData = async (page = 1) => {
        setLoading(true);
        const res = await getAddonServicesAPI(page);
        if (res.success) {
            let pre = [];
            const data = res.data;
            pre = data.map((e) => ({
                'id': e?._id,
                'serviceName': e?.name,
                'dailyPrice': e?.isFree ? 'free' : e?.dayPrice?.price,
                'weeklyPrice': e?.isFree ? 'free' : e?.weeklyPrice?.price,
                'monthlyPrice': e?.isFree ? 'free' : e?.monthlyPrice?.price,
                'subscriptionPrice': e?.isFree ? 'free' : e?.monthlySubscriptionPrice?.price,
                'serviceSubtitle': e?.subtitle,
                'info': e?.description,
                'imageUrl': e?.image,
                'serviceType': e?.serviceType
            }));
            setServiceData(pre);
        } else {
            errorAlert({ message: res.message });
        }
        setLoading(false);
    }

    useEffect(() => {
        getUsersData(pagination.page);
    },
        [pagination.page]);

    const pageHandler = (page) => {
        setPagination(prevPagination => ({
            ...prevPagination,
            page: page
        }));
        getUsersData(page);
    }
    return <>
        <Loader show={loading} />
        <Container fluid>
            <Row className="g-3">
                <Col md={3}>
                    <Sidebar />
                </Col>
                <Col md={9}>
                    <VerticalLayout gap={3}>
                        <Header
                            heading={'Add On Service'}
                            subHeading={'Welcome ! Admin Truck'}
                            showNotificationIcon={true}
                        />
                        <HorizontalLayout justifyContent={'end'} alignItems={'center'}>
                            <SharedButton className={'rounded-5 bg-white'} variant={'white'} size={'md'} type={'button'} label={'Add Service'} icon={<AddIcon color={'#000'} />} onClick={handleNavigate} />
                        </HorizontalLayout>
                        <AddOnServiceList serviceData={serviceData} pagination={pagination} pageHandler={pageHandler} />
                    </VerticalLayout>
                </Col>
            </Row>
        </Container>
    </>
}