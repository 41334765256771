import { Col, Container, Row, Form } from "react-bootstrap";
import { Loader } from "../../../components/Loader";
import { VerticalLayout } from "../../../components/VerticalLayout";
import { WhitePanelBox } from "../../../components/WhitePanelBox";
import { HorizontalLayout } from "../../../components/HorizontalLayout";
import { Heading } from "../../../components/Heading";
import { SvgIcon } from "../../../components/SvgIcon";
import { SearchIcon } from "../../../components/Icons/SearchIcon";
import { SharedButton } from "../../../components/SharedButton";
import { LeftArrow } from "../../../components/Icons/LeftArrow";
import { useState } from "react";
import { Sidebar } from "../../../commonPages/sidebar";
import { Notification } from "../../../components/Icons/Notification";
import { InputFields } from "../../../components/InputFields";
import { errorAlert, successAlert } from "../../../components/Alert";
import { addCamera } from "../../../services/NetworkCall"
import { useNavigate } from "react-router-dom";
import Header from "../../../components/Header";

export const AddCamera = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [cameraData, setCameraData] = useState({ cameraName: "", cameraUrl: "" });
    const [error, setError] = useState({ cameraName: "", cameraUrl: "" });

    const inputHandler = (e) => {
        const { name, value } = e.target;
        setCameraData({ ...cameraData, [name]: value });
        setError({ ...error, [name]: "" });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        let isValid = 1;
        let newError = { cameraName: "", cameraUrl: "" }; // Create a temporary error object

        if (!cameraData.cameraName) {
            newError.cameraName = "Camera name is required *";
            isValid = 0;
        }

        if (!cameraData.cameraUrl) {
            newError.cameraUrl = "Camera URL is required *";
            isValid = 0;
        }

        setError(newError); // Update the error state once for both fields

        if (isValid === 1) {
            setLoading(true);

            const res = await addCamera(cameraData);

            if (res.success) {
                successAlert({ message: res.message })
                navigate("/camera-list");
            } else {
                errorAlert({ message: res.message });
            }
            setLoading(false);
        }
    };

    return (
        <>
            <Loader show={loading} />
            <Container fluid>
                <Row className="g-3">
                    <Col md={3}>
                        <Sidebar />
                    </Col>
                    <Col md={9}>
                        <VerticalLayout gap={3}>
                            <Header
                                heading={"Add Camera"}
                                subHeading={"Welcome ! Admin Truck"}
                                showNotificationIcon={true}

                            />
                            <HorizontalLayout justifyContent={"between"} alignItems={"center"}>
                                <SharedButton
                                    label={"Back"}
                                    size={"sm"}
                                    variant={"light"}
                                    className={"rounded-pill"}
                                    icon={<LeftArrow />}
                                    onClick={() => window.history.back()}
                                />
                            </HorizontalLayout>

                            <WhitePanelBox>
                                <Form onSubmit={handleFormSubmit} className='p-3'>
                                    <Row className="g-3">
                                        <Col md={6} lg={6} sm={12}>
                                            <InputFields
                                                type={"text"}
                                                name={"cameraName"}
                                                label={"Camera Name"}
                                                id={"cameraName"}
                                                placeholder={"Enter Camera Name"}
                                                size={"md"}
                                                className="rounded-2 bg-white"
                                                onChange={inputHandler}
                                                feedback={error.cameraName}
                                                isvalid={!!error.cameraName}
                                                value={cameraData.cameraName}
                                            />
                                        </Col>
                                        <Col md={6} lg={6} sm={12}>
                                            <InputFields
                                                type={"text"}
                                                name={"cameraUrl"}
                                                label={"Camera URL"}
                                                id={"cameraUrl"}
                                                placeholder={"Enter Camera URL"}
                                                size={"md"}
                                                className="rounded-2 bg-white"
                                                onChange={inputHandler}
                                                feedback={error.cameraUrl}
                                                isvalid={!!error.cameraUrl}
                                                value={cameraData.cameraUrl}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col md={12}>
                                            <HorizontalLayout justifyContent={"end"} className="mt-4">
                                                <SharedButton
                                                    type="submit"
                                                    variant={"primary"}
                                                    className={"px-5"}
                                                    size={"md"}
                                                    label={"Submit"}
                                                />
                                            </HorizontalLayout>
                                        </Col>
                                    </Row>
                                </Form>
                            </WhitePanelBox>
                        </VerticalLayout>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
