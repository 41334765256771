import React from 'react'

export const WhitePanelBox = ({ children, className, onClick }) => {

    const styles = {
        background: '#fff',
        padding: '1rem',
        borderRadius: '1rem',
        height:'100%',
    }

    return (
        <>
            <div className={className} style={styles} onClick={onClick}>
              {children}
            </div>
        </>
    )
}
