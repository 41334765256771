import React from 'react'
import { WhitePanelBox } from '../../../components/WhitePanelBox';
import { Table } from 'react-bootstrap';
import { TablePagination } from '../../../components/TablePagination';
import { HorizontalLayout } from '../../../components/HorizontalLayout';
import { SharedButton } from '../../../components/SharedButton';
import { ViewIcon } from '../../../components/Icons/ViewIcon';
import { useNavigate } from 'react-router-dom';

export const UserListTable = ({ usersData, pagination, pageHandler }) => {
    const navigate = useNavigate();

    const handleViewClick = (user) => {
        navigate("/user-detail", { state: { data: user } })
    }

    return (
        <>
            <WhitePanelBox>
                <Table hover responsive>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>LICENSE PLATE</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {usersData && usersData.map((user, index) => (
                            <tr key={index}>
                                <td> {user?.userName}</td>
                                <td>{user?.email}</td>
                                <td>{user?.phoneNumber}</td>
                                <td>{user?.licensePlate}</td>
                                <td><SharedButton icon={<ViewIcon />} iconPosition='left' label={'View'} variant={'light'} className={'bg-white'} onClick={() => handleViewClick(user)} /></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </WhitePanelBox>
            <HorizontalLayout justifyContent={'end'} alignItems={'center'}>
                <TablePagination pagination={pagination} pageHandler={pageHandler} />
            </HorizontalLayout>

        </>
    );
};
