import React from 'react'

export const CameraFeedIcon = () => {
    return (
        <>

            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1418_5583)">
                    <path d="M8.50003 11.3662H8.27466C8.04929 11.4789 7.82396 11.5916 7.7113 11.8169C7.59863 12.0423 7.59863 12.2676 7.59863 12.493C7.7113 12.831 8.04933 13.169 8.50003 13.169H8.7254C9.1761 13.0564 9.51413 12.493 9.28876 12.0423C9.28876 11.5915 8.83806 11.3662 8.50003 11.3662Z" fill="black" />
                    <path d="M23.9366 15.0845H21.6831C21.3451 15.0845 21.1197 15.3099 21.1197 15.6478V18.4647H16.6127V16.2112C16.6127 16.0985 16.6127 16.0985 16.6127 15.9858L17.8521 15.6478C17.9648 15.6478 18.0775 15.5351 18.1902 15.4224C18.3028 15.3098 18.3028 15.0844 18.1902 14.9717L17.7395 13.2816L23.7113 11.5915C24.0493 11.4788 24.162 11.1408 24.0493 10.9154L21.2324 1.22527C21.2324 1.1126 21.1198 0.999895 21.0071 0.887232C20.8944 0.774569 20.669 0.774569 20.5564 0.887232L0.950698 6.52112C0.725327 6.52112 0.5 6.74649 0.5 6.97182C0.5 7.19715 0.612663 7.42252 0.838035 7.53518L3.42961 8.54929L3.65498 9.33802C1.85214 9.90138 0.838081 11.8169 1.40144 13.6197C1.85214 15.1972 3.20428 16.2113 4.7817 16.2113C5.11974 16.2113 5.34507 16.2113 5.6831 16.0986V16.324C5.79576 16.5494 6.02114 16.7747 6.24646 16.7747H6.35913L12.331 15.0846L12.7817 16.662C12.7817 16.7747 12.8943 16.8874 13.007 17.0001C13.1197 17.0001 13.2324 17.1127 13.2324 17.1127H13.3451L15.3732 16.5494V19.2536C15.3732 19.5917 15.5986 19.817 15.9366 19.817H21.1197V22.6339C21.1197 22.9719 21.3451 23.1972 21.6831 23.1972H23.9366C24.2747 23.1972 24.5 22.9719 24.5 22.6339V15.8732C24.5 15.3099 24.2747 15.0845 23.9366 15.0845ZM2.8662 7.08448L20.4437 2.01405L21.1197 4.15488L6.24646 8.43658L4.2183 7.64784C4.2183 7.53518 3.99292 7.53518 3.88026 7.53518L2.8662 7.08448ZM2.4155 13.2817C2.07747 12.0423 2.75354 10.8028 3.99297 10.3521L5.34507 14.8592C4.10563 15.1971 2.75354 14.5211 2.4155 13.2817ZM4.7817 8.99999L6.02114 9.45068C6.1338 9.45068 6.24651 9.45068 6.35917 9.45068L21.3451 5.16898L22.9225 10.5775L6.5845 15.3099L4.7817 8.99999ZM13.4577 14.5211L16.7253 13.6197L17.0634 14.7465L13.7958 15.6479L13.4577 14.5211ZM22.2465 18.4648H23.3733V19.5916H22.2465V18.4648ZM22.2465 16.2113H23.3733V17.338H22.2465V16.2113ZM23.3732 21.8451H22.2465V20.7183H23.3732V21.8451Z" fill="black" />
                    <path d="M17.8526 8.66183L10.1906 10.9154C9.9652 11.028 9.85254 11.3661 9.85254 11.7041C9.9652 11.9295 10.1906 12.1548 10.4159 12.1548H10.5286L18.1905 9.90126C18.5286 9.7886 18.6412 9.45056 18.5286 9.22519C18.4159 8.99982 18.1906 8.54916 17.8526 8.66183Z" fill="black" />
                </g>
                <defs>
                    <clipPath id="clip0_1418_5583">
                        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                    </clipPath>
                </defs>
            </svg>

        </>
    )
}
