import React from 'react'
import { Stack } from 'react-bootstrap'
export const VerticalLayout = ({ gap, justifyContent, alignItems, children , myClass }) => {
    return (
        <>
            <Stack direction="vertical" gap={gap} className={`justify-content-${justifyContent} align-items-${alignItems} ${myClass}`}>
                {children}
            </Stack>
        </>
    )
}
