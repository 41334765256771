import { Col, Container, Image, Row, Table } from "react-bootstrap"
import { Heading } from "../../components/Heading"
import { Sidebar } from "../../commonPages/sidebar"
import { WhitePanelBox } from "../../components/WhitePanelBox"
import { Notification } from "../../components/Icons/Notification"
import { SvgIcon } from "../../components/SvgIcon"
import { SearchIcon } from "../../components/Icons/SearchIcon"
import { HorizontalLayout } from "../../components/HorizontalLayout"
import { VerticalLayout } from "../../components/VerticalLayout"
import Typography from "../../components/Typography"
import { useState, useEffect } from "react"
import { errorAlert } from "../../components/Alert"
import { Loader } from "../../components/Loader"
import { deleteAdminUser, getAdminUsersAPI } from "../../services/NetworkCall"
import { SharedButton } from "../../components/SharedButton"
import { UserAccountIcon } from "../../components/Icons/UserAccountIcon"
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { EditIcon } from "../../components/Icons/EditIcon"
import { Delete } from "../../components/Icons/Delete"
import Header from "../../components/Header"

export const AdminUserList = () => {
    const [loading, setLoading] = useState(false);
    const [adminUsersData, setAdminUsersData] = useState([]);
    const navigate = useNavigate();

    const getUsersData = async () => {
        setLoading(true);
        const res = await getAdminUsersAPI();
        if (res.success) {
            setAdminUsersData(res.data);
            // successAlert(res.message);
        } else {
            errorAlert({ message: res.message });
        }
        setLoading(false);
    }

    useEffect(() => {
        getUsersData();
    }, []);


    const handleAddUser = () => {
        navigate('/add-user');
    }

    const handleDeleteClick = (id) => {
        if (!id) {
            errorAlert("Something wrong");
            return;
        }
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: `Yes, delete it!`
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await deleteAdminUser(id);
                if (res.success) {
                    setLoading(false);
                    Swal.fire({
                        title: "DELETE!",
                        text: `Request has been deleted.`,
                        icon: "success"
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            getUsersData();
                        }
                    });
                }
            }
        });
    };


    const handleAddAdminUserClick = (id) => {
        navigate("/add-admin-user")
    }

    const handleEditClick = (preData) => {
        navigate("/edit-admin-user", { state: { data: preData } });
    }

    return <>
        <Loader show={loading} />
        <Container fluid>
            <Row className="g-3 m-0">
                <Col md={3}>
                    <Sidebar />
                </Col>
                <Col md={9}>
                    <VerticalLayout gap={3}>
                        <Header
                            heading={'Admin Users'}
                            subHeading={'Welcome ! Admin Truck'}
                            showNotificationIcon={true}
                        />
                        <HorizontalLayout justifyContent={'between'} alignItems={'center'}>
                            <Typography variant={'h6'} className={'fw-medium'} color={'#000'}>Users List</Typography>
                            <SharedButton label={'Add User'} variant={'white'} className={' bg-white rounded-pill'} icon={<UserAccountIcon />} onClick={handleAddAdminUserClick} />
                        </HorizontalLayout>
                        <WhitePanelBox>
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th>USER NAME</th>
                                        <th>EMAIL</th>
                                        <th>PHONE</th>
                                        <th>ACTION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {adminUsersData && adminUsersData.map((user, index) => (
                                        <tr key={index}>
                                            <td>{user?.userName}</td>
                                            <td>{user?.email}</td>
                                            <td>{user?.phoneNumber}</td>
                                            <td>
                                                <SharedButton
                                                    icon={<EditIcon color={'black'} />}
                                                    variant={'transparent'}
                                                    className={'border-0'}
                                                    onClick={() => handleEditClick(user)}
                                                />
                                                <SharedButton
                                                    icon={<Delete color={'red'} />}
                                                    variant={'transparent'}
                                                    className={'border-0'}
                                                    onClick={() => handleDeleteClick(user?._id)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </WhitePanelBox>
                    </VerticalLayout>
                </Col>
            </Row>
        </Container>
    </>
}