import React from 'react'
import { Pagination } from 'react-bootstrap'
import { WhitePanelBox } from './WhitePanelBox'

export const TablePagination = ({ pagination, pageHandler }) => {
    return (
        <>
            {pagination &&
                <Pagination className='mb-0 p-2 bg-white text-center d-flex justify-content-center align-items-center rounded-5 gap-2'>
                    <Pagination.Prev
                        onClick={pagination.page > 1 ? () => pageHandler(pagination.page - 1) : null}
                    />
                    {Array.from({ length: pagination.totalPages }, (_, index) => (
                        <Pagination.Item
                            key={index}
                            active={pagination.page === index + 1}
                            onClick={() => pageHandler(index + 1)}
                            className='cursor-pointer'
                        >
                            {index + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next
                        onClick={pagination.page < pagination.totalPages ? () => pageHandler(pagination.page + 1) : null}
                    />
                </Pagination>
            }
        </>
    )
}
