import React, { useState } from 'react'
import { WhitePanelBox } from '../../../components/WhitePanelBox'
import { HorizontalLayout } from '../../../components/HorizontalLayout'
import { SharedButton } from '../../../components/SharedButton'
import { SlotIcon } from '../../../components/Icons/SlotIcon'
import { Row, Col, Form } from 'react-bootstrap'
import { InputFields } from '../../../components/InputFields'
import { VerticalLayout } from '../../../components/VerticalLayout'
import { Delete } from '../../../components/Icons/Delete'
import { UploadImg } from '../../../components/Icons/UploadImg'
import { DropdownSelect } from '../../../components/DropdownSelect'
import { UploadFile } from '../../../components/UploadFile'
import Select from '../../../components/Select'

import { optionAddOnServiceType, optionIsPaid } from '../../../helper/Helper'
import { NewInputField } from '../../../components/NewInputField'
import { createAddonServiceAPI } from '../../../services/NetworkCall'
import { errorAlert, successAlert } from '../../../components/Alert'
import { useNavigate } from 'react-router-dom'

export const AddOnServiceForm = ({ setLoading }) => {
    const navigate = useNavigate();
    const [inData, setInData] = useState({ "image": "", "serviceType": "", "serviceName": "", "isPaid": "", "dailyPrice": "", "weeklyPrice": "", "monthlyPrice": "", "subscriptionPrice": "", "serviceSubtitle": "", "infoDescription": "" });
    const [error, setError] = useState({ "image": "", "serviceType": "", "serviceName": "", "isPaid": "", "dailyPrice": "", "weeklyPrice": "", "monthlyPrice": "", "subscriptionPrice": "", "serviceSubtitle": "", "infoDescription": "" });
    const imageHandler = (data) => {
        const { name, value } = data;
        setInData((pre) => ({ ...pre, [name]: value }));
        setError((pre) => ({ ...pre, [name]: "" }));
    }

    const inputHandler = (e) => {
        const { name, value, type } = e.target;

        if (type === 'number') {
            const regex = /^\d*(\.\d{0,2})?$/;
            if (value.includes('.') && value.split('.')[1].length > 2) {

            } else {
                setInData((pre) => ({ ...pre, [name]: value }));
                setError((pre) => ({ ...pre, [name]: "" }));
            }
        } else {
            setInData((pre) => ({ ...pre, [name]: value }));
            setError((pre) => ({ ...pre, [name]: "" }));
        }

    }

    const submitHandler = async (e) => {

        e.preventDefault();
        let isValid = true;
        const { serviceType, serviceName, isPaid, dailyPrice, weeklyPrice, monthlyPrice, subscriptionPrice, serviceSubtitle, infoDescription, image } = inData;
        if (!serviceName) { setError((pre) => ({ ...pre, "serviceName": 'Required' })); isValid = false; }
        if (!isPaid) { setError((pre) => ({ ...pre, "isPaid": 'Required' })); isValid = false; }
        if (!serviceType) { setError((pre) => ({ ...pre, "serviceType": "Required" })); isValid = false; }

        if (isPaid == 2) {
            if (!dailyPrice) { setError((pre) => ({ ...pre, "dailyPrice": 'Required' })); isValid = false; }
            if (!weeklyPrice) { setError((pre) => ({ ...pre, "weeklyPrice": 'Required' })); isValid = false; }
            if (!monthlyPrice) { setError((pre) => ({ ...pre, "monthlyPrice": 'Required' })); isValid = false; }
            if (!subscriptionPrice) { setError((pre) => ({ ...pre, "subscriptionPrice": 'Required' })); isValid = false; }
        }

        if (!serviceSubtitle) { setError((pre) => ({ ...pre, "serviceSubtitle": 'Required' })); isValid = false; }
        if (!infoDescription) { setError((pre) => ({ ...pre, "infoDescription": 'Required' })); isValid = false; }
        if (!image) { setError((pre) => ({ ...pre, "image": 'Required' })); isValid = false; }

        if (isValid) {
            setLoading(true);
            const data = new FormData();
            data.append('image', inData.image);
            data.append('name', inData.serviceName);
            data.append('isFree', inData.isPaid == 2 ? false : true);
            data.append('dayPrice', inData.dailyPrice);
            data.append('weeklyPrice', inData.weeklyPrice);
            data.append('monthlyPrice', inData.monthlyPrice);
            data.append('monthlySubscriptionPrice', inData.subscriptionPrice);
            data.append('subtitle', inData.serviceSubtitle);
            data.append('description', inData.infoDescription);
            data.append('serviceType', inData.serviceType);
            const res = await createAddonServiceAPI(data);
            if (res.success) {
                let clear = { ...inData };
                clear.image = "";
                clear.serviceName = "";
                clear.isPaid = "";
                clear.dailyPrice = "";
                clear.weeklyPrice = "";
                clear.monthlyPrice = "";
                clear.subscriptionPrice = "";
                clear.serviceSubtitle = "";
                clear.infoDescription = "";
                clear.serviceType = "";
                setInData(clear);
                setLoading(false);
                successAlert({ message: res.message });
                navigate("/add-on-service");
            } else {
                setLoading(false);
                errorAlert({ message: res.message });
            }
            setLoading(false);
        }
    }

    // console.log("this is in data-----------------------", inData);

    return (
        <>
            <WhitePanelBox>
                    <Form className={'p-2'}>
                        <Row className='g-3'>
                            <Col md={12} sm={12}>
                                <UploadFile
                                    FormLabel="Upload Image"
                                    name="image"
                                    controlId="formProfilePic"
                                    onChange={imageHandler}
                                    error={error.image}
                                />
                            </Col>
                        
                            <Col md={4}>
                                <NewInputField FormLabel={'Service Name'} FormType={'text'} name={'serviceName'} value={inData.serviceName} className={'rounded-2 bg-white'} error={error.serviceName} onChange={inputHandler} />
                            </Col>

                            <Col md={4}>
                                <Select myClassName={'rounded-2 bg-white'} Array={optionIsPaid} required={true} name="isPaid" FormLabel={"Paid /Free"} error={error.isPaid} value={inData.isPaid} onChange={inputHandler} />
                            </Col>
                            
                            <Col md={4}>
                                {inData.isPaid == 2 ?
                                        <NewInputField FormLabel={'Daily Price'} placeholder={'price'} FormType={'number'} value={inData.dailyPrice} name={'dailyPrice'} error={error.dailyPrice} min={'0'} className={'rounded-2 bg-white'} onChange={inputHandler} />
                                    :
                                        <NewInputField FormLabel={'Daily Price'} disabled={true} placeholder={'price'} FormType={'number'} value={''} name={'dailyPrice'} error={error.dailyPrice} min={'0'} className={'rounded-2 bg-white'} onChange={inputHandler} />
                                }
                            </Col>

                            <Col md={4}>
                                {inData.isPaid == 2 ?
                                    <NewInputField FormLabel={'Weekly Price'} placeholder={'price'} disabled={!inData.isPaid} FormType={'number'} name={'weeklyPrice'} value={inData.weeklyPrice} error={error.weeklyPrice} min={'0'} className={'rounded-2 bg-white'} onChange={inputHandler} />
                                    :
                                    <NewInputField FormLabel={'Weekly Price'} disabled={true} FormType={'number'} value={''} className={'rounded-2 bg-white'} />
                                }
                            </Col>

                            <Col md={4}>
                                {inData.isPaid == 2 ?
                                    <NewInputField FormLabel={'Monthly Price'} placeholder={'price'} FormType={'number'} name={'monthlyPrice'}
                                        value={inData.monthlyPrice} error={error.monthlyPrice} min={'0'} className={'rounded-2 bg-white'} onChange={inputHandler} />
                                    :
                                    <NewInputField FormLabel={'Monthly Price'} disabled={true} FormType={'number'} value={''} className={'rounded-2 bg-white'} />
                                }
                            </Col>

                            <Col md={4}>
                                {inData.isPaid == 2 ?
                                    <NewInputField FormLabel={'Monthly Subscription Price'} placeholder={'price'} FormType={'number'} name={'subscriptionPrice'} value={inData.subscriptionPrice} min={'0'} error={error.subscriptionPrice} className={'rounded-2 bg-white'} onChange={inputHandler} />
                                    :
                                    <NewInputField FormLabel={'Monthly Subscription Price'} disabled={true} FormType={'number'} value={''} className={'rounded-2 bg-white'} />
                                }
                            </Col>

                            <Col md={4}>
                                <Select myClassName={'rounded-2 bg-white'} Array={optionAddOnServiceType} required={true} name="serviceType" FormLabel={"Service Type"} error={error.serviceType} value={inData.serviceType} onChange={inputHandler} />
                            </Col>

                            <Col md={4}>
                                <NewInputField FormLabel={'Service Subtitle'} placeholder={'service subtitle'} FormType={'text'} name={'serviceSubtitle'} value={inData.serviceSubtitle} error={error.serviceSubtitle} className={'rounded-2 bg-white'} onChange={inputHandler} />
                            </Col>
                            <Col md={4}>
                                <NewInputField FormLabel={'Info Description'} placeholder={'info description'} FormType={'text'} name={'infoDescription'} value={inData.infoDescription} error={error.infoDescription} className={'rounded-2 bg-white'} onChange={inputHandler} />
                            </Col>

                            <Col md={12}>
                                <SharedButton type={'button'} label={'Create Service'} size={'md'} variant={'primary'} className={'rounded-2 px-5 py-2'} onClick={submitHandler} />
                            </Col>

                        </Row>
                    </Form>
            </WhitePanelBox>
        </>
    )
}
