import React from 'react'

export const Filter = () => {
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 6.25C7.58579 6.25 7.25 6.58578 7.25 7C7.25 7.41421 7.58579 7.75 8 7.75L16 7.75C16.4142 7.75 16.75 7.41421 16.75 7C16.75 6.58579 16.4142 6.25 16 6.25L8 6.25Z" fill="#2D264B" />
                <path fillRule="evenodd" clipRule="evenodd" d="M6.47246 2.25C4.16114 2.25 2.25 4.07558 2.25 6.37307C2.25 7.53648 2.75289 8.64108 3.62728 9.41967L6.87987 12.3159C7.75613 13.0961 8.25 14.1925 8.25 15.336V19.5134C8.25 21.3745 10.3855 22.367 11.859 21.3261L13.8002 19.9547C14.7061 19.3147 15.25 18.2855 15.25 17.1817V15.4521C15.25 14.2522 15.7936 13.107 16.7465 12.3238L20.2668 9.43032C21.2037 8.66027 21.75 7.52281 21.75 6.318C21.75 4.05092 19.8642 2.25 17.5842 2.25H6.47246ZM3.75 6.37307C3.75 4.94477 4.94821 3.75 6.47246 3.75H17.5842C19.0772 3.75 20.25 4.92011 20.25 6.318C20.25 7.06517 19.9116 7.78069 19.3144 8.27151L15.7941 11.165C14.5015 12.2274 13.75 13.7946 13.75 15.4521V17.1817C13.75 17.7872 13.4519 18.3642 12.9347 18.7296L10.9935 20.101C10.4475 20.4867 9.75 20.0779 9.75 19.5134V15.336C9.75 13.7567 9.0674 12.2552 7.87738 11.1956L4.62479 8.29941C4.06416 7.80021 3.75 7.10064 3.75 6.37307Z" fill="#2D264B" />
            </svg>
        </>
    )
}
