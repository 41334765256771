import React, { useState } from 'react'
import { Loader } from '../../../components/Loader'
import { Col, Container, Row } from 'react-bootstrap'
import { Sidebar } from '../../../commonPages/sidebar'
import { VerticalLayout } from '../../../components/VerticalLayout'
import Header from '../../../components/Header'

export const UserRegistrationReport = () => {
      const [loading, setLoading] = useState(false);
    
  return (
     <>
     <Loader show={loading} />
         <Container fluid>
           <Row className="g-3">
             <Col md={3}>
               <Sidebar />
             </Col>
             <Col md={9}>
               <VerticalLayout gap={3}>
                 <Header
                   heading={"Dashboard"}
                   subHeading={"Welcome ! Admin Truck"}
                   showNotificationIcon={true}
                   showButton={true}
                   buttonLabel={"Create Location"}
                //    onButtonClick={createLocationNavigate}
                 />
                 </VerticalLayout>
                 </Col>
                 </Row>
                 </Container>
     </>
  )
}
