import React from 'react'

export const CreateLocationIcon = () => {
    return (
        <>

            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1418_2900)">
                    <path d="M3.67676 20.6719V22.4766C3.67676 23.1238 4.20143 23.6484 4.84863 23.6484H20.1513C20.7985 23.6484 21.3232 23.1238 21.3232 22.4766V1.52344C21.3232 0.876234 20.7985 0.351562 20.1513 0.351562H4.84863C4.20143 0.351562 3.67676 0.876234 3.67676 1.52344V19.0312" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7.48477 1.92969H5.79234C5.49652 1.92969 5.25586 2.17039 5.25586 2.46622V21.5328C5.25586 21.8286 5.49656 22.0693 5.79234 22.0693H19.2085C19.5043 22.0693 19.745 21.8286 19.745 21.5328V2.46622C19.745 2.17034 19.5043 1.92969 19.2085 1.92969H9.12539" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M18.3316 16.2434L17.6388 15.168C17.5525 15.034 17.4041 14.9531 17.2447 14.9531H15.875V17.7907C16.4215 17.8976 16.8486 18.3375 16.9364 18.8906H17.9375C18.1964 18.8906 18.4062 18.6808 18.4062 18.4219V16.4973C18.4062 16.4072 18.3803 16.3191 18.3316 16.2434Z" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11.1081 12.097H9.95215C9.82273 12.097 9.71777 11.9921 9.71777 11.8627V3.56641C9.71777 3.43698 9.82273 3.33203 9.95215 3.33203H13.0321C14.5346 3.33203 15.7883 4.52514 15.8112 6.02739C15.8345 7.55645 14.5975 8.80778 13.0736 8.80778H11.3425V11.8626C11.3425 11.9921 11.2376 12.097 11.1081 12.097ZM11.3425 7.18309H13.0736C13.6875 7.18309 14.1868 6.68373 14.1868 6.06995C14.1868 5.45612 13.6874 4.95677 13.0736 4.95677H11.3425V7.18309Z" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M12.4531 13.4531H7.29688C7.03798 13.4531 6.82812 13.663 6.82812 13.9219V18.4219C6.82812 18.6808 7.03798 18.8906 7.29688 18.8906H8.15736C8.25852 18.2531 8.81056 17.7656 9.47656 17.7656C10.1426 17.7656 10.6946 18.2531 10.7958 18.8906H14.298C14.3991 18.2531 14.9512 17.7656 15.6172 17.7656C15.7054 17.7656 15.7916 17.7744 15.875 17.7908V13.9219C15.875 13.663 15.6651 13.4531 15.4062 13.4531H14.0938" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9.47656 20.4375C10.2144 20.4375 10.8125 19.8394 10.8125 19.1016C10.8125 18.3637 10.2144 17.7656 9.47656 17.7656C8.73874 17.7656 8.14062 18.3637 8.14062 19.1016C8.14062 19.8394 8.73874 20.4375 9.47656 20.4375Z" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M15.6172 20.4375C16.355 20.4375 16.9531 19.8394 16.9531 19.1016C16.9531 18.3637 16.355 17.7656 15.6172 17.7656C14.8794 17.7656 14.2812 18.3637 14.2812 19.1016C14.2812 19.8394 14.8794 20.4375 15.6172 20.4375Z" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9.47656 19.2188C9.54128 19.2188 9.59375 19.1663 9.59375 19.1016C9.59375 19.0368 9.54128 18.9844 9.47656 18.9844C9.41184 18.9844 9.35938 19.0368 9.35938 19.1016C9.35938 19.1663 9.41184 19.2188 9.47656 19.2188Z" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M15.6172 19.2188C15.6819 19.2188 15.7344 19.1663 15.7344 19.1016C15.7344 19.0368 15.6819 18.9844 15.6172 18.9844C15.5525 18.9844 15.5 19.0368 15.5 19.1016C15.5 19.1663 15.5525 19.2188 15.6172 19.2188Z" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M18.3155 16.4492H15.875" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_1418_2900">
                        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                    </clipPath>
                </defs>
            </svg>


        </>
    )
}
