import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { WhitePanelBox } from './WhitePanelBox';
import { HorizontalLayout } from './HorizontalLayout';
import { Image } from 'react-bootstrap';
import { VerticalLayout } from './VerticalLayout';
import Typography from './Typography';

export const SubUserDetailCard = ({ className, userName, email, phoneNumber, profileImage, userId }) => {
    const navigate = useNavigate();
    const handleCardClick = () => {
        navigate('/sub-user-detail', { state: { data: { userId, userName, email, phoneNumber, profileImage } } })
    };

    return (
        < >
            <WhitePanelBox className={`cursor-pointer ${className}`}   onClick={handleCardClick}>
                <HorizontalLayout gap={3} overflow={'scroll scrollbar-w-none'}>
                    <Image src={profileImage || '../../assets/images/Image.png'} alt='' fluid className='border-dashed-2 rounded-3' width={100} height={100}/>
                    <VerticalLayout justifyContent={'center'} alignItems={'start'}>
                        <Typography variant={'h4'} className={'mb-0'}>{userName}</Typography>
                        <Typography variant={'p'} color={'#7d7d7d'} className={'mb-0'}>{email}</Typography>
                        <Typography variant={'p'} color={'#0a0a0a'} className={'mb-0'}>{phoneNumber}</Typography>
                    </VerticalLayout>
                </HorizontalLayout>
            </WhitePanelBox>
        </>
    );
};
