import { Col, Container, Form, Image, Row } from "react-bootstrap";
import { Loader } from "../../components/Loader";
import { Sidebar } from "../../commonPages/sidebar";
import { VerticalLayout } from "../../components/VerticalLayout";
import { WhitePanelBox } from "../../components/WhitePanelBox";
import { HorizontalLayout } from "../../components/HorizontalLayout";
import { SharedButton } from "../../components/SharedButton";
import { LeftArrow } from "../../components/Icons/LeftArrow";
import { InputFields } from "../../components/InputFields";
import { useState } from "react";
import { addAdminUserAPI } from "../../services/NetworkCall";
import { errorAlert, successAlert } from "../../components/Alert";
import { emailPattern } from "../../helper/Helper";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";

export const AddAdminUser = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [inData, setInData] = useState({
        userName: "",
        email: "",
        password: "",
        phoneNumber: "",
        confirmPassword: ""
    });
    const [error, setError] = useState({
        userName: "",
        email: "",
        password: "",
        phoneNumber: "",
        confirmPassword: ""
    });

    const inputHandler = (e) => {
        const { name, value } = e.target;
        setInData((prev) => ({ ...prev, [name]: value }));
        setError((prev) => ({ ...prev, [name]: "" }));

    };


    const submitHandler = async (e) => {
        e.preventDefault();
        const { email, userName, phoneNumber, password, confirmPassword } = inData;

        let isValid = true;

        // Form validation
        if (!email) {
            setError((prev) => ({ ...prev, email: "Email is required *" }));
            isValid = false;
        } else if (!emailPattern.test(email)) {
            setError((prev) => ({ ...prev, email: "Invalid email format *" }));
            isValid = false;
        }

        if (!password) {
            setError((prev) => ({ ...prev, password: "Password is required *" }));
            isValid = false;
        }

        if (!confirmPassword) {
            setError((prev) => ({ ...prev, confirmPassword: "Confirm password is required *" }));
            isValid = false;
        } else if (password !== confirmPassword) {
            setError((prev) => ({ ...prev, confirmPassword: "Passwords do not match *" }));
            isValid = false;
        }

        if (!userName) {
            setError((prev) => ({ ...prev, userName: "Username is required *" }));
            isValid = false;
        }

        if (!phoneNumber) {
            setError((prev) => ({ ...prev, phoneNumber: "Phone number is required *" }));
            isValid = false;
        }

        // If form is valid, proceed to submit the data
        if (isValid) {
            const payload = { email, userName, phoneNumber, password }

            setLoading(true);

            const res = await addAdminUserAPI(payload);

            setLoading(false);

            if (res.success) {
                successAlert({ message: res.message });
                navigate("/admin-users");
            } else {
                errorAlert({ message: res.message });
            }
        }
    };

    return (
        <>
            <Loader show={loading} />
            <Container fluid>
                <Row className="m-0">
                    <Col md={3}>
                        <Sidebar />
                    </Col>
                    <Col md={9}>
                        <VerticalLayout gap={3}>
                            <Header
                                heading={'Add Admin User'}
                                subHeading={'Welcome ! Admin Truck'}
                                showNotificationIcon={true}
                            />

                            <HorizontalLayout justifyContent={"between"} alignItems={"center"}>
                                <SharedButton
                                    label={"Back"}
                                    size={"sm"}
                                    variant={"light"}
                                    className={"rounded-pill"}
                                    icon={<LeftArrow />}
                                    onClick={() => window.history.back()}
                                />
                            </HorizontalLayout>

                            <WhitePanelBox className={"py-4 px-4"}>
                                <Form onSubmit={submitHandler}>
                                    <Row className="g-3">
                                        <Col md={6} lg={6} sm={12}>
                                            <InputFields
                                                type="text"
                                                name="userName"
                                                label="User Name"
                                                id="nickName"
                                                placeholder="Enter Name"
                                                size="md"
                                                className="rounded-2 bg-white "
                                                onChange={inputHandler}
                                                feedback={error.userName}
                                                value={inData.userName}
                                            />
                                        </Col>
                                        <Col md={6} lg={6} sm={12}>
                                            <InputFields
                                                type="email"
                                                name="email"
                                                label="Email"
                                                id="email"
                                                placeholder="Enter Email"
                                                size="md"
                                                className="rounded-2 bg-white"
                                                onChange={inputHandler}
                                                feedback={error.email}
                                                value={inData.email}
                                            />
                                        </Col>
                                        <Col md={6} lg={6} sm={12}>
                                            <InputFields
                                                type="text"
                                                name="phoneNumber"
                                                label="Phone No"
                                                id="phone"
                                                placeholder="Enter Phone No"
                                                size="md"
                                                className="rounded-2 bg-white"
                                                onChange={inputHandler}
                                                feedback={error.phoneNumber}
                                                value={inData.phoneNumber}
                                            />
                                        </Col>
                                        <Col md={6} lg={6} sm={12}>
                                            <InputFields
                                                type="password"
                                                name="password"
                                                label="Password"
                                                id="password"
                                                placeholder="Enter Password"
                                                size="md"
                                                className="rounded-2 bg-white"
                                                onChange={inputHandler}
                                                feedback={error.password}
                                                value={inData.password}
                                            />
                                        </Col>
                                        <Col md={6} lg={6} sm={12}>
                                            <InputFields
                                                type="password"
                                                name="confirmPassword"
                                                label="Confirm Password"
                                                id="confirmPassword"
                                                placeholder="Confirm Password"
                                                size="md"
                                                className="rounded-2 bg-white"
                                                onChange={inputHandler}
                                                feedback={error.confirmPassword}
                                                value={inData.confirmPassword}
                                            />
                                        </Col>
                                    </Row>

                                    <Row className="mt-3">
                                        <Col md={12} sm={12}>
                                        <HorizontalLayout justifyContent={"end"} className="mt-4">
                                                <SharedButton
                                                    type="submit"
                                                    variant="primary"
                                                    className="px-5 fw-semibold"
                                                    size="md"
                                                    label="Create User"
                                                />
                                            </HorizontalLayout>
                                        </Col>

                                    </Row>
                                           
                                </Form>
                            </WhitePanelBox>
                        </VerticalLayout>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
