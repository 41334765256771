import React from 'react'

export const SecurityIcon = () => {
    return (
        <>

            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M12.4999 7.25C10.9811 7.25 9.74987 8.48122 9.74987 10C9.74987 11.2588 10.5956 12.32 11.7499 12.6465V16C11.7499 16.4142 12.0857 16.75 12.4999 16.75C12.9141 16.75 13.2499 16.4142 13.2499 16V12.6465C14.4041 12.32 15.2499 11.2588 15.2499 10C15.2499 8.48122 14.0187 7.25 12.4999 7.25ZM11.2499 10C11.2499 9.30964 11.8095 8.75 12.4999 8.75C13.1902 8.75 13.7499 9.30964 13.7499 10C13.7499 10.6904 13.1902 11.25 12.4999 11.25C11.8095 11.25 11.2499 10.6904 11.2499 10Z" fill="#2D264B" />
                <path fillRule="evenodd" clipRule="evenodd" d="M10.0628 1.25C8.52104 1.25 7.27117 2.49987 7.27117 4.04167C7.27117 4.75503 6.69287 5.33333 5.9795 5.33333H5.67064C4.09458 5.33333 2.69067 6.55273 2.77617 8.24648C2.8777 10.2575 3.32435 13.3918 4.9535 16.3608C5.4098 17.1923 6.01919 18.0092 6.67795 18.7703L6.72755 18.8276C7.67522 19.9226 8.46537 20.8356 9.31485 21.4579C10.2285 22.1273 11.2037 22.4607 12.5 22.4607C13.7963 22.4607 14.7715 22.1273 15.6852 21.4579C16.5346 20.8356 17.3248 19.9226 18.2725 18.8276L18.3221 18.7703C18.9808 18.0092 19.5902 17.1923 20.0465 16.3608C21.7298 13.2931 22.1496 9.82667 22.2324 7.66561C22.2969 5.97999 20.903 4.75 19.3182 4.75H18.7288C18.1766 4.75 17.7288 4.30228 17.7288 3.75C17.7288 2.36929 16.6096 1.25 15.2288 1.25H10.0628ZM8.77117 4.04167C8.77117 3.3283 9.34947 2.75 10.0628 2.75H15.2288C15.7811 2.75 16.2288 3.19772 16.2288 3.75C16.2288 5.13071 17.3481 6.25 18.7288 6.25H19.3182C20.1558 6.25 20.7616 6.8731 20.7335 7.60819C20.6544 9.67293 20.2518 12.8686 18.7315 15.6392C18.3425 16.348 17.804 17.0768 17.1879 17.7886C16.1778 18.9557 15.4957 19.7373 14.7987 20.2479C14.1512 20.7223 13.4888 20.9607 12.5 20.9607C11.5112 20.9607 10.8489 20.7223 10.2013 20.2479C9.50433 19.7373 8.82224 18.9557 7.81212 17.7886C7.19604 17.0768 6.65747 16.348 6.26853 15.6392C4.79005 12.9448 4.36972 10.0616 4.27427 8.17085C4.23795 7.45149 4.8305 6.83333 5.67064 6.83333H5.9795C7.5213 6.83333 8.77117 5.58346 8.77117 4.04167Z" fill="#2D264B" />
            </svg>

        </>
    )
}
