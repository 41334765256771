import { useEffect, useRef, useState } from "react";
import { VerticalLayout } from "../../components/VerticalLayout";
import { HorizontalLayout } from "../../components/HorizontalLayout";
import { Image, Tab } from "react-bootstrap";
import Typography from "../../components/Typography";
import { InputFields } from "../../components/InputFields";
import styled from "styled-components";
import { BiSolidNavigation } from "react-icons/bi";
import { SharedButton } from "../../components/SharedButton";
import { ImCross } from "react-icons/im";
import moment from "moment";
import { WhitePanelBox } from "../../components/WhitePanelBox";

// const MessageBox = styled.div`
//     max-height:100%; 
//     height:110vh;
//     overflow-y:auto;
//     &::-webkit-scrollbar {
//         display: none;
//     }
// `;

const MessageBox = styled.div`
    max-height: 100%;
    height: 60vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse; /* Reverse the direction */
    &::-webkit-scrollbar {
        display: none;
    }
`;



export const ChatPanel = ({ users, setUserKey, setMessages, setCombinedId, messages, sendMessageHandler, loggedUserId, setActive, }) => {
    const [inText, setInText] = useState();
    const messageBoxRef = useRef();

    useEffect(() => {
        if (messageBoxRef.current) {
            messageBoxRef.current.scrollTop = 0;
        }
    }, [messages]);

    const convertUtcToLocalDate = (date) => {
        return moment(date.toDate()).local().format("YYYY-MM-DD hh:mm A")
    };

    return (
        <WhitePanelBox>
            <Tab.Content  >
                {users.map((user, index) => (
                    <Tab.Pane key={user.userId} eventKey={user.userId} >
                        <VerticalLayout gap={1}>
                            <HorizontalLayout gap={3}>
                                <Image
                                    src={user?.profileImage || "../../assets/images/imagePlaceholder.png"}
                                    fluid
                                    roundedCircle
                                    className="chatuser-image"
                                />
                                <VerticalLayout justifyContent={'center'}>
                                    <Typography variant={'h5'} className={'mb-0'}>
                                        {user?.userName}
                                    </Typography>
                                </VerticalLayout>
                                <ImCross onClick={() => { setActive(); setUserKey(); setMessages([]); setCombinedId(); }} className="cursor-pointer" />
                            </HorizontalLayout>
                            <hr />
                            <VerticalLayout gap={2}>
                                <MessageBox ref={messageBoxRef} >
                                    <VerticalLayout gap={3}>
                                        <div>
                                            {messages && messages.map((msg, index) => (
                                                (msg?.senderId === loggedUserId) ? (
                                                    <HorizontalLayout key={index} gap={2} justifyContent={'end'} alignItems={'end'}>
                                                        <div className='ms-5'>
                                                            <span className='message-box'>{msg?.text} </span>
                                                            <VerticalLayout alignItems={'end'} >
                                                                <Typography size={'.7rem'} className={'fw-bold text-muted m-0'}>
                                                                    {msg?.date && convertUtcToLocalDate(msg?.date).split(' ')[0]}
                                                                </Typography>
                                                                <Typography size={'.7rem'} className={'fw-bold text-muted m-0'}>
                                                                    {msg?.date && `${convertUtcToLocalDate(msg?.date).split(' ')[1]} ${convertUtcToLocalDate(msg?.date).split(' ')[2]}`}
                                                                </Typography>
                                                            </VerticalLayout>
                                                        </div>
                                                    </HorizontalLayout>
                                                ) : (
                                                    <HorizontalLayout key={index} gap={2} alignItems={'end'}>
                                                        <div className='me-5'>
                                                            <span className='message-box'>{msg?.text} </span>
                                                            <VerticalLayout  >
                                                                <Typography size={'.7rem'} className={'fw-bold text-muted m-0'}>
                                                                    {msg?.date && convertUtcToLocalDate(msg?.date).split(' ')[0]}
                                                                </Typography>
                                                                <Typography size={'.7rem'} className={'fw-bold text-muted m-0'}>
                                                                    {msg?.date && `${convertUtcToLocalDate(msg?.date).split(' ')[1]} ${convertUtcToLocalDate(msg?.date).split(' ')[2]}`}
                                                                </Typography>
                                                            </VerticalLayout>
                                                        </div>
                                                    </HorizontalLayout>
                                                )
                                            ))}
                                        </div>

                                    </VerticalLayout>
                                </MessageBox>
                            </VerticalLayout>

                            <HorizontalLayout gap={1} justifyContent={'start'} alignItems={'center'}>
                                <InputFields
                                    type={'text'}
                                    className={'rounded-end'}
                                    placeholder={'Type a Message'}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            // Trigger the send message handler when Enter is pressed
                                            sendMessageHandler(inText);
                                            setInText(''); // Clear input after sending message
                                        }
                                    }}
                                    value={inText}
                                    onChange={(e) => setInText(e.target.value)}
                                />
                                <SharedButton
                                    className={'border-0'}
                                    icon={<BiSolidNavigation fontSize={'1.5rem'} className="text-primary" />}
                                    onClick={() => { sendMessageHandler(inText); setInText("") }}
                                    variant={'transparent'}
                                />
                            </HorizontalLayout>
                        </VerticalLayout>
                    </Tab.Pane>
                ))
                }
            </Tab.Content >
        </WhitePanelBox >
    )
}
