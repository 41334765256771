import { Col, Container, Row } from "react-bootstrap"
import { Heading } from "../../components/Heading"
import { Sidebar } from "../../commonPages/sidebar"
import { WhitePanelBox } from "../../components/WhitePanelBox"
import { Notification } from "../../components/Icons/Notification"
import { SvgIcon } from "../../components/SvgIcon"
import { SearchIcon } from "../../components/Icons/SearchIcon"
import { HorizontalLayout } from "../../components/HorizontalLayout"
import { VerticalLayout } from "../../components/VerticalLayout"
import Typography from "../../components/Typography"
import { DropdownFilter } from "../../components/DropdownFilter"
import { Filter } from "../../components/Icons/Filter"
import { DashboardCard } from "../../components/DashboardCard"
import { TotalUser } from "../../components/Icons/TotalUser"
import { NewUser } from "../../components/Icons/NewUser"
import { ActiveUser } from "../../components/Icons/ActiveUser"
import { UserListTable } from "./userList/UserListTable"
import { useState, useEffect } from "react"
import { errorAlert } from "../../components/Alert"
import { Loader } from "../../components/Loader"
import { getUsers, getUserAnalytics } from "../../services/NetworkCall"
import Header from "../../components/Header"

export const UserAccount = () => {
    const [loading, setLoading] = useState(false);
    const [usersData, setUsersData] = useState([]);
    const [pagination, setPagination] = useState({ totalPages: 1, page: 1 })
    const [userAnalyticsData, setUserAnalyticsData] = useState({});
    const [selectedFilterValue, setSelectedFilterValue] = useState("Total Users Register");


    const filterValue = {
        "Total Users Register": "TOTAL_REGISTER_USERS",
        "New Users Register": "NEW_REGISTER_USERS",
        "Active Users Register": "ACTIVE_REGISTER_USERS",
    };

    const getUsersData = async (page = 1, filter = filterValue[selectedFilterValue]) => {
        setLoading(true);
        const res = await getUsers(page, filter);
        if (res.success) {
            setUsersData(res.data);
            setPagination(prevPagination => ({
                ...prevPagination,
                totalPages: res.totalNumberOfPages
            }));
            // successAlert(res.message);
        } else {
            errorAlert({ message: res.message });
        }
        setLoading(false);
    }

    const getAnalyticData = async () => {
        setLoading(true);
        const res = await getUserAnalytics();
        if (res.success) {
            setUserAnalyticsData(res.data);
        } else {
            errorAlert({ message: res.message });
        }
        setLoading(false);
    }

    useEffect(() => {
        getUsersData(pagination.page);
        getAnalyticData();
    },
        [pagination.page, selectedFilterValue]);

    const pageHandler = (page) => {
        setPagination(prevPagination => ({
            ...prevPagination,
            page: page
        }));
        getUsersData(page);
    }

    // Filter options
    const filterOptions = ["Total Users Register", "New Users Register", "Active Users Register"];

    // Handle filter selection
    const handleFilterSelection = (selectedValue) => {
        setSelectedFilterValue(selectedValue);
        setPagination({ totalPages: 1, page: 1 });
    };

    return <>
        <Loader show={loading} />
        <Container fluid>
            <Row className="g-3">
                <Col md={3}>
                    <Sidebar />
                </Col>
                <Col md={9}>
                    <VerticalLayout gap={3}>
                        <Header
                            heading={'Users Management'}
                            subHeading={'Welcome ! Admin Truck'}
                            showNotificationIcon={true}
                        />

                        <Row className='g-3'>
                            <Col sm={12} md={4}>
                                <DashboardCard Icon={<TotalUser />} Heading={'Total Users Register'} SubHeading={userAnalyticsData?.totalUsers} />
                            </Col>
                            <Col sm={12} md={4}>
                                <DashboardCard Icon={<NewUser />} Heading={'New Users Register'} SubHeading={userAnalyticsData?.totalNewUsers} />
                            </Col>
                            <Col sm={12} md={4}>
                                <DashboardCard Icon={<ActiveUser />} Heading={'Active Users Register'} SubHeading={userAnalyticsData?.totalActiveUsers} />
                            </Col>
                        </Row>


                        <HorizontalLayout justifyContent={'between'} alignItems={'center'}>
                            <Typography className={'fw-medium mb-0'} color={'#000'}>Users List</Typography>
                            <DropdownFilter
                                Icon={<Filter />}
                                DropdownLabel={selectedFilterValue}
                                className={"bg-light rounded-5 border text-dark"}
                                filterOptions={filterOptions}
                                selectedValue={selectedFilterValue}
                                onFilterSelect={handleFilterSelection}
                            />
                        </HorizontalLayout>
                        <UserListTable usersData={usersData} pagination={pagination} pageHandler={pageHandler} />
                    </VerticalLayout>

                </Col>
            </Row>
        </Container>
    </>
}