import { Col, Container, Row } from "react-bootstrap"
import { Heading } from "../../../components/Heading"
import { Sidebar } from "../../../commonPages/sidebar"
import { WhitePanelBox } from "../../../components/WhitePanelBox"
import { Notification } from "../../../components/Icons/Notification"
import { SvgIcon } from "../../../components/SvgIcon"
import { SearchIcon } from "../../../components/Icons/SearchIcon"
import { HorizontalLayout } from "../../../components/HorizontalLayout"
import { VerticalLayout } from "../../../components/VerticalLayout"
import Typography from "../../../components/Typography"
import { useState, useEffect } from "react"
import { errorAlert } from "../../../components/Alert"
import { Loader } from "../../../components/Loader"
import { getCameras, deleteCamera } from "../../../services/NetworkCall"
import { CameraListTable } from "./CameraListTable"
import { SharedButton } from "../../../components/SharedButton"
import { useNavigate } from "react-router-dom"
import Swal from 'sweetalert2';
import Header from "../../../components/Header"

export const CameraList = () => {
    const [loading, setLoading] = useState(false);
    const [cameraData, setCameraData] = useState([]);
    const [pagination, setPagination] = useState({ totalPages: 1, page: 1 })
    const navigate = useNavigate();

    const getCameraData = async (page = 1) => {
        setLoading(true);
        const res = await getCameras(page);
        if (res.success) {
            setCameraData(res.data);
            setPagination(prevPagination => ({
                ...prevPagination,
                totalPages: res.totalNumberOfPages
            }));
            // successAlert(res.message);
        } else {
            errorAlert({ message: res.message });
        }
        setLoading(false);
    }

    useEffect(() => {
        getCameraData(pagination.page);
    },
        [pagination.page]);

    const pageHandler = (page) => {
        setPagination(prevPagination => ({
            ...prevPagination,
            page: page
        }));
        getCameraData(page);
    }

    const handleDeleteClick = (id) => {
        if (!id) {
            errorAlert("Something wrong");
            return;
        }
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: `Yes, delete it!`
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await deleteCamera({ id });
                if (res.success) {
                    setLoading(false);
                    Swal.fire({
                        title: "DELETE!",
                        text: `Request has been deleted.`,
                        icon: "success"
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            getCameraData();
                        }
                    });
                }
            }
        });
    };


    return <>
        <Loader show={loading} />
        <Container fluid>
            <Row className="g-3">
                <Col md={3}>
                    <Sidebar />
                </Col>
                <Col md={9}>
                    <VerticalLayout gap={3}>
                        <Header
                            heading={'Camera'}
                            subHeading={'Welcome ! Admin Truck'}
                            showNotificationIcon={true}
                        />
                        <HorizontalLayout justifyContent={'between'} alignItems={'center'}>
                            <Typography className={'fw-medium mb-0'} color={'#000'}>Camera List</Typography>
                            <SharedButton variant={'primary'} className={'px-5'} size={'md'} onClick={() => navigate('/add-camera')} label={"Add Camera"} />
                        </HorizontalLayout>
                        <CameraListTable
                            cameraData={cameraData}
                            pagination={pagination}
                            pageHandler={pageHandler}
                            handleDeleteClick={handleDeleteClick}
                        />
                    </VerticalLayout>
                </Col>
            </Row>
        </Container>
    </>
}