import React, { useEffect, useState } from 'react'
import { Col, Row, Stack, Table } from 'react-bootstrap'
import { WhitePanelBox } from '../../../components/WhitePanelBox'
import { SharedButton } from '../../../components/SharedButton';
import { AddIcon } from '../../../components/Icons/AddIcon';
import { LeftArrow } from '../../../components/Icons/LeftArrow';
import Select from '../../../components/Select';
import { NewInputField } from '../../../components/NewInputField';
import {
    getParkingLocationPreferredSlotPrices,
    createParkingLocationPreferredSlotPrice,
    updateParkingLocationPreferredSlotPrice,
    getParkingLocationOptionAPI
} from '../../../services/NetworkCall';
import { errorAlert, successAlert } from '../../../components/Alert';
import { EditIcon } from '../../../components/Icons/EditIcon';

export const PreferredParkingPrice = ({ setLoading }) => {
    const [createPr, setCreatePr] = useState(false);
    const [inData, setInData] = useState({
        "locationId": "",
        "price": "",
        "name": "",
    });

    const [error, setError] = useState({
        "price": "",
        "name": "",
        "locationId": ""
    });

    const [exData, setExData] = useState([]);
    const [locationData, setLocationData] = useState([]);
    const [isEdit, setIsEdit] = useState(false);

    const inputHandler = (e) => {
        const { name, value, type } = e.target;

        if (type === 'number') {
            const regex = /^\d*(\.\d{0,2})?$/;
            if (value.includes('.') && value.split('.')[1].length > 2) {

            } else {
                setInData((pre) => ({ ...pre, [name]: value }));
                setError((pre) => ({ ...pre, [name]: "" }));
            }
        } else {
            setInData((pre) => ({ ...pre, [name]: value }));
            setError((pre) => ({ ...pre, [name]: "" }));
        }
    }

    const getPreferredParkingLocationPriceData = async () => {
        setLoading(true);
        const res = await getParkingLocationPreferredSlotPrices();
        if (res.success) {
            const data = res.data;
            setExData(data);
            setLoading(false);
        }
        setLoading(false);
    }

    const getLocationOptions = async () => {
        setLoading(true);
        const res = await getParkingLocationOptionAPI();
        if (res.success) {
            const data = res.data;
            const pre = data.map((e) => ({ "name": e?.locationName, "value": e?._id }));
            setLocationData(pre)
        }
        setLoading(false);
    }


    useEffect(() => {
        getPreferredParkingLocationPriceData();
        getLocationOptions();
    }, []);


    const submitHandler = async (e) => {
        e.preventDefault();

        let isValid = true;

        const { locationId, price, name } = inData;

        if (!locationId) { setError((pre) => ({ ...pre, "locationId": 'Required' })); isValid = false; }
        if (!price) { setError((pre) => ({ ...pre, "price": 'Required' })); isValid = false; }
        if (!name) { setError((pre) => ({ ...pre, "name": 'Required' })); isValid = false; }

        if (isValid) {
            setLoading(true);
            const data = {
                "parkingLocationId": locationId,
                "price": price,
                "name": name,
            }

            const res = await createParkingLocationPreferredSlotPrice(data);

            if (res.success) {
                setInData({
                    "locationId": "",
                    "price": "",
                    "name": ""
                })
                // setCreatePr(false);
                getPreferredParkingLocationPriceData();
                setLoading(false);
                setCreatePr(false);
                successAlert({ message: res.message })
            } else {
                setLoading(false);
                errorAlert({ message: res.message });
            }
            setLoading(false);
        }
    }

    const updateHandler = async (e) => {
        e.preventDefault();

        let isValid = true;

        const { locationId, stripeProductId, price, name, id } = inData;

        if (!locationId) { setError((pre) => ({ ...pre, "locationId": 'Required' })); isValid = false; }
        if (!price) { setError((pre) => ({ ...pre, "price": 'Required' })); isValid = false; }
        if (!name) { setError((pre) => ({ ...pre, "name": 'Required' })); isValid = false; }

        if (isValid) {
            setLoading(true);
            const data = {
                "stripeProductId": stripeProductId,
                "price": price,
                "name": name,
            }

            const res = await updateParkingLocationPreferredSlotPrice(id, data);

            if (res.success) {
                setInData({
                    "locationId": "",
                    "price": "",
                    "name": ""
                })
                getPreferredParkingLocationPriceData();
                setIsEdit(false);
                setLoading(false);
                successAlert({ message: res.message })
            } else {
                setLoading(false);
                errorAlert({ message: res.message });
            }
            setLoading(false);
        }
    }

    const editButtonHandler = (d) => {
        setIsEdit(true);
        setInData({
            "id": d?._id,
            "name": d?.name,
            "price": d?.price,
            "stripeProductId": d?.stripeProductId,
            "locationId": d?.parkingLocationId?._id
        })
    }

    const handleBack = () => {
        setCreatePr(false);
        setIsEdit(false);
        inData.locationId = '';
        inData.name = '';
        inData.price = '';

    }

    return (
        <>
            {!createPr && !isEdit ? (
                <>
                    <Stack direction="horizontal" className="justify-content-end">
                        <SharedButton className={'w-25 mb-3'} type={'button'} label={'Add Price'} icon={<AddIcon color={'#fff'} />} onClick={() => setCreatePr(true)} />
                    </Stack>
                    <WhitePanelBox>
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>LOCATION NAME</th>
                                    <th>Name</th>
                                    <th>Price</th>
                                    <th>ACTION</th>
                                </tr>
                            </thead>
                            <tbody>
                                {exData && exData.map((d, index) => (
                                    <tr key={index}>
                                        <td>
                                            {d?.parkingLocationId?.locationName}
                                        </td>
                                        <td>{d?.name}</td>
                                        <td>{d?.price}</td>
                                        <th>
                                            <SharedButton
                                                type={'button'}
                                                onClick={() => { editButtonHandler(d) }}
                                                label={'Edit'}
                                                icon={<EditIcon color={'#4E78EC'} />}
                                                variant={'light-primary'}
                                                size={'md'}
                                                className={'rounded-5 text-primary me-2'} />
                                        </th >

                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </WhitePanelBox>
                </>)
                :
                (<>
                    <Stack direction="horizontal" className="justify-content-end">
                        <SharedButton variant={'light'} className={'rounded-5 mb-3'} type={'button'} label={'Back'} icon={<LeftArrow color={'#000'} />} onClick={handleBack} />
                    </Stack>
                    <WhitePanelBox className={'p-5'}>
                        <Row className='g-3'>
                            <Col md={3} className='mb-4' >
                                <Select
                                    myClassName={'rounded-2 bg-white'}
                                    Array={locationData}
                                    readOnly={isEdit}
                                    name="locationId"
                                    FormLabel={"Location"}
                                    onChange={inputHandler}
                                    value={inData.locationId}
                                    error={error.locationId}
                                />
                            </Col>
                            <Col md={3} className='mb-4'>
                                <NewInputField
                                    FormLabel={'Name'}
                                    placeholder={'name'}
                                    FormType={'text'}
                                    name={'name'}
                                    className={'rounded-2 bg-white'}
                                    value={inData.name}
                                    onChange={inputHandler}
                                    error={error.name}
                                />
                            </Col>
                            <Col md={3} className='mb-4'>
                                <NewInputField
                                    FormLabel={'Price'}
                                    placeholder={'Price'}
                                    FormType={'number'}
                                    value={inData.price}
                                    name={'price'}
                                    error={error.price}
                                    min={'0'}
                                    className={'rounded-2 bg-white'}
                                    onChange={inputHandler} />
                            </Col>
                        </Row>
                        <Row className='g-3 mt-3'>
                            <Col md={4}>

                                {isEdit ?
                                    <SharedButton onClick={updateHandler} label={'Update'} variant={'primary'} size={'md'} className={'w-100 my-2'} />
                                    : <SharedButton
                                        onClick={submitHandler}
                                        label={'Save'}
                                        variant={'primary'}
                                        size={'md'}
                                        type={"button"}
                                        className={'w-100 my-2'} />
                                }
                            </Col>
                        </Row>
                    </WhitePanelBox>
                </>)
            }
        </>
    )
}
