import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import { Loader } from '../../../components/Loader';
import { Sidebar } from '../../../commonPages/sidebar';
import { VerticalLayout } from '../../../components/VerticalLayout';
import { WhitePanelBox } from '../../../components/WhitePanelBox';
import { HorizontalLayout } from '../../../components/HorizontalLayout';
import { Heading } from '../../../components/Heading';
import { SvgIcon } from '../../../components/SvgIcon';
import { SearchIcon } from '../../../components/Icons/SearchIcon';
import { SharedButton } from '../../../components/SharedButton';
import { LeftArrow } from '../../../components/Icons/LeftArrow';
import { Notification } from '../../../components/Icons/Notification';
import { deleteParkingSlotAPI, getAddonServicesAPI, getCameras, updateParkingSlotAPI } from '../../../services/NetworkCall';
import Select from '../../../components/Select';
import { NewInputField } from '../../../components/NewInputField';
import { useLocation, useNavigate } from 'react-router-dom';
import { Checkbox } from '../../../components/Checkbox';
import { CheckedSlotsIDS, updateCheckedServices } from '../../../helper/Helper';
import { errorAlert, successAlert } from '../../../components/Alert';
import Swal from 'sweetalert2';
import { Delete } from '../../../components/Icons/Delete';

export const EditSlot = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const [preSlotsIds, setPreSlotsIds] = useState([]);
    const [optionCamera, setOptionCamera] = useState([]);
    const [slots, setSlots] = useState(
        {
            "id": "",
            "slotNumber": "",
            "slotLength": "",
            "slotWidth": "",
            "cameraId": "",
            "addonServiceIds": [],
            "isPreferredSlot": false
        }
    );

    const [erData, setErData] = useState(
        {
            "id": "",
            "slotNumber": "",
            "slotLength": "",
            "slotWidth": "",
            "cameraId": "",
            "addonServiceIds": [],
            "isPreferredSlot": false
        }
    )

    const getCamerasAPI = async () => {
        const res = await getCameras();
        if (res.success) {
            const data = res.data;
            const pre = data.map((e) => ({
                'name': e?.cameraName,
                'value': e?._id,
            }));
            setOptionCamera(pre);
        }
    }

    const isIdsCheck = (id) => {
        const data = preSlotsIds.map((e) => {
            if (e.id === id) {
                return true;
            } else return false;
        });
        return data;
    };

    const isIdsCheckNew = (id) => {
        const data = preSlotsIds.some((e) => e === id);
        return data;
    };

    const getServiceList = async () => {
        try {
            setLoading(true);
            const res = await getAddonServicesAPI();
            if (res.success) {
                const data = res.data;
                let allTrue = false;
                if (data?.length == preSlotsIds.length) {
                    allTrue = true;
                }
                const pre = [{
                    'id': '',
                    'serviceName': "Select All",
                    "isChecked": allTrue
                },
                ...data.map((e) => ({
                    'id': e?._id,
                    'serviceName': e?.name,
                    "isChecked": isIdsCheckNew(e?._id)
                }))];

                setSlots((prevSlots) => ({
                    ...prevSlots,
                    addonServiceIds: pre,
                }));
            }
        } catch (error) {
            console.error("Error fetching addonServiceIds:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (location?.state?.data) {
            getCamerasAPI();
            const data = location?.state?.data;
            console.log(" data?.addonServiceIds", data?.addonServiceIds);
            const pre = {
                id: data._id,
                slotNumber: data.slotNumber,
                slotLength: data.slotLength,
                slotWidth: data.slotWidth,
                cameraId: data.cameraId?._id,
                addonServiceIds: [],
                isPreferredSlot: data?.isPreferredSlot
            };
            setSlots(pre);
            const arrayIds = [];
            data?.addonServiceIds.map((e) => arrayIds.push(e._id));
            setPreSlotsIds(arrayIds);

        }
    }, [location]);

    useEffect(() => {
        getServiceList();
    }, [preSlotsIds])

    const slotChangeHandler = (e) => {
        let { name, value } = e.target;
        if (name === 'isPreferredSlot') {
            value = e.target;
        }

        if (name === 'isPreferredSlot') {
            value = e.target.checked;
        }

        setSlots((prevSlots) => ({
            ...prevSlots,
            [name]: value,
        }));
    }

    const checkHandler = (e, serviceIndex) => {
        const { name, checked } = e.target;
        setSlots((prevSlots) => {
            const updatedAddonServiceIds = prevSlots.addonServiceIds.map((service, index) => {
                if (name === "Select All") {
                    return { ...service, isChecked: checked };
                } else if (index === serviceIndex) {
                    return { ...service, isChecked: checked };
                }
                return service;
            });

            if (name !== "Select All") {
                const allChecked = updatedAddonServiceIds.slice(1).every((service) => service.isChecked);
                updatedAddonServiceIds[0].isChecked = allChecked;
            }
            return {
                ...prevSlots,
                addonServiceIds: updatedAddonServiceIds,
            };
        });
    };

    console.log("---", slots);

    const updateHandler = async () => {
        const { id, slotNumber, slotLength, slotWidth, cameraId, addonServiceIds, isPreferredSlot } = slots;
        let isValid = true;
        if (!slotNumber) { setErData((pre) => ({ ...pre, 'slotNumber': "Required" })); isValid = false };
        if (!slotLength) { setErData((pre) => ({ ...pre, 'slotLength': "Required" })); isValid = false };
        if (!slotWidth) { setErData((pre) => ({ ...pre, 'slotWidth': "Required" })); isValid = false };
        if (!cameraId) { setErData((pre) => ({ ...pre, 'cameraId': "Required" })); isValid = false };
        const serIds = CheckedSlotsIDS(addonServiceIds);
        if (isValid) {
            setLoading(true);
            const data = {
                "slotNumber": slotNumber,
                "slotLength": slotLength,
                "slotWidth": slotWidth,
                "cameraId": cameraId,
                "addonServiceIds": serIds,
                "isPreferredSlot": isPreferredSlot
            }

            console.log('data', data);
            try {
                const res = await updateParkingSlotAPI(data, id);
                setLoading(false);
                if (res.success) {
                    successAlert({ message: res.message });
                    // navigate('/location-list');
                } else {
                    errorAlert({ message: res.message });
                }
            } catch (error) {
                setLoading(false);
                errorAlert({ message: "An error occurred while creating the location." });
                console.error(error);
            }

        }
    }

    const deleteHandler = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await deleteParkingSlotAPI(id);
                if (res.success) {
                    setLoading(false);
                    successAlert({ message: res.message });
                    navigate("/location-list");
                } else {
                    setLoading(false);
                    errorAlert({ message: res.message });
                }
                setLoading(false);
            }
        });
    }

    return (
        <>
            <Loader show={loading} />
            <Container fluid>
                <Row className="m-0">
                    <Col md={3}>
                        <Sidebar />
                    </Col>
                    <Col md={9}>
                        <VerticalLayout gap={3}>
                            <WhitePanelBox className="Box">
                                <HorizontalLayout gap={2} justifyContent={'space-between'} alignItems={'center'}>
                                    <Heading Heading={'Edit Location'} SubHeading={'Welcome ! Admin Truck'} />
                                    <HorizontalLayout gap={3}>
                                        <SvgIcon bg={'#E8EDFD'} size={'1rem'}>
                                            <SearchIcon />
                                        </SvgIcon>
                                        <SvgIcon bg={'#E8EDFD'} size={'1rem'}>
                                            <Notification />
                                        </SvgIcon>
                                    </HorizontalLayout>
                                </HorizontalLayout>
                            </WhitePanelBox>
                            <HorizontalLayout justifyContent={'between'} alignItems={'center'}>
                                <SharedButton onClick={() => window.history.back()} className={'rounded-5'} variant={'light'} size={'md'} type={'button'} label={'Back'} icon={<LeftArrow color={'#000'} />} />
                                <HorizontalLayout justifyContent={'between'} alignItems={'center'}>
                                    <SharedButton onClick={updateHandler} className={'rounded-5 text-dark'} variant={'light'} size={'md'} type={'button'} label={'Update'} />
                                    <SharedButton label={'Delete'} type={'button'} variant={'danger-light'} size={'md'} className={'rounded-5 text-black ms-2'} onClick={() => deleteHandler(slots?.id)} icon={<Delete />} />
                                </HorizontalLayout>
                            </HorizontalLayout>

                            <WhitePanelBox>
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Body>
                                            <VerticalLayout gap={3} justifyContent={'center'} alignItems={'start'}>
                                                <Row className='w-100'>
                                                    <Col md={4} className='mb-3'>
                                                        <NewInputField FormLabel={'Parking Slot Name'} FormType={'text'} FormPlaceHolder={'parking slot name'} name={'slotNumber'} value={slots?.slotNumber} error={erData.slotNumber} className={'rounded-2 bg-white'} onChange={slotChangeHandler} />
                                                    </Col>
                                                    <Col md={4} className='mb-3'>
                                                        <NewInputField FormLabel={'Length (Feet)'} FormType={'number'} FormPlaceHolder={'length (feet)'} min={0} name={'slotLength'} value={slots?.slotLength} error={erData.slotLength} className={'rounded-2 bg-white'} onChange={slotChangeHandler} />
                                                    </Col>
                                                    <Col md={4} className='mb-3'>
                                                        <NewInputField FormLabel={'Width (Feet)'} FormType={'number'} FormPlaceHolder={'width (feet)'} min={0} name={'slotWidth'} value={slots?.slotWidth} error={erData.slotWidth} className={'rounded-2 bg-white'} onChange={slotChangeHandler} />
                                                    </Col>
                                                    <Col md={4} className='mb-1'>
                                                        <Select myClassName={'rounded-2 bg-white'} Array={optionCamera} name="cameraId" FormLabel={"Camera name"} value={slots?.cameraId} error={erData.cameraId} onChange={slotChangeHandler} />
                                                    </Col>
                                                    <Col md={4} className='mt-4'>
                                                        <SvgIcon className={'rounded-2 mb-2 mt-2'}>
                                                            <Checkbox
                                                                label={"Preferred Slot"}
                                                                onClick={slotChangeHandler}
                                                                checked={slots?.isPreferredSlot}
                                                                name={'isPreferredSlot'}
                                                            />
                                                        </SvgIcon>
                                                    </Col>
                                                </Row>
                                                <Row className='mt-4'>
                                                    {slots?.addonServiceIds.map((ser, serIndex) => (
                                                        <Col key={serIndex}>
                                                            <SvgIcon bg={'#D1DCFA'} className={'rounded-2 mb-2'}>
                                                                <Checkbox
                                                                    label={ser.serviceName}
                                                                    onClick={(e) => checkHandler(e, serIndex)}
                                                                    checked={ser?.isChecked}
                                                                    name={ser.serviceName}
                                                                />
                                                            </SvgIcon>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </VerticalLayout>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </WhitePanelBox>
                        </VerticalLayout>
                    </Col>
                </Row>
            </Container>
        </>
    )
};
