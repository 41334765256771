import React from 'react'

export const NewUser = () => {
    return (
        <>

            <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M20.0594 5.64697C17.3792 5.64697 15.2065 7.81971 15.2065 10.4999C15.2065 13.1801 17.3792 15.3529 20.0594 15.3529C22.7396 15.3529 24.9123 13.1801 24.9123 10.4999C24.9123 7.81971 22.7396 5.64697 20.0594 5.64697ZM17.1476 10.4999C17.1476 8.89179 18.4513 7.58815 20.0594 7.58815C21.6675 7.58815 22.9712 8.89179 22.9712 10.4999C22.9712 12.108 21.6675 13.4117 20.0594 13.4117C18.4513 13.4117 17.1476 12.108 17.1476 10.4999Z" fill="#2D264B" />
                <path fillRule="evenodd" clipRule="evenodd" d="M16.177 17.294C13.4968 17.294 11.3241 19.4668 11.3241 22.147C11.3241 24.8272 13.4968 26.9999 16.177 26.9999H23.9417C26.622 26.9999 28.7947 24.8272 28.7947 22.147C28.7947 19.4668 26.622 17.294 23.9417 17.294H16.177ZM13.2653 22.147C13.2653 20.5389 14.5689 19.2352 16.177 19.2352H23.9417C25.5499 19.2352 26.8535 20.5389 26.8535 22.147C26.8535 23.7551 25.5499 25.0587 23.9417 25.0587H16.177C14.5689 25.0587 13.2653 23.7551 13.2653 22.147Z" fill="#2D264B" />
                <path d="M14.5622 13.9146C14.3581 13.5867 13.9751 13.4117 13.5888 13.4117C11.9807 13.4117 10.677 12.108 10.677 10.4999C10.677 8.89179 11.9807 7.58815 13.5888 7.58815C13.9751 7.58815 14.3581 7.41309 14.5622 7.08519C14.5712 7.07068 14.5803 7.05622 14.5894 7.04179C14.9293 6.50537 14.7571 5.74584 14.1259 5.67635C13.9495 5.65694 13.7703 5.64697 13.5888 5.64697C10.9086 5.64697 8.73587 7.81971 8.73587 10.4999C8.73587 13.1801 10.9086 15.3529 13.5888 15.3529C13.7703 15.3529 13.9495 15.3429 14.1259 15.3235C14.7571 15.254 14.9293 14.4945 14.5894 13.958C14.5803 13.9436 14.5712 13.9291 14.5622 13.9146Z" fill="#2D264B" />
                <path d="M10.6231 24.1747C10.4762 23.9296 10.2168 23.7646 9.9311 23.7646H9.70646C8.09833 23.7646 6.79469 22.461 6.79469 20.8529C6.79469 19.2447 8.09833 17.9411 9.70646 17.9411H9.9311C10.2168 17.9411 10.4762 17.7761 10.6231 17.531C10.9817 16.9327 10.5925 15.9999 9.895 15.9999H9.70646C7.02625 15.9999 4.85352 18.1727 4.85352 20.8529C4.85352 23.5331 7.02625 25.7058 9.70646 25.7058H9.895C10.5925 25.7058 10.9817 24.773 10.6231 24.1747Z" fill="#2D264B" />
            </svg>

        </>
    )
}
