import { Col, Container, Row } from "react-bootstrap"
import { Heading } from "../../../components/Heading"
import { SharedButton } from "../../../components/SharedButton"
import { Sidebar } from "../../../commonPages/sidebar"
import { WhitePanelBox } from "../../../components/WhitePanelBox"
import { AddIcon } from "../../../components/Icons/AddIcon"
import { Notification } from "../../../components/Icons/Notification"
import { SvgIcon } from "../../../components/SvgIcon"
import { SearchIcon } from "../../../components/Icons/SearchIcon"
import { HorizontalLayout } from "../../../components/HorizontalLayout"
import { VerticalLayout } from "../../../components/VerticalLayout"
import Typography from "../../../components/Typography"
import { LocationListTable } from "./LocationListTable"
import { useEffect, useState } from "react"
import { getParkingLocations } from "../../../services/NetworkCall"
import { errorAlert } from "../../../components/Alert"
import { useNavigate } from "react-router-dom"
import { Loader } from "../../../components/Loader"
import Header from "../../../components/Header"

export const LocationList = () => {
    const [loading, setLoading] = useState(false);
    const [locationData, setLocationData] = useState([]);
    const [pagination, setPagination] = useState({ totalPages: 1, page: 1 })

    const navigate = useNavigate();
    const createLocationNavigate = () => {
        navigate("/create-location");
    }

    const getLocationsData = async (page = 1) => {
        setLoading(true);
        const res = await getParkingLocations(page);
        if (res.success) {
            setLocationData(res.data);
            setPagination(prevPagination => ({
                ...prevPagination,
                totalPages: res.totalNumberOfPages
            }));
            // successAlert(res.message);
        } else {
            errorAlert({ message: res.message });
        }
        setLoading(false);
    }

    useEffect(() => {
        getLocationsData(pagination.page);
    },
        [pagination.page]);

    const pageHandler = (page) => {
        setPagination(prevPagination => ({
            ...prevPagination,
            page: page
        }));
        getLocationsData(page);
    }

    return <>
        <Loader show={loading} />
        <Container fluid>
            <Row className="g-3">
                <Col md={3}>
                    <Sidebar />
                </Col>
                <Col md={9}>
                    <VerticalLayout gap={3}>
                        <Header
                            heading={"Create Parking"}
                            subHeading={'Welcome ! Admin Truck'}
                            showNotificationIcon={true}
                            showButton={true}
                            buttonLabel={"Create Location"}
                            onButtonClick={createLocationNavigate} />
                        <Typography className={'fw-medium'} color={'#000'}>Location List</Typography>

                        <LocationListTable pagination={pagination} pageHandler={pageHandler} locationData={locationData} />
                    </VerticalLayout>
                </Col>
            </Row>
        </Container>
    </>
}