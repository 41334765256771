import React from 'react'

export const ViewIcon = () => {
    return (
        <>

            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M12 8.27393C9.92894 8.27393 8.25001 9.95286 8.25001 12.0239C8.25001 14.095 9.92894 15.7739 12 15.7739C14.0711 15.7739 15.75 14.095 15.75 12.0239C15.75 9.95286 14.0711 8.27393 12 8.27393ZM9.75001 12.0239C9.75001 10.7813 10.7574 9.77393 12 9.77393C13.2426 9.77393 14.25 10.7813 14.25 12.0239C14.25 13.2666 13.2426 14.2739 12 14.2739C10.7574 14.2739 9.75001 13.2666 9.75001 12.0239Z" fill="#2D264B" />
                <path fillRule="evenodd" clipRule="evenodd" d="M12 3.27393C9.85905 3.27393 7.92325 4.33292 6.35173 5.61964C4.77164 6.91339 3.47962 8.50258 2.61142 9.71194L2.53981 9.81152C2.01715 10.5378 1.58691 11.1356 1.58691 12.0239C1.58691 12.9123 2.01715 13.5101 2.53981 14.2363L2.61142 14.3359C3.47962 15.5453 4.77164 17.1345 6.35173 18.4282C7.92325 19.7149 9.85905 20.7739 12 20.7739C14.141 20.7739 16.0768 19.7149 17.6483 18.4282C19.2284 17.1345 20.5204 15.5453 21.3886 14.3359L21.4602 14.2363C21.9829 13.5101 22.4131 12.9123 22.4131 12.0239C22.4131 11.1356 21.9829 10.5378 21.4602 9.81152L21.3886 9.71194C20.5204 8.50257 19.2284 6.91339 17.6483 5.61964C16.0768 4.33292 14.141 3.27393 12 3.27393ZM3.82993 10.5867C4.6592 9.43158 5.86348 7.95807 7.302 6.78024C8.7491 5.59539 10.3542 4.77393 12 4.77393C13.6458 4.77393 15.2509 5.59539 16.698 6.78024C18.1365 7.95806 19.3408 9.43158 20.1701 10.5867C20.794 11.4559 20.9131 11.6654 20.9131 12.0239C20.9131 12.3825 20.794 12.592 20.1701 13.4611C19.3408 14.6163 18.1365 16.0898 16.698 17.2676C15.2509 18.4525 13.6458 19.2739 12 19.2739C10.3542 19.2739 8.7491 18.4525 7.302 17.2676C5.86349 16.0898 4.6592 14.6163 3.82993 13.4611C3.20597 12.592 3.08691 12.3825 3.08691 12.0239C3.08691 11.6654 3.20597 11.4559 3.82993 10.5867Z" fill="#2D264B" />
            </svg>

        </>
    )
}
