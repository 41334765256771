import React from 'react'

export const AddOnServicesIcon = () => {
    return (
        <>

            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1418_5551)">
                    <path d="M10.4375 4.63184H16.4263" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10.4375 8.61914H16.4263" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10.4375 10.2598H16.4263" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10.4375 12.6064H16.4263" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10.4375 14.2471H14.6562" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10.4375 4.63184H16.4263" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10.4375 6.27246H16.4263" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6.3916 5.37893L7.34387 6.55371L8.59473 4.35059" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M17.208 17.3884V21.3303" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M19.1792 19.3594H15.2373" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10.4375 18.2344H13.0685" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M13.9301 16.5938H10.4375" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M3.6084 3.75952H5.73901C6.38621 3.75952 6.91088 3.23484 6.91088 2.58764V0.457031" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinejoin="round" />
                    <path d="M8.4541 14.5283H6.53223C6.45455 14.5283 6.3916 14.4654 6.3916 14.3877V12.4658C6.3916 12.3881 6.45455 12.3252 6.53223 12.3252H8.4541C8.53177 12.3252 8.59473 12.3881 8.59473 12.4658V14.3877C8.59473 14.4654 8.53177 14.5283 8.4541 14.5283Z" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.4541 10.541H6.53223C6.45455 10.541 6.3916 10.4781 6.3916 10.4004V8.47852C6.3916 8.40084 6.45455 8.33789 6.53223 8.33789H8.4541C8.53177 8.33789 8.59473 8.40084 8.59473 8.47852V10.4004C8.59473 10.4781 8.53177 10.541 8.4541 10.541Z" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.4541 18.5156H6.53223C6.45455 18.5156 6.3916 18.4527 6.3916 18.375V16.4531C6.3916 16.3755 6.45455 16.3125 6.53223 16.3125H8.4541C8.53177 16.3125 8.59473 16.3755 8.59473 16.4531V18.375C8.59473 18.4527 8.53177 18.5156 8.4541 18.5156Z" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M3.50293 14.3438V20.046C3.50293 20.6932 4.0276 21.2179 4.6748 21.2179H13.3417" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M19.315 4.64062V1.52344C19.315 0.876234 18.7904 0.351562 18.1432 0.351562H7.39637C7.08559 0.351562 6.78751 0.475031 6.56771 0.694781L3.84615 3.41634C3.6264 3.63614 3.50293 3.93422 3.50293 4.245V12.7031" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M19.3154 15.6229V6.28125" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M20.987 21.3895C21.3123 20.7851 21.4971 20.0939 21.4971 19.3594C21.4971 16.9906 19.5768 15.0703 17.208 15.0703C14.8392 15.0703 12.9189 16.9906 12.9189 19.3594C12.9189 21.7282 14.8392 23.6484 17.208 23.6484C18.2469 23.6484 19.1995 23.279 19.9417 22.6644" stroke="black" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_1418_5551">
                        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                    </clipPath>
                </defs>
            </svg>

        </>
    )
}
