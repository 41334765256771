import React, { useState } from 'react';
import { Notification } from './Icons/Notification';
import { WhitePanelBox } from './WhitePanelBox';
import { HorizontalLayout } from './HorizontalLayout';
import { Heading } from './Heading';
import { SvgIcon } from './SvgIcon';
import { SharedButton } from './SharedButton';
import { AddIcon } from './Icons/AddIcon';

const Header = ({
  heading,
  subHeading,
  showButton = false,
  notificationIconBg,
  buttonLabel,
  buttonIcon,
  onButtonClick,
  showNotificationIcon = false,
  notifications = []
}) => {
  const [isNotificationDropdownVisible, setNotificationDropdownVisible] = useState(false);

  const toggleNotificationDropdown = () => {
    setNotificationDropdownVisible(prevState => !prevState);
  };

  return (
    <WhitePanelBox className="Box">
      <HorizontalLayout gap={2} justifyContent={'space-between'} alignItems={'center'}>
        <Heading Heading={heading} SubHeading={subHeading} />
        <HorizontalLayout gap={3}>
          {/* Render notification icon only if showNotification is true */}
          {showNotificationIcon && (
            <div className='position-relative'>
              <SvgIcon bg={notificationIconBg || '#E8EDFD'} size={'1rem'} onClick={toggleNotificationDropdown}>
                <Notification />
              </SvgIcon>
              {isNotificationDropdownVisible && (
                <div
                  className='position-absolute end-0 bg-white border-dashed rounded shadow p-3'
                  style={{
                    top: '100%',
                    width: '200px',
                    maxHeight: '300px',
                    overflowY: 'auto',
                    zIndex: '10'
                  }}
                >
                  <ul className='unstyled p-0'>
                    {notifications.length === 0 ? (
                      <li>No notifications</li>
                    ) : (
                      notifications.map((notification, index) => (
                        <li key={index} style={{ padding: '8px', borderBottom: '1px solid #eee' }}>
                          {notification}
                        </li>
                      ))
                    )}
                  </ul>
                </div>
              )}
            </div>
          )}
          {showButton && (
            <SharedButton
              onClick={onButtonClick}
              type={'button'}
              label={buttonLabel}
              icon={buttonIcon || <AddIcon color={'#fff'} />}
            />
          )}
        </HorizontalLayout>
      </HorizontalLayout>
    </WhitePanelBox>
  );
};

export default Header;
