import React from 'react'

export const TotalUser = () => {
    return (
        <>

            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1480_8598)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M15.9998 5.64697C13.3196 5.64697 11.1469 7.81971 11.1469 10.4999C11.1469 13.1801 13.3196 15.3529 15.9998 15.3529C18.68 15.3529 20.8528 13.1801 20.8528 10.4999C20.8528 7.81971 18.68 5.64697 15.9998 5.64697ZM13.0881 10.4999C13.0881 8.89179 14.3917 7.58815 15.9998 7.58815C17.6079 7.58815 18.9116 8.89179 18.9116 10.4999C18.9116 12.108 17.6079 13.4117 15.9998 13.4117C14.3917 13.4117 13.0881 12.108 13.0881 10.4999Z" fill="black" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M12.1175 17.294C9.43727 17.294 7.26453 19.4668 7.26453 22.147C7.26453 24.8272 9.43727 26.9999 12.1175 26.9999H19.8822C22.5624 26.9999 24.7351 24.8272 24.7351 22.147C24.7351 19.4668 22.5624 17.294 19.8822 17.294H12.1175ZM9.20571 22.147C9.20571 20.5389 10.5094 19.2352 12.1175 19.2352H19.8822C21.4903 19.2352 22.7939 20.5389 22.7939 22.147C22.7939 23.7551 21.4903 25.0587 19.8822 25.0587H12.1175C10.5094 25.0587 9.20571 23.7551 9.20571 22.147Z" fill="black" />
                    <path d="M10.5026 13.9146C10.2985 13.5867 9.91548 13.4117 9.52924 13.4117C7.92112 13.4117 6.61747 12.108 6.61747 10.4999C6.61747 8.89179 7.92112 7.58815 9.52924 7.58815C9.91548 7.58815 10.2985 7.41309 10.5026 7.08519C10.5116 7.07068 10.5207 7.05622 10.5298 7.04179C10.8697 6.50537 10.6975 5.74584 10.0663 5.67635C9.88995 5.65694 9.71076 5.64697 9.52924 5.64697C6.84903 5.64697 4.6763 7.81971 4.6763 10.4999C4.6763 13.1801 6.84903 15.3529 9.52924 15.3529C9.71076 15.3529 9.88995 15.3429 10.0663 15.3235C10.6975 15.254 10.8697 14.4945 10.5298 13.958C10.5207 13.9436 10.5116 13.9291 10.5026 13.9146Z" fill="black" />
                    <path d="M6.5635 24.1747C6.41659 23.9296 6.15728 23.7646 5.87153 23.7646H5.64689C4.03876 23.7646 2.73512 22.461 2.73512 20.8529C2.73512 19.2447 4.03876 17.9411 5.64689 17.9411H5.87153C6.15728 17.9411 6.41659 17.7761 6.5635 17.531C6.9221 16.9327 6.53295 15.9999 5.83543 15.9999H5.64689C2.96668 15.9999 0.793945 18.1727 0.793945 20.8529C0.793945 23.5331 2.96668 25.7058 5.64689 25.7058H5.83543C6.53295 25.7058 6.9221 24.773 6.5635 24.1747Z" fill="black" />
                    <path d="M21.4698 13.958C21.13 14.4945 21.3022 15.254 21.9334 15.3235C22.1097 15.3429 22.2889 15.3529 22.4704 15.3529C25.1506 15.3529 27.3234 13.1801 27.3234 10.4999C27.3234 7.81971 25.1506 5.64697 22.4704 5.64697C22.2889 5.64697 22.1097 5.65694 21.9334 5.67635C21.3022 5.74584 21.13 6.50537 21.4698 7.04179C21.4789 7.05622 21.488 7.07068 21.4971 7.08518C21.7012 7.41309 22.0842 7.58815 22.4704 7.58815C24.0785 7.58815 25.3822 8.89179 25.3822 10.4999C25.3822 12.108 24.0785 13.4117 22.4704 13.4117C22.0842 13.4117 21.7012 13.5867 21.4971 13.9146C21.488 13.9291 21.4789 13.9436 21.4698 13.958Z" fill="black" />
                    <path d="M25.4362 24.1747C25.0776 24.773 25.4667 25.7058 26.1642 25.7058H26.3528C29.033 25.7058 31.2057 23.5331 31.2057 20.8529C31.2057 18.1727 29.033 15.9999 26.3528 15.9999H26.1642C25.4667 15.9999 25.0776 16.9327 25.4362 17.531C25.5831 17.7761 25.8424 17.9411 26.1281 17.9411H26.3528C27.9609 17.9411 29.2645 19.2447 29.2645 20.8529C29.2645 22.461 27.9609 23.7646 26.3528 23.7646H26.1281C25.8424 23.7646 25.5831 23.9296 25.4362 24.1747Z" fill="black" />
                </g>
                <defs>
                    <clipPath id="clip0_1480_8598">
                        <rect width="31.0588" height="31.0588" fill="white" transform="translate(0.470703 0.470703)" />
                    </clipPath>
                </defs>
            </svg>

        </>
    )
}
