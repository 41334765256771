import React, { useEffect, useState } from 'react'
import { Loader } from '../../../components/Loader'
import { Col, Container, Row } from 'react-bootstrap';
import { Sidebar } from '../../../commonPages/sidebar';
import { VerticalLayout } from '../../../components/VerticalLayout';
import { WhitePanelBox } from '../../../components/WhitePanelBox';
import { HorizontalLayout } from '../../../components/HorizontalLayout';
import { Heading } from '../../../components/Heading';
import { SearchIcon } from '../../../components/Icons/SearchIcon';
import { SvgIcon } from '../../../components/SvgIcon';
import { EditServiceForm } from './EditServiceForm';
import { Notification } from '../../../components/Icons/Notification';
import { SharedButton } from '../../../components/SharedButton';
import { AddIcon } from '../../../components/Icons/AddIcon';
import { LeftArrow } from '../../../components/Icons/LeftArrow';
import { useLocation } from 'react-router-dom';
import { getAddOnServiceByIdAPI } from '../../../services/NetworkCall';
import Header from '../../../components/Header';

export default function EditService() {
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const [serviceData, setServiceData] = useState();
    const [isView, setIsView] = useState(false);

    const getDataHandler = async (id) => {
        if (id) {
            setLoading(true);
            const res = await getAddOnServiceByIdAPI(id);
            if (res.success) {
                setLoading(false);
                const e = res.data;
                const pre = {
                    'id': e?._id,
                    'serviceName': e?.name,
                    'dailyPrice': e?.isFree ? '' : e?.dayPrice?.price,
                    'weeklyPrice': e?.isFree ? '' : e?.weeklyPrice?.price,
                    'monthlyPrice': e?.isFree ? '' : e?.monthlyPrice?.price,
                    'subscriptionPrice': e?.isFree ? '' : e?.monthlySubscriptionPrice?.price,
                    'serviceSubtitle': e?.subtitle,
                    'info': e?.description,
                    'imageUrl': e?.image,
                    "isFree": e?.isFree === false ? 2 : 1,
                    "stripeProductId": e?.stripeProductId,
                    "serviceType": e?.serviceType
                };
                setServiceData(pre);
            } else {
                setLoading(false);
                setServiceData();
            }
            setLoading(false);
        }
    }

    useEffect(() => {
        const id = location?.state?.data?.id;
        const mode = location?.state?.data?.mode;

        if (id && id !== undefined) {
            if (mode === 'view') {
                setIsView(true);
            }
            getDataHandler(id);
        }
    }, [location])


    return (
        <>
            <Loader show={loading} />
            <Container fluid>
                <Row className="g-3">
                    <Col md={3}>
                        <Sidebar />
                    </Col>
                    <Col md={9}>
                        <VerticalLayout gap={3}>
                            <Header
                                heading={isView ? 'Service Detail' : "Edit Service"}
                                subHeading={'Welcome ! Admin Truck'}
                                showNotificationIcon={true}
                            />
                            <HorizontalLayout justifyContent={'start'} alignItems={'center'}>
                                <SharedButton onClick={() => window.history.back()} className={'rounded-5'} variant={'light'} size={'md'} type={'button'} label={'Back'} icon={<LeftArrow color={'#000'} />} />
                            </HorizontalLayout>
                            <EditServiceForm serviceData={serviceData} setLoading={setLoading} getDataHandler={getDataHandler} isView={isView} />
                        </VerticalLayout>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
