import React from 'react';
import { Form, InputGroup } from "react-bootstrap";

export const InputFields = ({
    type,
    placeholder,
    title,
    label,
    as,
    name,
    value,
    id,
    size,
    readOnly,
    disabled,
    plaintext,
    autoComplete,
    startIcon,
    endIcon,  // Make sure this prop is properly handled
    className,
    required,
    feedback,
    onKeyDown,
    onChange }
) => {
    return (
        <Form.Group className='w-100'>
            {label && <Form.Label className='mb-2' htmlFor={label}>{label}</Form.Label>}
            <InputGroup>
                {startIcon && (<InputGroup.Text>{startIcon}</InputGroup.Text>)}
                <Form.Control
                    type={type}
                    name={name}
                    as={as}
                    onKeyDown={onKeyDown}
                    value={value}
                    id={id}
                    placeholder={placeholder}
                    title={title}
                    className={className}
                    size={size}
                    readOnly={readOnly}
                    disabled={disabled}
                    autoComplete={autoComplete}
                    onChange={onChange}
                    plaintext={plaintext}
                    required={required}
                    isInvalid={!!feedback} // This line tells Bootstrap when to show the error
                />
                {endIcon && (<InputGroup.Text className="Input-Group-Icon">{endIcon}</InputGroup.Text>)}
                <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>
            </InputGroup>
        </Form.Group>
    );
};
