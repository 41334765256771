import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Accordion, Image } from 'react-bootstrap'
import { Loader } from '../../../components/Loader'
import { Sidebar } from '../../../commonPages/sidebar'
import { VerticalLayout } from '../../../components/VerticalLayout'
import { WhitePanelBox } from '../../../components/WhitePanelBox'
import { HorizontalLayout } from '../../../components/HorizontalLayout'
import { Heading } from '../../../components/Heading'
import { SvgIcon } from '../../../components/SvgIcon'
import { SearchIcon } from '../../../components/Icons/SearchIcon'
import { SharedButton } from '../../../components/SharedButton'
import { LeftArrow } from '../../../components/Icons/LeftArrow'
import { LaneIcon } from '../../../components/Icons/LaneIcon'
import { SlotIcon } from '../../../components/Icons/SlotIcon'
import { PowerIcon } from '../../../components/Icons/PowerIcon'
import { WifiIcon } from '../../../components/Icons/WifiIcon'
import { ParkingAssignmentIcon } from '../../../components/Icons/ParkingAssignmentIcon'
import { VacuumIcon } from '../../../components/Icons/VacuumIcon'
import { AirTireIcon } from '../../../components/Icons/AirTireIcon'
import { InputFields } from '../../../components/InputFields'
import { useLocation, useNavigate } from 'react-router-dom'
import { DropdownSelect } from '../../../components/DropdownSelect'
import { Checkbox } from '../../../components/Checkbox'
import { Notification } from '../../../components/Icons/Notification'

export const ViewSlot = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const [slotData, setSlotData] = useState({ "id": "", "slotNumber": "", "slotLength": "", "slotWidth": "", "cameraName": "", "addonServiceIds": [] })

    useEffect(() => {
        if (location?.state?.data) {
            const data = location?.state?.data;
            let pre = { ...slotData };
            pre.slotNumber = data.slotNumber;
            pre.slotLength = data.slotLength;
            pre.slotWidth = data.slotWidth;
            pre.cameraName = data?.cameraId?.cameraName;
            pre.addonServiceIds = data?.addonServiceIds;
            setSlotData(pre);
        }
    }, [location])




    const laneData = [
        { id: 1, Icon: <LaneIcon />, label: '5 Lane' },
        { id: 2, Icon: <SlotIcon />, label: '300 Slots' },
        { id: 3, Icon: <PowerIcon />, label: 'Power of truck' },
        { id: 3, Icon: <ParkingAssignmentIcon />, label: 'Parking Assignment' },
        { id: 3, Icon: <WifiIcon />, label: 'Internet' },
        { id: 3, Icon: <VacuumIcon />, label: 'Vacuum' },
        { id: 3, Icon: <AirTireIcon />, label: 'Air for Tire' },
    ];

    const addSlotsNavigate = () => {
        navigate("/")
    }


    return (
        <>
            <Loader show={loading} />
            <Container fluid>
                <Row className="g-3">
                    <Col md={3}>
                        <Sidebar />
                    </Col>
                    <Col md={9}>
                        <VerticalLayout gap={3}>
                            <WhitePanelBox className="Box">
                                <HorizontalLayout gap={2} justifyContent={'space-between'} alignItems={'center'}>
                                    <Heading Heading={'Slot Detail'} SubHeading={'Welcome ! Admin Truck'} />
                                    <HorizontalLayout gap={3}>
                                        <SvgIcon bg={'#E8EDFD'} size={'1rem'}>
                                            <SearchIcon />
                                        </SvgIcon>
                                        <SvgIcon bg={'#E8EDFD'} size={'1rem'}>
                                            <Notification />
                                        </SvgIcon>
                                    </HorizontalLayout>
                                </HorizontalLayout>
                            </WhitePanelBox>
                            <HorizontalLayout justifyContent={'between'} alignItems={'center'}>
                                <SharedButton onClick={() => window.history.back()} className={'rounded-5'} variant={'light'} size={'md'} type={'button'} label={'Back'} icon={<LeftArrow color={'#000'} />} />
                            </HorizontalLayout>

                            <WhitePanelBox>
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        {/* <Accordion.Header>Lane - A</Accordion.Header> */}
                                        <Accordion.Body>
                                            <VerticalLayout gap={3} justifyContent={'center'} alignItems={'start'}>
                                                <Row className='g-3 w-100'>
                                                    <Col md={4} sm={12}>
                                                        <InputFields readOnly={true} value={slotData.slotNumber} className={'bg-white mb-2'} label={'Parking Slot Name'} placeholder={'slot name'} id={'laneName'} size={'md'} type={'text'} />
                                                    </Col>
                                                    <Col md={4} sm={12}>
                                                        <InputFields readOnly={true} value={slotData.slotLength} className={'bg-white mb-2'} label={'Length'} placeholder={'length'} id={'laneName'} size={'md'} type={'text'} />
                                                    </Col>
                                                    <Col md={4} sm={12}>
                                                        <InputFields readOnly={true} value={slotData.slotWidth} className={'bg-white mb-2'} label={'Width'} placeholder={'width'} id={'laneName'} size={'md'} type={'text'} />
                                                    </Col>
                                                    <Col md={4} sm={12}>
                                                        <InputFields readOnly={true} value={slotData.cameraName} className={'bg-white mb-2'} label={'Camera'} placeholder={'Camera'} id={'laneName'} size={'md'} type={'text'} />
                                                    </Col>
                                                </Row>
                                                <Row className='g-3'>
                                                    {slotData?.addonServiceIds.map((e, index) => (
                                                        <Col md={3} sm={6} key={index}>
                                                            <SvgIcon bg={'#D1DCFA'} className={'rounded-2 mb-2'}>
                                                                <Image src={e.image} alt='' className='w-25 me-2' fluid rounded />
                                                                {e.name}
                                                            </SvgIcon>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </VerticalLayout>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </WhitePanelBox>
                        </VerticalLayout>
                    </Col>
                </Row>
            </Container >
        </>
    )
}
