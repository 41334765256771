import React from 'react';
import { Image, Table } from 'react-bootstrap';
import { WhitePanelBox } from '../../../components/WhitePanelBox';
import { HorizontalLayout } from '../../../components/HorizontalLayout';
import { TablePagination } from '../../../components/TablePagination';

export const UserParkedVehicleTable = ({ pagination, pageHandler, bookingsData }) => {
    return (
        <>
            <WhitePanelBox>
                <Table hover>
                    <thead>
                        <tr>
                            <th>LOCATION NAME</th>
                            <th>BOOKING ID</th>
                            <th>ZONE</th>
                            <th>SLOT</th>
                            <th>LICENSE PLATE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bookingsData && bookingsData.map((booking, index) => (
                            <tr key={index}>
                                <td>
                                    <Image
                                        src={booking?.parking_location?.locationImage || '../../assets/images/BydeFaultImg.png'}
                                        alt='image' rounded
                                        className='card-image rounded'
                                    /> &emsp;{booking?.parking_location?.locationName}
                                </td>
                                <td>{booking?._id}</td>
                                <td>{booking?.parking_zone?.zoneName}</td>
                                <td>{booking?.parking_slot?.slotNumber}</td>
                                <td>{booking?.vehicle?.licensePlate}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </WhitePanelBox>
            <HorizontalLayout justifyContent={'end'} alignItems={'center'}>
                <TablePagination pagination={pagination} pageHandler={pageHandler} />
            </HorizontalLayout>
        </>
    );
};
