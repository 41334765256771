import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Badge, Image } from 'react-bootstrap'
import { Loader } from '../../../components/Loader'
import { Sidebar } from '../../../commonPages/sidebar'
import { VerticalLayout } from '../../../components/VerticalLayout'
import { WhitePanelBox } from '../../../components/WhitePanelBox'
import { HorizontalLayout } from '../../../components/HorizontalLayout'
import { Heading } from '../../../components/Heading'
import { SvgIcon } from '../../../components/SvgIcon'
import { SearchIcon } from '../../../components/Icons/SearchIcon'
import { Notification } from '../../../components/Icons/Notification'
import Typography from '../../../components/Typography'
import { SharedButton } from '../../../components/SharedButton'
import { LeftArrow } from '../../../components/Icons/LeftArrow'
import { LocationIcon } from '../../../components/Icons/LocationIcon'
import { AddLaneIcon } from '../../../components/Icons/AddLaneIcon'
import { LocationLaneTableList } from './LocationLaneTableList'
import { NewInputField } from '../../../components/NewInputField'
import { UploadFile } from '../../../components/UploadFile'
import { useLocation, useNavigate } from 'react-router-dom'
import { deleteParkingLocationAPI, getParkingZonesAPI, updateParkingLocationAPI } from '../../../services/NetworkCall'
import { errorAlert, successAlert } from '../../../components/Alert'
import Swal from 'sweetalert2'
import { Delete } from '../../../components/Icons/Delete'
import Header from '../../../components/Header'

export const EditLocation = () => {
    const [loading, setLoading] = useState(false);
    const [inLocation, setInLocation] = useState({ "id": "", "name": "", "address": "", "link": "", "image": "", "preImage": "" });
    const [erLocation, setErLocation] = useState({ "id": "", "name": "", "address": "", "link": "", "image": "" });
    const location = useLocation();
    const navigate = useNavigate();
    const [zoneList, setZoneList] = useState([]);



    const getZoneList = async (page = 1, id) => {
        setLoading(true);
        const res = await getParkingZonesAPI(page, id);
        if (res.success) {
            const data = res.data;
            setZoneList(data);
            setLoading(false);
        }
        setLoading(false);
    }

    useEffect(() => {
        if (location?.state?.data) {
            const data = location?.state?.data;
            let pre = { ...inLocation };
            pre.id = data._id;
            pre.preImage = data.locationImage;
            pre.name = data.locationName;
            pre.address = data.locationAddress;
            pre.link = data.locationUrl;
            setInLocation(pre);
            getZoneList(1, data._id);
        }
    }, [location])

    const locationHandler = (e) => {
        const { name, value } = e.target;
        setInLocation((pre) => ({ ...pre, [name]: value }));
        setErLocation((pre) => ({ ...pre, [name]: '' }));
    }

    const locationImageHandler = (data) => {
        const { name, value } = data;
        setInLocation((pre) => ({ ...pre, [name]: value }));
        setErLocation((pre) => ({ ...pre, [name]: "" }));
    }

    const updateHandler = async () => {
        const { id, name, address, link, image } = inLocation;
        let isValid = true;
        if (!name) {
            setErLocation((pre) => ({ ...pre, 'name': 'Required' }));
            isValid = false;
        };
        if (!address) {
            setErLocation((pre) => ({ ...pre, 'address': 'Required' }));
            isValid = false;
        };
        if (!link) {
            setErLocation((pre) => ({ ...pre, 'link': 'Required' }));
            isValid = false;
        };

        if (isValid) {
            setLoading(true);
            const formData = new FormData();
            formData.append('locationName', name);
            formData.append('locationAddress', address);
            formData.append('locationUrl', link);
            if (image) formData.append('locationImage', image);
            try {
                const res = await updateParkingLocationAPI(id, formData);
                setLoading(false);
                if (res.success) {
                    successAlert({ message: res.message });
                    navigate('/location-list');
                } else {
                    errorAlert({ message: res.message });
                }
            } catch (error) {
                setLoading(false);
                errorAlert({ message: "An error occurred while creating the location." });
                console.error(error);
            }

        }

    }


    const deleteHandler = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await deleteParkingLocationAPI(id);
                if (res.success) {
                    setLoading(false);
                    successAlert({ message: res.message });
                    navigate("/location-list");
                } else {
                    setLoading(false);
                    errorAlert({ message: res.message });
                }
                setLoading(false);
            }
        });

    }

    // const zoneNavigator = () => {
    //     navigate('/add-zone', { state: { data: inLocation } })
    // }


    return (
        <>
            <Loader show={loading} />
            <Container fluid>
                <Row className="m-0">
                    <Col md={3}>
                        <Sidebar />
                    </Col>
                    <Col md={9}>
                        <VerticalLayout gap={3}>
                            <Header
                                heading={"Edit Location"}
                                subHeading={'Welcome ! Admin Truck'}
                                showNotificationIcon={true}
                            />
                            <HorizontalLayout justifyContent={'between'} alignItems={'center'}>
                                <SharedButton className={'rounded-5'} variant={'light'} size={'md'} onClick={() => window.history.back()} type={'button'} label={'Back'} icon={<LeftArrow color={'#000'} />} />
                                <HorizontalLayout justifyContent={'between'} alignItems={'center'} gap={3}>
                                    <SharedButton className={'rounded-5 text-dark me-2'} variant={'light'} size={'md'} onClick={updateHandler} type={'button'} label={'Update'} />
                                    <SharedButton label={'Delete'} type={'button'} variant={'danger-light'} size={'md'} className={'rounded-5 text-black'} onClick={() => deleteHandler(inLocation.id)} icon={<Delete />} />
                                </HorizontalLayout>
                            </HorizontalLayout>
                            <WhitePanelBox>
                                <VerticalLayout gap={3} justifyContent={'center'} alignItems={'start'}>
                                    <div className='d-flex'>
                                        <UploadFile
                                            FormLabel="Upload"
                                            name="image"
                                            controlId="formProfilePic"
                                            onChange={locationImageHandler}
                                            error={erLocation.image}
                                        />
                                        {inLocation?.preImage &&
                                            <Image src={inLocation?.preImage} alt='image' width={115} height={115} className='w-100 ms-3' rounded />
                                        }
                                    </div>

                                    <Row className='w-100'>
                                        <Col md={4}>
                                            <NewInputField FormLabel={'Location Name'} FormPlaceHolder={'location name'} FormType={'text'} name={'name'} value={inLocation.name} className={'rounded-2 bg-white'} error={erLocation.name} onChange={locationHandler} />
                                        </Col>
                                        <Col md={4}>
                                            <NewInputField FormLabel={'Location Address'} FormPlaceHolder={'location address'} FormType={'text'} name={'address'} value={inLocation.address} className={'rounded-2 bg-white'} error={erLocation.address} onChange={locationHandler} />
                                        </Col>
                                        <Col md={4}>
                                            <NewInputField FormLabel={'Location Link'} FormType={'text'} FormPlaceHolder={'location link'} name={'link'} value={inLocation.link} className={'rounded-2 bg-white'} error={erLocation.link} onChange={locationHandler} endIcon={<LocationIcon />} />
                                        </Col>
                                    </Row>
                                </VerticalLayout>
                            </WhitePanelBox>
                            <HorizontalLayout gap={1} justifyContent={'between'} alignItems={'center'}>
                                <Typography className={'fw-600'} color={'#000'}>Lane</Typography>
                                {/* <SharedButton onClick={zoneNavigator} variant={'light'} className={'rounded-5'} label={'Add Zone'} icon={<AddLaneIcon />} /> */}
                            </HorizontalLayout>
                            <WhitePanelBox>
                                <LocationLaneTableList zoneList={zoneList} />
                            </WhitePanelBox>
                        </VerticalLayout>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
