import React from 'react'
import { WhitePanelBox } from './WhitePanelBox'
import { HorizontalLayout } from './HorizontalLayout'
import { SvgIcon } from './SvgIcon'
import { VerticalLayout } from './VerticalLayout'
import Typography from './Typography'

export const DashboardCard = ({Icon, Heading, SubHeading}) => {
    return (
        <>
            <WhitePanelBox>
                <HorizontalLayout gap={3} justifyContent={'center'} alignItems={'center'}>
                    <SvgIcon bg={'#e8edfd'}>
                        {Icon}
                    </SvgIcon>
                    <VerticalLayout>
                        <Typography className={'mb-0'} color={'#020817'}>{Heading}</Typography>
                        <Typography className={'mb-0 fw-semibold'} variant={'h5'} color={'#020817'}>{SubHeading}</Typography>
                    </VerticalLayout>
                </HorizontalLayout>
            </WhitePanelBox>
        </>
    )
}
