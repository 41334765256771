import React from 'react'
import { WhitePanelBox } from '../../components/WhitePanelBox'
import { Image, Table } from 'react-bootstrap'
import { HorizontalLayout } from '../../components/HorizontalLayout'
import { TablePagination } from '../../components/TablePagination'

export const LastParkedTable = ({ pagination, pageHandler }) => {

    const vehicleRegistrationData = [
        {
            vehicleName: 'TRK -A01, NY',
            noPlate: 'WB 06 F 6872',
            id: 'TRK -A01',
            lane: 'Lane - 01',
            slot: 'A-10',
            imageUrl: '../../assets/images/BydeFaultImg.png',
        },
        {
            vehicleName: 'TRK -A01, NY',
            noPlate: 'WB 06 F 6872',
            id: 'TRK -A01',
            lane: 'Lane - 01',
            slot: 'A-10',
            imageUrl: '../../assets/images/BydeFaultImg.png',
        },
        {
            vehicleName: 'TRK -A01, NY',
            noPlate: 'WB 06 F 6872',
            id: 'TRK -A01',
            lane: 'Lane 03',
            slot: 'A-10',
            imageUrl: '../../assets/images/BydeFaultImg.png',
        },
        {
            vehicleName: 'TRK -A01, NY',
            noPlate: 'WB 06 F 6872',
            id: 'TRK -A01',
            lane: 'Lane - 01',
            slot: 'A-10',
            imageUrl: '../../assets/images/BydeFaultImg.png',
        }
    ];

    return (
        <>
            <WhitePanelBox>
                <Table hover>
                    <thead>
                        <tr>
                            <th>NICK NAME</th>
                            <th>ID</th>
                            <th>NAME OF LANE</th>
                            <th>SLOT</th>
                            <th>NO PLATE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {vehicleRegistrationData && vehicleRegistrationData.map((user, index) => (
                            <tr key={index}>
                                <td> <Image src={user?.imageUrl || '../../assets/images/Image.png'} alt='' fluid className='bg-white' /> {user?.vehicleName}</td>
                                <td>{user?.id}</td>
                                <td>{user?.lane}</td>
                                <td>{user?.slot}</td>
                                <td>{user?.noPlate}</td>

                            </tr>
                        ))}
                    </tbody>
                </Table>
            </WhitePanelBox>
            <HorizontalLayout justifyContent={'end'} alignItems={'center'}>
                <TablePagination pagination={pagination} pageHandler={pageHandler} />
            </HorizontalLayout>
        </>
    )
}
