import React, { useState } from "react";
import { Col, Container, Form, Image, Row } from "react-bootstrap";
import { Heading } from "../../components/Heading";
import { InputFields } from "../../components/InputFields";
import { SharedButton } from "../../components/SharedButton";
import { VerticalLayout } from "../../components/VerticalLayout";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { emailPattern } from "../../helper/Helper";
import { errorAlert, successAlert } from "../../components/Alert";
import { forgotPassword } from "../../services/NetworkCall";
import { Loader } from "../../components/Loader";

const WhiteBox = styled.div`
  background: #fff;
  padding: 2.5rem;
`;

export const ForgotPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [error, setError] = useState({ "email": "" });
    const [loading, setLoading] = useState(false);

  


    const inputHandler = (e) => {
        const { name, value } = e.target;
        setEmail(value);
        setError((pre) => ({ ...pre, [name]: "" }));
    }

    const submitHandler = async (e) => {

        e.preventDefault();

        let isValid = 1;

        if (!email) {
            setError((prev) => ({ ...prev, email: "Email is required *" }));
            isValid = 2;
        } else if (!emailPattern.test(email)) {
            setError((prev) => ({ ...prev, email: "Invalid email format *" }));
            isValid = 3;
        }


        if (isValid === 1) {
            setLoading(true);
            const data = { "email": email }

            const res = await forgotPassword(data);

            if (res.success) {
                successAlert({ message: res.message, positionClass: "toast-top-left" })
            } else {
                errorAlert({ message: res.message, positionClass: "toast-top-left" });
            }
            setLoading(false);
        }
    }

    return (
        <>
            <Loader show={loading} />
            <Container fluid className="p-0">
                <Row className="justify-content-center align-items-center">
                    <Col sm={12} md={5} lg={5}>
                        <VerticalLayout gap={3} justifyContent={"start"} alignItems={"center"}>
                            <WhiteBox className="w-75 rounded-3">
                                <Form>
                                    <VerticalLayout gap={2} justifyContent={"start"} alignItems={"center"}>
                                        <Image src="/assets/images/logo.png" fluid />
                                        <Heading
                                            Heading={"Forgot Password"}
                                            SubHeading={"Recover their password if forgotten"}
                                        />
                                    </VerticalLayout>
                                    <VerticalLayout gap={3} justifyContent={"center"} alignItems={"center"}>
                                        <InputFields
                                            type={"email"}
                                            name={"email"}
                                            label={"Email Address"}
                                            id={"EmailAddress"}
                                            placeholder={"Enter email address"}
                                            size={"md"}
                                            className="rounded-2 bg-white"
                                            onChange={inputHandler}
                                            feedback={error.email}
                                            isvalid={!!error.email}
                                            value={email}
                                        />

                                        <SharedButton
                                            type={"submit"}
                                            size={"md"}
                                            variant={"primary"}
                                            className={"btn-block w-100"}
                                            label={"Send"}
                                            onClick={submitHandler}
                                        />
                                        <SharedButton
                                            type={"button"}
                                            size={"md"}
                                            variant={"light"}
                                            className={"btn-block w-100"}
                                            label={"Back"}
                                            onClick={()=>window.history.back()}
                                        />
                                    </VerticalLayout>
                                </Form>
                            </WhiteBox>
                        </VerticalLayout>
                    </Col>

                    <Col sm={12} md={7} lg={7}>
                        <Image
                            src="/assets/images/TruckTruck.jpg"
                            fluid
                            className="w-100 vh-100 object-fit-cover"
                            alt="image"
                        />
                    </Col>
                </Row>
            </Container>
        </>

    );
};
