import React from 'react'

export const MoreIcon = () => {
    return (
        <>

            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.33366 8.52409C9.33366 9.26047 8.73671 9.85742 8.00033 9.85742C7.26395 9.85742 6.66699 9.26047 6.66699 8.52409C6.66699 7.78771 7.26395 7.19076 8.00033 7.19076C8.73671 7.19076 9.33366 7.78771 9.33366 8.52409Z" fill="black" />
                <path d="M9.33366 3.19076C9.33366 3.92713 8.73671 4.52409 8.00033 4.52409C7.26395 4.52409 6.66699 3.92713 6.66699 3.19076C6.66699 2.45438 7.26395 1.85742 8.00033 1.85742C8.73671 1.85742 9.33366 2.45438 9.33366 3.19076Z" fill="black" />
                <path d="M9.33366 13.8574C9.33366 14.5938 8.73671 15.1908 8.00033 15.1908C7.26395 15.1908 6.66699 14.5938 6.66699 13.8574C6.66699 13.121 7.26395 12.5241 8.00033 12.5241C8.73671 12.5241 9.33366 13.121 9.33366 13.8574Z" fill="black" />
            </svg>

        </>
    )
}
