import React from 'react'

export const Delete = () => {
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M12 1.25C9.37665 1.25 7.25 3.37665 7.25 6V6.25H4C3.58579 6.25 3.25 6.58579 3.25 7C3.25 7.41421 3.58579 7.75 4 7.75H20C20.4142 7.75 20.75 7.41421 20.75 7C20.75 6.58579 20.4142 6.25 20 6.25H16.75V6C16.75 3.37665 14.6234 1.25 12 1.25ZM12 2.75C13.7949 2.75 15.25 4.20507 15.25 6V6.25H8.75V6C8.75 4.20507 10.2051 2.75 12 2.75Z" fill="#B60000" />
                <path d="M5.74664 8.92906C5.70746 8.5167 5.34142 8.21418 4.92906 8.25336C4.5167 8.29254 4.21418 8.65858 4.25336 9.07094C4.34783 10.0652 4.51833 11.2903 4.73748 12.8651L5.01903 14.8881C5.28819 16.8229 5.44085 17.9201 5.77109 18.819C6.3857 20.4918 7.48205 21.8328 8.89206 22.4278C9.65773 22.7509 10.5335 22.7506 11.8373 22.75H12.1627C13.4665 22.7506 14.3423 22.7509 15.1079 22.4278C16.5179 21.8328 17.6143 20.4918 18.2289 18.819C18.5592 17.9201 18.7118 16.8229 18.981 14.8881L19.2625 12.8651C19.4817 11.2903 19.6522 10.0652 19.7466 9.07094C19.7858 8.65858 19.4833 8.29254 19.0709 8.25336C18.6586 8.21418 18.2925 8.5167 18.2534 8.92906C18.1623 9.88702 17.9965 11.08 17.7744 12.6761L17.512 14.5611C17.2215 16.6488 17.0884 17.5736 16.8209 18.3017C16.305 19.7058 15.4472 20.6566 14.5248 21.0458C14.0746 21.2358 13.5292 21.25 12 21.25C10.4708 21.25 9.92544 21.2358 9.47524 21.0458C8.55279 20.6566 7.69496 19.7058 7.17907 18.3017C6.91156 17.5736 6.77851 16.6488 6.48798 14.5611L6.22564 12.6761C6.00352 11.08 5.83766 9.88703 5.74664 8.92906Z" fill="#B60000" />
                <path d="M10.75 10C10.75 9.58579 10.4142 9.25 10 9.25C9.58579 9.25 9.25 9.58579 9.25 10V18C9.25 18.4142 9.58579 18.75 10 18.75C10.4142 18.75 10.75 18.4142 10.75 18V10Z" fill="#B60000" />
                <path d="M14.75 10C14.75 9.58579 14.4142 9.25 14 9.25C13.5858 9.25 13.25 9.58579 13.25 10V18C13.25 18.4142 13.5858 18.75 14 18.75C14.4142 18.75 14.75 18.4142 14.75 18V10Z" fill="#B60000" />
            </svg>
        </>
    )
}
