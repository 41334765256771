import React from "react";
import { Button } from "react-bootstrap";

export const SharedButton = ({
    variant,
    type,
    size,
    label,
    className,
    onClick,
    icon,  // New icon prop
    iconPosition = 'left', 
    disabled
}) => {
    return (
        <Button
            variant={variant}
            type={type}
            size={size}
            className={className}
            onClick={onClick} 
            disabled={disabled}
        >
            {icon && iconPosition === 'left' && <span className="me-2">{icon}</span>}
            {label}
            {icon && iconPosition === 'right' && <span className="ms-2">{icon}</span>}
        </Button>
    );
};
