import React from 'react'

export const TransactionIcon = () => {
    return (
        <>

            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1418_5592)">
                    <path d="M18.8897 7.8765C19.1826 8.16937 19.1826 8.64427 18.8897 8.93714L16.6235 11.2033C16.4771 11.3498 16.2851 11.423 16.0932 11.423C15.9013 11.423 15.7093 11.3498 15.5629 11.2034C15.27 10.9104 15.27 10.4356 15.5629 10.1427L16.5487 9.15684H7.5627C7.14847 9.15684 6.8127 8.82103 6.8127 8.40684C6.8127 7.99266 7.14847 7.65684 7.5627 7.65684H16.5487L15.5629 6.67097C15.27 6.37809 15.27 5.9032 15.5629 5.61033C15.8559 5.31745 16.3308 5.31741 16.6235 5.61033L18.8897 7.8765ZM16.9795 14.8432H8.45131L9.43714 13.8573C9.73006 13.5644 9.73006 13.0896 9.43714 12.7967C9.14431 12.5038 8.66942 12.5038 8.3765 12.7967L6.11028 15.0629C5.96961 15.2035 5.89058 15.3943 5.89058 15.5932C5.89058 15.7921 5.96961 15.9829 6.11028 16.1235L8.3765 18.3897C8.52294 18.5362 8.71489 18.6094 8.9068 18.6094C9.0987 18.6094 9.29066 18.5362 9.43709 18.3897C9.73002 18.0968 9.73002 17.622 9.43709 17.3291L8.45122 16.3432H16.9795C17.3937 16.3432 17.7295 16.0074 17.7295 15.5932C17.7295 15.179 17.3937 14.8432 16.9795 14.8432ZM24.5 12C24.5 18.6168 19.1168 24 12.5 24C5.88317 24 0.5 18.6168 0.5 12C0.5 5.38317 5.88317 0 12.5 0C19.1168 0 24.5 5.38317 24.5 12ZM23 12C23 6.21028 18.2897 1.5 12.5 1.5C6.71028 1.5 2 6.21028 2 12C2 17.7897 6.71028 22.5 12.5 22.5C18.2897 22.5 23 17.7897 23 12Z" fill="black" />
                </g>
                <defs>
                    <clipPath id="clip0_1418_5592">
                        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                    </clipPath>
                </defs>
            </svg>

        </>
    )
}
