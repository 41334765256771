import { Button, Col, Container, Form, Image, Row } from "react-bootstrap"
import { VerticalLayout } from "../../components/VerticalLayout"
import { WhitePanelBox } from "../../components/WhitePanelBox"
import { HorizontalLayout } from "../../components/HorizontalLayout"
import { SvgIcon } from "../../components/SvgIcon"
import { LocationIcon } from "../../components/Icons/LocationIcon"
import { Sidebar } from "../../commonPages/sidebar"
import { Heading } from "../../components/Heading"
import { SearchPanel } from "../../components/SearchPanel"
import { Notification } from "../../components/Icons/Notification"
import { SharedButton } from "../../components/SharedButton"
import Typography from "../../components/Typography"
import { LeftArrow } from "../../components/Icons/LeftArrow"
import { useLocation } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import moment from "moment"
import { getBookingDetailAPI } from "../../services/NetworkCall"
import { errorAlert } from "../../components/Alert"
import { Loader } from "../../components/Loader"
import swal from 'sweetalert';
import Header from "../../components/Header"

export const TransactionDetail = () => {
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const [bookingId, setBookingId] = useState("");
    const [bookingDetail, setBookingDetail] = useState({});

    useEffect(() => {
        if (location?.state?.data?.bookingId) {
            setBookingId(location.state.data.bookingId);
        }
    }, [location]);


    const getBookingDetail = async (bookingId) => {
        setLoading(true);
        const res = await getBookingDetailAPI(bookingId);
        if (res.success) {
            setBookingDetail(res.data);
        } else {
            errorAlert({ message: res.message });
        }
        setLoading(false)
    }

    useEffect(() => {
        if (bookingId) getBookingDetail(bookingId);
    }, [bookingId]);

    return (
        <>
            <Loader show={loading} />
            <Container fluid>
                <Row className="m-0">
                    <Col md={3}>
                        <Sidebar />
                    </Col>
                    <Col md={9}>
                        <VerticalLayout gap={3}>
                            <Header
                                heading={"Transaction Detail"}
                                subHeading={'Welcome ! Admin Truck'}
                                showNotificationIcon={true}
                            />
                            <HorizontalLayout justifyContent={'between'} alignItems={'center'}>
                                <SharedButton
                                    onClick={() => window.history.back()}
                                    className={'rounded-5'}
                                    variant={'light'}
                                    size={'md'}
                                    type={'button'}
                                    label={'Back'}
                                    icon={<LeftArrow color={'#000'} />}
                                />
                            </HorizontalLayout>

                            {/* -------------------card----------------------------- */}
                            <WhitePanelBox>
                                <HorizontalLayout gap={3} justifyContent={'between'} alignItems={'center'}>
                                    <HorizontalLayout gap={3}>
                                        <Image
                                            src={bookingDetail?.location_info?.locationImage || '../../user/assets/images/Image.png'} s
                                            alt=''
                                            fluid
                                            className='bg-white w-25 img-dashed'
                                        />
                                        <VerticalLayout justifyContent={'center'} alignItems={'start'}>
                                            <Typography variant={'h4'} className={'mb-0'}>{bookingDetail?.location_info?.locationName}</Typography>
                                            <Typography variant={'p'} color={'#7d7d7d'} className={'mb-0'}>{bookingDetail?.location_info?.locationAddress}</Typography>
                                            <Typography variant={'p'} color={'#0a0a0a'} className={'mb-0'}>{`ZONE : ${bookingDetail?.zone_info?.zoneName} | SLOT : ${bookingDetail?.slot_info?.slotNumber}`}</Typography>
                                        </VerticalLayout>
                                    </HorizontalLayout>
                                    <SvgIcon bg={'#e8edfd'}><LocationIcon /></SvgIcon>
                                </HorizontalLayout>
                            </WhitePanelBox>

                            {/*----------------------------Booking Dates-------------------------------*/}
                            <Typography variant={'h6'} className={'mb-0'}>Booking Dates</Typography>

                            <WhitePanelBox>
                                <Row className="g-3 overflow-x-scroll">
                                    <HorizontalLayout gap={3} justifyContent={'start'} alignItems={'center'}>
                                        {bookingDetail?.booking_dates?.length > 0 &&
                                            bookingDetail.booking_dates.map((data, index) => (
                                                <Col md={4} sm={6} key={index}>
                                                    <div className='border-end pe-3'>
                                                        <VerticalLayout>
                                                            <HorizontalLayout gap={3} justifyContent={'between'} alignItems={'center'}>
                                                                <Typography className={'mb-0 fw-semibold'} color={'#64748B'}>Arrive After</Typography>
                                                                <Typography color={"#999999"} className={'mb-0'}>
                                                                    {data?.startDateTime && moment(data?.startDateTime).local().format('D MMM YYYY h:mm A')}
                                                                </Typography>
                                                            </HorizontalLayout>
                                                            <HorizontalLayout gap={3} justifyContent={'between'} alignItems={'center'}>
                                                                <Typography className={'mb-0 fw-semibold'} color={'#64748B'}>Exit Before</Typography>
                                                                <Typography color={"#999999"} className={'mb-0'}>
                                                                    {data?.endDateTime && moment(data?.endDateTime).local().format('D MMM YYYY h:mm A')}
                                                                </Typography>
                                                            </HorizontalLayout>
                                                        </VerticalLayout>
                                                    </div>
                                                </Col>
                                            ))}
                                    </HorizontalLayout>
                                </Row>
                            </WhitePanelBox>

                            {/* -------------------------------booking details---------------------- */}
                            <Typography variant={'h6'} className={'mb-0'}>Booking Detail</Typography>
                            <WhitePanelBox>

                                <HorizontalLayout justifyContent={'between'} alignItems={'center'}>
                                    <VerticalLayout gap={2} alignItems={'center'}>
                                        <Typography color={'#64748B'} variant={'h6'} className={'fw-bold mb-0'}>Vehicle Name</Typography>
                                        <Typography color={'#999999'} className={'mb-0'}>{bookingDetail?.vehicle_info?.vehicleName}</Typography>
                                    </VerticalLayout>
                                    <VerticalLayout gap={2} alignItems={'center'}>
                                        <Typography color={'#64748B'} variant={'h6'} className={'fw-bold mb-0'}>License Plate</Typography>
                                        <Typography color={'#999999'} className={'mb-0'}>{bookingDetail?.vehicle_info?.licensePlate}</Typography>
                                    </VerticalLayout>
                                    <VerticalLayout gap={2} alignItems={'center'}>
                                        <Typography color={'#64748B'} variant={'h6'} className={'fw-bold mb-0'}>Booking Date</Typography>
                                        <Typography color={'#999999'} className={'mb-0'}>{bookingDetail?.createdAt && moment(bookingDetail?.createdAt).local().format("D MMM YYYY")}</Typography>
                                    </VerticalLayout>
                                    <VerticalLayout gap={2} alignItems={'center'}>
                                        <Typography color={'#64748B'} variant={'h6'} className={'fw-bold mb-0'}>Duration</Typography>
                                        <Typography color={'#999999'} className={'mb-0'}>{bookingDetail?.bookingType}</Typography>
                                    </VerticalLayout>
                                    <VerticalLayout gap={2} alignItems={'center'}>
                                        <Typography color={'#64748B'} variant={'h6'} className={'fw-bold mb-0'}>Preferred Parking Charge</Typography>
                                        <Typography color={'#34C759'} className={'mb-0 fw-semibold'}>USD {bookingDetail?.preferredSlotCharge}</Typography>
                                    </VerticalLayout>
                                    <VerticalLayout gap={2} alignItems={'center'}>
                                        <Typography color={'#64748B'} variant={'h6'} className={'fw-bold mb-0'}>Price</Typography>
                                        <Typography color={'#34C759'} className={'mb-0 fw-semibold'}>USD {bookingDetail?.parkingTotalAmount}</Typography>
                                    </VerticalLayout>
                                </HorizontalLayout>

                            </WhitePanelBox>

                            {/* ----------------------------------Add Service Info-------------------------- */}
                            <Typography variant={'h6'} className={'mb-0'}>Add Service Info</Typography>
                            <WhitePanelBox>
                                <HorizontalLayout justifyContent={'between'} alignItems={'center'}>
                                    {bookingDetail && bookingDetail.booking_addon_info && bookingDetail.booking_addon_info.map((addOnInfo, index) =>
                                        <VerticalLayout gap={2} alignItems={'center'} key={index}>
                                            <Typography color={'#64748B'} variant={'h6'} className={'mb-0 fw-bold'}>{addOnInfo?.addon_info?.name}</Typography>
                                            <Typography color={addOnInfo?.totalAmount == 0 ? '#FFC107' : '#34C759'} className={'mb-0'}>{addOnInfo?.totalAmount == 0 ? "Free" : `USD ${addOnInfo?.totalAmount}`}</Typography>
                                        </VerticalLayout>
                                    )}
                                </HorizontalLayout>
                            </WhitePanelBox>

                            {/* ------------------------Total Parking price------------------------ */}
                            <WhitePanelBox className={'mb-3'}>
                                <HorizontalLayout justifyContent={'between'} >
                                    <Typography variant={'h6'} className={'fw-bold mb-0'}>Total Parking Price</Typography>
                                    <Typography color={'#34C759'} variant={'h6'} className={'fw-bold me-3 mb-0'}>USD {bookingDetail?.totalAmount}</Typography>
                                </HorizontalLayout>
                            </WhitePanelBox>

                        </VerticalLayout>
                    </Col>
                </Row>
            </Container >
        </>
    )
} 