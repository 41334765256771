import React from 'react'

export const Setting = () => {
    return (
        <>

            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.08206 13.9448C2.55306 12.9948 2.28906 12.5188 2.28906 11.9998C2.28906 11.4808 2.55306 11.0058 3.08206 10.0558L4.43006 7.62977L5.85606 5.24877C6.41506 4.31577 6.69406 3.84877 7.14306 3.58877C7.59306 3.32977 8.13606 3.32177 9.22306 3.30377L12.0001 3.25977L14.7751 3.30377C15.8631 3.32177 16.4061 3.32977 16.8551 3.58977C17.3041 3.84977 17.5851 4.31577 18.1431 5.24877L19.5701 7.62977L20.9201 10.0558C21.4481 11.0058 21.7121 11.4808 21.7121 11.9998C21.7121 12.5188 21.4481 12.9938 20.9191 13.9438L19.5701 16.3698L18.1441 18.7508C17.5851 19.6838 17.3061 20.1508 16.8571 20.4108C16.4071 20.6698 15.8641 20.6778 14.7771 20.6958L12.0001 20.7398L9.22506 20.6958C8.13706 20.6778 7.59406 20.6698 7.14506 20.4098C6.69606 20.1498 6.41506 19.6838 5.85706 18.7508L4.43006 16.3698L3.08206 13.9448Z" stroke="black" strokeWidth="2" />
                <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="black" strokeWidth="2" />
            </svg>

        </>
    )
}
