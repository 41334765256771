import React from 'react'
import { WhitePanelBox } from '../../../components/WhitePanelBox';
import { Image, Table } from 'react-bootstrap';
import { IconTooltip } from '../../../components/IconTooltip';

export const AddOnServiceList = ({ serviceData }) => {

    return (
        <>
            <WhitePanelBox>
                <Table hover responsive>
                    <thead>
                        <tr>
                            <th>SERVICE NAME</th>
                            <th>SERVICE TYPE</th>
                            <th>DAILY PRICE</th>
                            <th>WEEKLY PRICE</th>
                            <th>MONTHLY PRICE</th>
                            <th>SUBSCRIPTION PRICE</th>
                            <th>SERVICE SUBTITLE</th>
                            <th>INFO</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {serviceData.map((services, index) => (
                            <tr key={index}>
                                <td className='text-uppercase'><Image src={services.imageUrl} alt='image' className='w-25 me-2' fluid rounded /> {services?.serviceName}</td>
                                <td className='text-uppercase'>{services?.serviceType}</td>
                                <td className='text-uppercase'>{services?.dailyPrice}</td>
                                <td className='text-uppercase'>{services?.weeklyPrice}</td>
                                <td className='text-uppercase'>{services?.monthlyPrice}</td>
                                <td className='text-uppercase'>{services?.subscriptionPrice}</td>
                                <td style={{maxWidth: '200px'}} className='text-uppercase' >{services?.serviceSubtitle}</td>
                                <td className='text-uppercase'>{services?.info}</td>
                                <td><IconTooltip editPath={'/edit-service'} editData={{ id: services.id, "mode": 'edit' }} viewPath='/edit-service' viewData={{ id: services.id, "mode": 'view' }} /></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </WhitePanelBox>
        </>
    );
};
