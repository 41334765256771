// import React from 'react'
// import { Dropdown } from 'react-bootstrap'

// export const DropdownFilter = ({ Icon, variant, DropdownLabel, DropdownOptionOne, DropdownOptionTwo, DropdownOptionThree }) => {
//     return (
//         <>
//             <Dropdown>
//                 <Dropdown.Toggle variant={variant} id="dropdown-basic" className='bg-white rounded-5'>
//                     {Icon}   {DropdownLabel}
//                 </Dropdown.Toggle>
//                 <Dropdown.Menu>
//                     <Dropdown.Item href="#/action-1">{DropdownOptionOne}</Dropdown.Item>
//                     <Dropdown.Item href="#/action-2">{DropdownOptionTwo}</Dropdown.Item>
//                     <Dropdown.Item href="#/action-3">{DropdownOptionThree}</Dropdown.Item>
//                 </Dropdown.Menu>
//             </Dropdown>
//         </>
//     )
// }


import React, { useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';

export const DropdownFilter = ({
    Icon,
    className,
    DropdownLabel,
    filterOptions,
    onFilterSelect,
    selectedValue
}) => {
    const [show, setShow] = useState(false);

    const toggleDropdown = () => setShow(!show);

    const handleSelect = (value) => {
        setShow(false); // Close dropdown after selection
        if (onFilterSelect) {
            onFilterSelect(value); // Invoke the callback with the selected value
        }
    };

    return (
        <Dropdown show={show} onToggle={() => setShow(!show)} drop="down" align="end" placement="bottom-end">
            <Button
                className={className}
                onClick={toggleDropdown}
            >
                {Icon} {DropdownLabel}
            </Button>
            <Dropdown.Menu>
                {filterOptions && filterOptions.map((option, index) => (
                    <Dropdown.Item
                        key={index}
                        onClick={() => handleSelect(option)}
                        className={selectedValue === option ? 'bg-primary text-white' : ''}
                    >
                        {option}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

